<template>
  <div v-cloak>
    <!-- 용량제 Tab -->
    <div class="mainTabAr">
      <ul class="mainTab">
        <li
            :class="['mainTab-link', 'fW-R', { current: selectedTab === 1 }]"
            @click="selectedTab = 1"
            data-tab="trafficBest notranslate"
        >
          {{ $t('trans.t01_16') }}
          {{ $t('trans.t01_09') }}<span></span>
        </li>
        <li
            :class="['mainTab-link ', 'fW-R notranslate', { current: selectedTab === 2 }]"
            @click="selectedTab = 2"
            data-tab="trafficBest"
        >
          {{ $t('trans.t01_17') }}
          {{ $t('trans.t01_09') }}<span></span>
        </li>
        <li
            :class="['mainTab-link', 'fW-R notranslate', { current: selectedTab === 3 }]"
            @click="selectedTab = 3"
            data-tab="trafficBest"
        >
          {{ $t('trans.t01_18') }}
          {{ $t('trans.t01_09') }}<span></span>
        </li>
      </ul>
    </div>
    <div style="clear: both;"></div>
    <!-- 할인 혜택 -->
    <div class="rangeAr">
      <div class="left">
        <div class="rangeSection">
          <p class="mainTit fW-R notranslate">
            {{ $t('trans.t01_19') }}</p>
          <p class="info">
            <i class="xi-help-o"></i>
            <span class="tooltiptext">
              연속 사용기간에 따라 기본료 할인 혜택을 드리는
              제도로 할인 적용 시점의 예상 납부 요금을
              미리 확인하실 수 있습니다. (요금제별 할인금액 상이)<br>
              - 4회차 부터 : 1,000~3,000원 할인<br>
              - 10회차부터 : 2,000~6,000원 할인
            </span>
          </p>
          <p class="subTxt notranslate">
            {{ $t('trans.t01_20') }}
          </p>
          <div id="slider-max">
            <div class="numWrap">
              <span class="sliderNum"
                    style="padding-left: 14px;">1</span>
              <span class="sliderNum">2</span>
              <span class="sliderNum">3</span>
              <span class="sliderNum">4</span>
              <span class="sliderNum">5</span>
              <span class="sliderNum">6</span>
              <span class="sliderNum">7</span>
              <span class="sliderNum">8</span>
              <span class="sliderNum">9</span>
              <span class="sliderNum">10+</span>
            </div>
          </div>
          <div class="priceGuide notranslate">
            <span class="priceArrow"></span>
            <span>{{ $t('trans.t01_21') }}</span>
            <span class="priceArrow"></span>
            <span>{{ $t('trans.t01_22') }}</span>
          </div>
          <!--<div class="priceBenefit pc-con">
                                <span><img src="../images/benefit3.png"> 장기할인 (3개월)</span>
                                <span><img src="../images/benefit9.png"> 장기할인 (9개월)</span>
                                <span><img src="../images/benefitlabel.png"> 자동 결제 중복할인</span>
                            </div>-->
        </div>
      </div>
      <div class="right">
        <div class="rangeSection">
          <p class="mainTit fW-R notranslate">
            {{ $t('trans.t01_23') }}</p>
          <p class="info">
            <i class="xi-help-o"></i>
            <span class="tooltiptext">
              자동결제를 신청하시면 월 1,000~3,000원(요금제별 상이)의 할인 혜택을 드립니다. 할인은 2회차 자동 납부분부터 적용됩니다.
            </span>
          </p>
          <div class="switchAr">
            <div class="onoffswitch">
              <input
                  type="checkbox"
                  name="onoffswitch"
                  class="onoffswitch-checkbox"
                  id="myonoffswitch"
                  @click="onAutoPay"
                  :checked="switchOn"
              />
              <label class="onoffswitch-label"
                     for="myonoffswitch">
                <span
                    class="onoffswitch-inner"></span>
                <span
                    class="onoffswitch-switch"></span>
              </label>
            </div>
          </div>
          <p class="subTxt mo-con">
            한 번 등록 후 매월 편리하게 할인 받으세요.
          </p>
          <div
              class="priceGuide pc-con notranslate">
            <p>{{ $t('trans.t01_24') }}</p>
          </div>
          <!--<div class="priceBenefit pc-con">
                            <p>총 00% 할인이 적용된 요금입니다.</p>
                        </div>-->
          <!-- 토글 스위치 on 팝업 -->
          <div class="onPop" v-show="!switchOn">
            1,000원 ~ 3,000원
          </div>
          <!-- 토글 스위치 on 팝업 end -->
          <div class="plusAr pc-con">+</div>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
    <!-- 요금제 선택 -->
    <div class="mainTab-contentWrap">
      <!-- BEST 요금제 -->
      <div
          :class="['mainTab-content', { current: selectedTab === 1 }]"
          id="trafficBest"
      >
        <div class="mainPriceAr">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <swiper class="swiper"
                      :options="swiperOptionPlanBest">
                <swiper-slide
                    v-for="(item, index) in planlist.filter((x) => x.rt_seqnum === 1)"
                    :key="index"
                >
                  <div class="planTb">
                    <span class="priceTit">
                      <strong>{{
                          item.agency
                        }}</strong
                      >{{ item.name }}
                      <!-- <b>{{item.data_text}}</b> -->
                    </span>
                    <ul class="planAr">
                      <li>
                        <span class="iconAr">
                          <img
                              src="../images/iconData.png"/>
                        </span>
                        <span
                            class="iconName notranslate">
                          {{ $t('trans.t01_25') }}
                        </span>
                        <span class="iconVol">
                          {{ item.data_text }}
                        </span>
                      </li>
                      <li>
                        <span class="iconAr">
                          <img
                              src="../images/iconPhone.png"/>
                        </span>
                        <span
                            class="iconName notranslate">
                          {{ $t('trans.t01_26') }}
                        </span>
                        <span class="iconVol">
                          {{ item.voice_text }}
                        </span>
                      </li>
                      <li>
                        <span class="iconAr ">
                          <img
                              src="../images/iconSms.png"/>
                        </span>
                        <span
                            class="iconName notranslate">
                          {{ $t('trans.t01_27') }}
                        </span>
                        <span class="iconVol">
                          {{ item.sms_text }}
                        </span>
                      </li>
                    </ul>
                    <span class="planPrice">
                      <div class="priceWrap">
                        월<strong>{{
                          item.amount | currency
                        }}</strong
                      >원
                      </div>
                      <div class="btnWrap">
                        <router-link
                            class="btnTem"
                            :to="{
                            name: 'Step1View',
                            query: {
                              rate: item.seqnum,
                            },
                          }"
                        ><span
                            class='notranslate'>{{ $t('trans.t01_28') }}</span></router-link
                        >                        
                        <a @click="onShowDetailPop(item)"
                           style="cursor:pointer;"
                           class="btnTem wh notranslate"
                           id="psPop">{{ $t('trans.t01_29') }}</a>
                      </div>
                    </span>
                  </div>
                </swiper-slide>
              </swiper>
            </div>

            <div id="bestpage"
                 class="swiper-pagination"
                 slot="pagination"></div>
          </div>
          <div id="bestprev"
               class="swiper-button-prev pc-con"></div>
          <div id="bestnext"
               class="swiper-button-next pc-con"></div>
          <div class="viewAll mo-con"
               @click="$router.push({name:'PaymentSystemView'})">
            전체 요금제 보기
          </div>
        </div>
      </div>
      <!-- 대용량 요금제 -->
      <div
          :class="['mainTab-content', { current: selectedTab === 2 }]"
          id="trafficLarge"
      >
        <div class="mainPriceAr">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <swiper class="swiper"
                      :options="swiperOptionPlanLarge">
                <swiper-slide
                    v-for="(item, index) in planlist.filter((x) => x.rt_seqnum === 2)"
                    :key="index"
                >
                  <div class="planTb">
                    <span class="priceTit">
                      <strong>{{
                          item.agency
                        }}</strong
                      >{{ item.name }}
                      <!-- <b>{{item.data_text}}</b> -->
                    </span>
                    <ul class="planAr">
                      <li>
                        <span class="iconAr">
                          <img
                              src="../images/iconData.png"/>
                        </span>
                        <span
                            class="iconName notranslate">
                          {{ $t('trans.t01_25') }}
                        </span>
                        <span class="iconVol">
                          {{ item.data_text }}
                        </span>
                      </li>
                      <li>
                        <span class="iconAr">
                          <img
                              src="../images/iconPhone.png"/>
                        </span>
                        <span
                            class="iconName notranslate">
                          {{ $t('trans.t01_26') }}
                        </span>
                        <span class="iconVol">
                          {{ item.voice_text }}
                        </span>
                      </li>
                      <li>
                        <span class="iconAr">
                          <img
                              src="../images/iconSms.png"/>
                        </span>
                        <span
                            class="iconName notranslate">
                          {{ $t('trans.t01_27') }}
                        </span>
                        <span class="iconVol">
                          {{ item.sms_text }}
                        </span>
                      </li>
                    </ul>
                    <span class="planPrice">
                      <div class="priceWrap"
                           >
                        월<strong>{{
                          item.amount | currency
                        }}</strong
                      >원
                      </div>
                      <div class="btnWrap">
                        <router-link
                            class="btnTem"
                            :to="{
                            name: 'Step1View',
                            query: {
                              rate: item.seqnum,
                            },
                          }"
                        ><span
                            class='notranslate'>{{ $t('trans.t01_28') }}</span></router-link
                        >                        
                        <a @click="onShowDetailPop(item)"
                           style="cursor:pointer;"
                           class="btnTem wh notranslate"
                           id="psPop">{{ $t('trans.t01_29') }}</a>

                      </div>
                    </span>
                  </div>
                </swiper-slide>
              </swiper>
            </div>

            <div id="largepage"
                 class="swiper-pagination"></div>
          </div>
          <div id="largeprev"
               class="swiper-button-prev pc-con"></div>
          <div id="largenext"
               class="swiper-button-next pc-con"></div>
        </div>
      </div>
      <!-- 실속형 요금제 -->
      <div
          :class="['mainTab-content', { current: selectedTab === 3 }]"
          id="trafficSubstance"
      >
        <div class="mainPriceAr">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <swiper class="swiper"
                      :options="swiperOptionPlanStall">
                <swiper-slide
                    v-for="(item, index) in planlist.filter((x) => x.rt_seqnum === 3)"
                    :key="index"
                >
                  <div class="planTb">
                    <span class="priceTit">
                      <strong>{{
                          item.agency
                        }}</strong
                      >{{ item.name }}
                      <!-- <b>{{item.data_text}}</b> -->
                    </span>
                    <ul class="planAr">
                      <li>
                        <span class="iconAr">
                          <img
                              src="../images/iconData.png"/>
                        </span>
                        <span
                            class="iconName notranslate">
                          {{ $t('trans.t01_25') }}
                        </span>
                        <span class="iconVol">
                          {{ item.data_text }}
                        </span>
                      </li>
                      <li>
                        <span class="iconAr">
                          <img
                              src="../images/iconPhone.png"/>
                        </span>
                        <span
                            class="iconName notranslate">
                          {{ $t('trans.t01_26') }}
                        </span>
                        <span class="iconVol">
                          {{ item.voice_text }}
                        </span>
                      </li>
                      <li>
                        <span class="iconAr">
                          <img
                              src="../images/iconSms.png"/>
                        </span>
                        <span
                            class="iconName notranslate">
                          {{ $t('trans.t01_27') }}
                        </span>
                        <span class="iconVol">
                          {{ item.sms_text }}
                        </span>
                      </li>
                    </ul>
                    <span class="planPrice">
                      <div class="priceWrap">
                        월<strong>{{
                          item.amount | currency
                        }}</strong
                      >원
                      </div>
                      <div class="btnWrap">
                        <router-link
                            class="btnTem"
                            :to="{
                            name: 'Step1View',
                            query: {
                              rate: item.seqnum,
                            },
                          }"
                        ><span
                            class='notranslate'>{{ $t('trans.t01_28') }}</span></router-link
                        >                        
                        <a @click="onShowDetailPop(item)"
                           style="cursor:pointer;"
                           class="btnTem wh notranslate"
                           id="psPop">{{ $t('trans.t01_29') }}</a>

                      </div>
                    </span>
                  </div>
                </swiper-slide>
              </swiper>
            </div>

            <div id="stallpage"
                 class="swiper-pagination"></div>
          </div>
          <div id="stallprev"
               class="swiper-button-prev pc-con"></div>
          <div id="stallnext"
               class="swiper-button-next pc-con"></div>
        </div>
      </div>
      <div class="priceDetailAr pc-con">
        <span class="slogan notranslate">
          {{ $t('trans.t01_30') }}
        </span>
        <span
            class="priceDetailBtn notranslate"
            @click="$router.push({ name: 'PaymentSystemView' })"
        >{{ $t('trans.t01_31') }}</span
        >
        <span
            class="priceDetailBtn notranslate"
            @click="$router.push({ name: 'AddServiceView' })"
        >{{ $t('trans.t01_32') }}</span
        >
      </div>
    </div>
    <div class="popWpper paymentSystemPop"
         v-show="isDetailPop">
      <div class="popContainer center">
        <button class="close ic"
                @click="onCloseDetailPop">닫기
        </button>
        <div class="popWP">
          <div class="popContTit line">
            <p class="tit">요금제 상세</p>
          </div>
          <div class="popBody">
            <!-- pc -->
            <div class="priceDetailTb pc-con">
              <table>
                <thead>
                <tr>
                  <th><span
                      class='notranslate'>{{ $t('trans.t01_09') }}</span>
                  </th>
                  <th class="notranslate">{{ $t('trans.t11_02')}}</th>
                  <!-- <th>할인 적용 기본료</th> -->
                  <th class="notranslate">{{ $t('trans.t01_25') }}</th>
                  <th class="notranslate">{{ $t('trans.t01_26') }}</th>
                  <th class="notranslate">{{ $t('trans.t01_27') }}</th>
                  <!-- <th>국제전화</th> -->
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{{ rateDetail.name }}</td>
                  <td :key="rateDetail.amount">{{
                      rateDetail.amount | currency
                    }}원
                  </td>
                  <!-- <td :key="rateDetail.discountedAmt">{{ rateDetail.discountedAmt | currency }}원</td> -->
                  <td :key="rateDetail.data_text">
                    {{ rateDetail.data_text }}
                  </td>
                  <td :key="rateDetail.voice_text">
                    {{ rateDetail.voice_text }}
                  </td>
                  <td :key="rateDetail.sms_text">
                    {{ rateDetail.sms_text }}
                  </td>
                  <!-- <td :key="rateDetail.nationcall">{{ rateDetail.nationcall }}</td> -->
                </tr>
                </tbody>
              </table>

            </div>
            <!-- mobile -->
            <div class="priceDetailTb mo-con">
              <table>
                <thead>
                <tr>
                  <th><span
                      class='notranslate'>{{ $t('trans.t01_09') }}</span>
                  </th>
                  <th>{{ $t('trans.t11_02')}}</th>
                  <!-- <th>할인 적용 기본료</th> -->
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{{ rateDetail.name }}</td>
                  <td>
                    {{
                      rateDetail.amount | currency
                    }}
                  </td>
                  <!-- <td>
                    {{ rateDetail.amount | currency }}
                  </td> -->
                </tr>
                </tbody>
              </table>

            </div>
            <div class="price_content">
              <ul class="chargeGuide">
                <li>
                  <div
                      @click="
                        rateDeatailOnOff.basicOnOff = !rateDeatailOnOff.basicOnOff
                      "
                      class="guideTop"
                      style="display: block;"
                  >
                      <span class="guideTit notranslate">
                        {{$t('trans.t03_08')}}
                      </span>
                    <span class="guideArrow">
                        <span
                            class="arrowDown"></span>
                      </span>
                  </div>
                  <div
                      class="guideBottom"
                      :style="[
                        !rateDeatailOnOff.basicOnOff
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                  >
                    <div
                        v-html="rateDetail.basic_text"></div>
                  </div>
                </li>
                <li>
                  <div
                      @click="
                        rateDeatailOnOff.discountOnOff = !rateDeatailOnOff.discountOnOff
                      "
                      class="guideTop"
                      style="display: block;"
                  >
                      <span class="guideTit notranslate">
                        {{$t('trans.t03_09')}}
                      </span>
                    <span class="guideArrow">
                        <span
                            class="arrowDown"></span>
                      </span>
                  </div>
                  <div
                      class="guideBottom"
                      :style="[
                        !rateDeatailOnOff.discountOnOff
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                  >
                    <div
                        v-html="rateDetail.discount_text"></div>
                  </div>
                </li>
                <li>
                  <div
                      @click="
                        rateDeatailOnOff.overRateOnOff = !rateDeatailOnOff.overRateOnOff
                      "
                      class="guideTop"
                      style="display: block;"
                  >
                      <span class="guideTit notranslate">
                        {{$t('trans.t03_10')}}
                      </span>
                    <span class="guideArrow">
                        <span
                            class="arrowDown"></span>
                      </span>
                  </div>
                  <div
                      class="guideBottom"
                      :style="[
                        !rateDeatailOnOff.overRateOnOff
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                  >
                    <div
                        v-html="rateDetail.overrate_text"></div>
                  </div>
                </li>
                <li>
                  <div
                      @click="
                        rateDeatailOnOff.chargeOnOff = !rateDeatailOnOff.chargeOnOff
                      "
                      class="guideTop"
                      style="display: block;"
                  >
                      <span class="guideTit notranslate">
                        {{$t('trans.t03_11')}}
                      </span>
                    <span class="guideArrow">
                        <span
                            class="arrowDown"></span>
                      </span>
                  </div>
                  <div
                      class="guideBottom"
                      :style="[
                        !rateDeatailOnOff.chargeOnOff
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                  >
                    <div
                        v-html="rateDetail.charge_text"></div>
                  </div>
                </li>
                <li>
                  <div
                      @click="
                        rateDeatailOnOff.noticeOnOff = !rateDeatailOnOff.noticeOnOff
                      "
                      class="guideTop"
                      style="display: block;"
                  >
                      <span class="guideTit notranslate">
                        {{ $t('trans.t03_12')}}
                      </span>
                    <span class="guideArrow">
                        <span
                            class="arrowDown"></span>
                      </span>
                  </div>
                  <div
                      class="guideBottom"
                      :style="[
                        !rateDeatailOnOff.noticeOnOff
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                  >
                    <div
                        v-html="rateDetail.notice_text"></div>
                  </div>
                </li>
              </ul>
            </div>
            <!-- 가입 신청 버튼 -->
            <router-link
                class="selectBtn notranslate"
                :to="{
                  name: 'Step1View',
                  query: { rate: rateDetail.seqnum },
                }"
            >{{$t('trans.t03_13')}}
            </router-link
            >
          </div>
        </div>
      </div>
      <div class="dim"></div>
    </div>
  </div>
</template>

<script>
import {
  Swiper,
  SwiperSlide
} from "vue-awesome-swiper";
import RangeSlider
  from "../js/lib/rangeslider.js";
import "../css/lib/swiper.css";
import {product} from "../api/index";
import {orderMixin} from "../mixin/storeMixin";
import * as util from "../js/util";

const rateDetail = {
  basicOnOff: false,
  overRateOnOff: false,
  discountOnOff: false,
  chargeOnOff: false,
  noticeOnOff: false,
};

export default {
  name: "MainPlan",
  title: "Multiple slides per view",
  components: {
    Swiper,
    SwiperSlide,
  },
  mixins: [orderMixin],
  methods: {
    async fetchPlans() {
      const res = await product.fetchPlans();
      this.planlist = res.data.ResponseData;

      let discountArr = [];
      let objs = [];
      for (const item of res.data.ResponseData) {
        if (item.discountJson) {
          discountArr.push(JSON.parse(item.discountJson));
        }
      }

      // 회차할인 중복제거
      const mapDiscount = new Map();
      for (const item of discountArr) {
        for (const item2 of item) {
          if (!mapDiscount.has(item2.id)) {
            mapDiscount.set(item2.id, true);
            objs.push(item2);
          }
        }
      }
      this.discounts = objs;

      // 플랜중복제거
      const mapPlans = new Map();
      for (const item of this.planlist) {
        if (!mapPlans.has(item.name_cd)) {
          mapPlans.set(item.name_cd, true);
          this.plans.push({
            name_cd: item.name_cd,
            amount: item.amount,
          });
        }
      }
      this.planlist = util.sortBy(this.planlist, 'orderby')
    },

    onUpdateAmount() {
      console.log(this.planlist);
    },

    onAutoPay() {
      this.switchOn = !this.switchOn;
      if (!this.switchOn) {
        // 회차할인이 포함된 플랜
        for (const item of this.planlist) {
          item.amount = Number.parseInt(item.amount) - Number.parseInt(item.auto_discountamount)
        }

        return
      }

      for (const item of this.planlist) {
        item.amount = Number.parseInt(item.amount) + Number.parseInt(item.auto_discountamount)
      }
    },

    onShowDetailPop(payload) {
      this.rateDetail = payload;
      this.isDetailPop = true;
      this.isDim = true;
    },

    onCloseDetailPop() {
      this.isDetailPop = false;
      this.isDim = false;
      this.rateDeatailOnOff = {...rateDetail};
    },

    onDiscount(e) {

      const pop = document.querySelector(".rs-popup");
      document.querySelector(
          ".rs-popup"
      ).className = `rs-popup rs-popup-bottom rs-hidden`;

      if (e.value === 4) {
        pop.className = `rs-popup rs-popup-bottom`;
        pop.innerText = `${"1000원 ~ 3000"}원`;
      } else if (e.value === 10) {
        pop.className = `rs-popup rs-popup-bottom`;
        pop.innerText = `${"2000원 ~ 6000"}원`;
      }

      // 선택회차의 할인 금액 조회
      const ldiscounts = this.discounts
      .filter(
          (x) =>
              x.startnumber <= e.value &&
              x.endnumber >= e.value
      )
      .map((x) => {
        return x;
      });

      // 회차할인이 포함된 플랜
      for (const item of this.planlist) {
        // 회차할인이 포함된 플랜이 있는경우
        let disAmt = 0;
        let amtOrigin = 0;

        amtOrigin = this.plans
        .filter((x) => x.name_cd === item.name_cd)
        .map((x) => x.amount);

        item.amount = amtOrigin;

        //플랜 할인 회차 할인금액이 존재하면
        if (ldiscounts.find((x) => x.name_cd === item.name_cd)) {
          // 플랜 할인 금액
          disAmt = ldiscounts
          .filter((x) => x.name_cd === item.name_cd)
          .map((x) => {
            return x.discountamount;
          });

          item.amount = item.amount - disAmt;

          //disAmt = 0;
        }

        item.amount = (item.amount) - (!this.switchOn ? item.auto_discountamount : 0);

        disAmt = 0;
      }
    }
  },

  created() {
    this.fetchPlans();
  },

  data() {
    return {
      planlist: [], // 플랜원본
      plans: [], //플랜 복사본
      discounts: [], //플랜별 회차 할인
      slideRange: "",
      isDetailPop: false,
      rateDetail: {},
      rateDeatailOnOff: {...rateDetail},
      swiperOptionPlanBest: {
        grabCursor: false,
        centeredSlides: false,
        slidesPerView: 'auto',
        loop: false,
        spaceBetween: 10,
        slidesPerColumn: 1,
        //loop: true,
        navigation: {
          nextEl: "#bestnext",
          prevEl: "#bestprev",
        },
        pagination: {
          el: "#bestpage",
          clickable: true,
        },
        // autoplay:{
        //     delay:1000
        // }
      },
      swiperOptionPlanLarge: {
        grabCursor: false,
        centeredSlides: false,
        slidesPerView: 'auto',
        loop: false,
        spaceBetween: 10,
        slidesPerColumn: 1,
        //loop: true,
        navigation: {
          nextEl: "#largenext",
          prevEl: "#largeprev",
        },
        pagination: {
          el: "#largepage",
          clickable: true,
        },
        // autoplay:{
        //     delay:1000
        // }
      },
      swiperOptionPlanStall: {
        grabCursor: false,
        centeredSlides: false,
        slidesPerView: 'auto',
        loop: false,
        spaceBetween: 10,
        slidesPerColumn: 1,
        //loop: true,
        navigation: {
          nextEl: "#stallnext",
          prevEl: "#stallprev",
        },
        pagination: {
          el: "#stallpage",
          clickable: true,
        },
        // autoplay:{
        //     delay:3000
        // }
      },
      selectedTab: 1,
      switchOn: true,
    };
  },

  async mounted() {
    this.slideRange = new RangeSlider(document.getElementById("slider-max"), {
      step: 1,
      min: 1,
      max: 11,
      value: 1,
      unit: "회차",
      width: "600px",
      design: "3d",
      showMinMaxLabels: false,
      showCurrentValueLabel: false,
      labelsPosition: "bottom",
      popup: "bottom",
      theme: "attention",
      handle: "round",
      size: "large",
      visible: true,
    });
  },

  watch: {
    slideRange: {
      handler(val) {
        this.onDiscount(val)
      },
      deep: true
    },

    switchOn: {
      handler(val) {
        this.onDiscount(this.slideRange)
      }
    }
  },

};
</script>
