var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap subPage"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"subTitAr"},[_c('h1',{staticClass:"notranslate"},[_vm._v(_vm._s(_vm.$t('trans.t09_06')))]),_c('span',{staticClass:"subTit"},[_vm._v("도시락 Prepaid 이용 시 신청 가능한 부가 서비스입니다.")])]),_c('div',{staticClass:"subPageAr"},[_c('div',{staticClass:"addTabAr"},[_c('ul',{staticClass:"addTab"},_vm._l((_vm.addServiceHeader),function(item){return _c('li',{key:item.category_cd,class:[
                            'addTab-link',
                            {
                                current:
                                    _vm.selectedCategory === item.category_cd,
                            } ],on:{"click":function($event){_vm.selectedCategory = item.category_cd}}},[_vm._v(" "+_vm._s(item.category)),_c('span')])}),0)]),_vm._l((_vm.addServiceHeader),function(head,index){return _c('div',{key:index,class:[
                    'addTab-content',
                    { current: _vm.selectedCategory === head.category_cd } ]},[_c('div',{staticClass:"addAr"},[_c('ul',{staticClass:"addListDetail"},_vm._l((_vm.addServiceBody.filter(
                                function (x) { return x.category_cd === head.category_cd; }
                            )),function(body,index){return _c('div',{key:index},[_c('li',[_vm._v(" "+_vm._s(body.category)+" "),_c('div',{staticClass:"detailCon"},[_c('span',{staticClass:"option"},[_vm._v(_vm._s(body.amount === 0 ? "무료" : "유료"))]),_c('span',{staticClass:"mainTit"},[_vm._v(_vm._s(body.extraservice))]),_c('span',{staticClass:"mainTxt"},[_vm._v(_vm._s(body.reference))])]),_c('div',{staticClass:"detailPrice"},[_c('span',{staticClass:"addPrice"},[_vm._v("월 "+_vm._s(_vm._f("currency")(body.amount))+"원")]),_c('span',{staticClass:"addArrow",on:{"click":function($event){return _vm.showDetail(body)}}},[_c('span',{staticClass:"arrowDown",attrs:{"onclick":"this.classList.toggle('active')"}})])]),_c('div',{staticStyle:{"clear":"both"}})]),(body.isShowText)?_c('div',{staticClass:"detailNote",attrs:{"id":'deatailNote' + body.seqnum}},[_c('span',{staticClass:"noteTit"},[_vm._v("[상세안내]")]),_c('span',{staticClass:"noteTxt",domProps:{"innerHTML":_vm._s(body.detail_info)}})]):_vm._e()])}),0)])])})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }