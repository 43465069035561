<template>
    <div class="mainVisualAr swiper-container">
        <div class="swiper-wrapper">

            <swiper class="swiper" :options="swiperOption">

                <swiper-slide>
                    <div class="mainBan notranslate">
                        <div class="mainBanTxt" v-html="$t('trans.t01_06')">
                        </div>
                        <div class="element first">
                            <img v-if="isMobile" src="../images/mo_mainBanFirst_img.png" />
                            <img v-else src="../images/mainBanFirst_img.png" />
                        </div>
                    </div>
                </swiper-slide>

            </swiper>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination"></div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
// import "swiper/swiper-bundle.css";

export default {
    name: "MainTopSlide",
    title: "Multiple slides per view",
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        plan: Array,
    },
    data() {
        return {
            swiperOption: {
                //loop: true,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                // pagination: {
                //     el: ".mainVisualAr .swiper-pagination",
                //     clickable: true,
                // },
                navigation: {
                    nextEl: ".mainVisualAr .swiper-button-next",
                    prevEl: ".mainVisualAr .swiper-button-prev",
                },
                breakpoints: {
                    // 반응형
                    //1079 : { // 테블릿
                    //  slidesPerView : 2
                    //},
                    450: {
                        // 모바일
                        slidesPerView: 1,
                    },
                },                            
            },
        };
    },
    filters: {
        currency(payload) {
            return String(payload).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    },
};
</script>

<style scoped>
</style>
