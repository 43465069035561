<template>
  <div v-cloak>
    <div class="wrap subPage">
      <div class="container">
        <!-- sub title -->
        <div class="subTitAr">
          <h1><span
              class="notranslate">{{
              $t('trans.t02_01')
            }}</span>
          </h1>
          <span
              class="subTit fW-L notranslate">{{
              $t('trans.t02_02')
            }}</span
          >
        </div>
        <!-- 서브페이지 컨텐츠 -->
        <div class="chargeAr">
          <!-- 타이틀 -->
          <div class="chargeArTit">
            <img
                src="../../images/chargeTit.png"/>
          </div>
          <!-- 상단 select box -->
          <div class="chargeSelect">
            <ul>
              <!-- 월 납입금액 -->
              <li>
                <span class="menuTit notranslate">{{
                    $t('trans.t02_03')
                  }}</span>
                <select
                    @change="onSelectedAmount"
                    :value="rangeFilter"
                    v-model="rangeFilter"
                >
                  <option value=""><span
                      class='notranslate'>{{
                      $t('trans.t02_04')
                    }}</span>
                  </option>
                  <option value="1" data-min="0"
                          data-max="30000">30,000원
                    이하
                  </option>
                  <option value="2"
                          data-min="30000"
                          data-max="40000"
                  >30,000~40,000원
                  </option
                  >
                  <option value="3"
                          data-min="40000"
                          data-max="50000"
                  >40,000~50,000원
                  </option
                  >
                  <option value="4"
                          data-min="50000"
                          data-max="1000000"
                  >50,000원 이상
                  </option
                  >
                </select>
              </li>
              <!-- 데이터 사용량 -->
              <li>
                <span class="menuTit notranslate">{{
                    $t('trans.t02_05')
                  }}</span>
                <select
                    @change="onSelectedDataUsage"
                    :value="dataUsageFilter"
                    v-model="dataUsageFilter"
                >
                  <option value=""><span
                      class='notranslate'>{{
                      $t('trans.t02_04')
                    }}</span>
                  </option>
                  <option value="1" data-min="0"
                          data-max="2000">2GB 이하
                  </option>
                  <option value="2"
                          data-min="2000"
                          data-max="5000">2~50GB
                  </option>
                  <option value="3"
                          data-min="50000"
                          data-max="100000"
                  >50GB~100GB
                  </option
                  >
                  <option value="4"
                          data-min="100000"
                          data-max="1000000"
                  >100GB 이상
                  </option
                  >
                </select>
              </li>
              <!-- 통화 사용량 -->
              <li>
                <span class="menuTit notranslate">{{
                    $t('trans.t02_06')
                  }}</span>
                <select
                    @change="onSelectedVoiceUsage"
                    :value="voiceUsageFilter"
                    v-model="voiceUsageFilter"
                >
                  <option value=""><span
                      class='notranslate'>{{
                      $t('trans.t02_04')
                    }}</span>
                  </option>
                  <option value="1" data-min="0"
                          data-max="200">200분 이하
                  </option>
                  <option value="2" data-min="200"
                          data-max="500">200~500분
                  </option>
                  <option value="3" data-min="500"
                          data-max="1000000"
                  >500분 이상
                  </option
                  >
                  >
                </select>
              </li>
            </ul>
            <div style="clear: both;"></div>
            <div class="menuSearchAr">
              <button
                  class="menuSearch notranslate"
                  :key="$t('trans.t15_05')"
                  @click="planFilter">
                {{ $t('trans.t15_05') }}
              </button>
            </div>
          </div>
          <!-- 요금제 리스트 -->
          <div class="chargeListAr">
            <!-- 상단 영역 -->
            <div class="listTop">
              <div class="left">
                <span
                    class="listNote notranslate">{{
                    $t('trans.t02_07')
                  }}</span>
              </div>
              <div class="right">
                <!--<select>
                            <option>요금제 타입 전체</option>
                            <option>정액제</option>
                            <option>종량제</option>
                        </select>-->
                <select @change="onChangeOrder">
                  <option data-col="orderby"
                          data-order="asc"
                          value="1"
                  ><span
                      class='notranslate'>{{
                      $t('trans.t02_08')
                    }}</span>
                  </option
                  >
                  <option data-col="amount"
                          data-order="asc"
                          value="2"
                  ><span
                      class='notranslate'>{{
                      $t('trans.t02_09')
                    }}</span>
                  </option
                  >
                  <option data-col="amount"
                          data-order="desc"
                          value="3"
                  ><span
                      class='notranslate'>{{
                      $t('trans.t02_10')
                    }}</span>
                  </option
                  >
                </select>
                <ul class="chargeTab pc-con">
                  <li
                      :class="['chargeTab-link', { active: isList }]"
                      @click="isList = !isList"
                  ></li>
                  <li
                      :class="['chargeTab-link', { active: !isList }]"
                      @click="isList = !isList"
                  ></li>
                </ul>
              </div>
            </div>
            <div style="clear: both;"></div>
          </div>
          <div></div>

          <!-- 요금표 리스트형 -->
          <div
              :class="['chargeTab-content', { active: isList }]">
            <ul class="chargeListDetail">
              <li v-for="(item, index) in planFilters"
                  :key="index">
                <div class="top">
                  <div class="listLt">
                    <span class="listTitle"
                    >[{{
                        item.agency
                      }}] {{ item.name }}</span
                    >
                  </div>

                  <div class="listRt">
                    <span
                        class="saleCharge"
                        v-show="
                        !switchOn ||
                          Object.keys(getStepDiscount(item.name_cd)).length > 0
                      "
                    >할인 적용</span
                    >
                    <span
                        class="listCharge"
                    >{{ $t('trans.t02_12') }}
                      <strong>{{
                          item.amount | currency
                        }}원</strong></span
                    >
                  </div>
                </div>
                <div class="listLt mT10">
                  <div class="basicZone">
                    <span class="option data">{{
                        item.data_text
                      }}</span>
                    <span class="option voice">{{
                        item.voice_text
                      }}</span>
                    <span class="option sms">{{
                        item.sms_text
                      }}</span>
                    <span></span>
                    <span></span>
                  </div>

                  <div class="benefitZone">
                    <span
                        v-for="(item, index) in getStepDiscount(item.name_cd)"
                        :key="index"
                    >
                      <span
                          :class="[
                          'option',
                          {
                            second: item.discountamount === 3000,
                            first: item.discountamount === 1000,
                            third: item.discountamount === 2000,
                            fourth: item.discountamount === 4000,
                            fifth: item.discountamount === 5000,
                            sixth: item.discountamount === 6000,
                          },
                        ]"
                      >장기할인 ({{
                          item.startnumber
                        }}회차부터)</span
                      >
                    </span>

                    <span
                        v-show="
                        item.longdiscountyn === 'Y' &&
                          item.auto_discountamount > 0 &&
                          !switchOn &&
                          item.auto_discountamount <= 6000
                      "
                        :class="[
                        'option',
                        {
                            second: item.auto_discountamount === 3000,
                            first: item.auto_discountamount === 1000,
                            third: item.auto_discountamount === 2000,
                            fourth: item.auto_discountamount === 4000,
                            fifth: item.auto_discountamount === 5000,
                            sixth: item.auto_discountamount === 6000,
                        },
                      ]"
                    >자동결제할인</span
                    >
                  </div>
                </div>
                <div class="listRt mT10">
                  <div class="basicZone">
                    <span
                        class="basicPrice"
                        v-show="
                        !switchOn ||
                          Object.keys(getStepDiscount(item.name_cd)).length > 0
                      "
                    >월 기본료
                      {{
                        getOrignAmount(item.name_cd) | currency
                      }}원</span
                    >
                  </div>
                  <div class="benefitZone">
                    <span
                        class="detailBtn notranslate"
                    ><a @click="onShowDetailPop(item)">{{ $t('trans.t01_29') }}</a></span
                    >
                    <span
                        class="joinBtn notranslate">
                      <router-link
                          :to="{ name: 'Step1View', query: { rate: item.seqnum } }"
                      >{{ $t('trans.t01_28') }}</router-link
                      >
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <!-- 요금표 카드형 -->
          <!-- 대용량 -->
          <div
              :class="['chargeTab-content', { active: !isList }, 'pc-con']">
            <!-- <div class="priceTitAr">
                        <span class="priceTit">대용량 도시락팩</span>
                        <span class="priceSub"
                            >월 10GB 이상 데이터 제공으로 걱정없이</span
                        >
                    </div> -->
            <div class="chargeLargeAr fW-L"
                 v-show="allPlanList.length > 0">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <swiper
                      class="swiper"
                      :options="swiperOptionPlanAll"
                  >
                    <swiper-slide
                        class="swiper-slide"
                        v-for="(item, index) in allPlanList"
                        :key="index"
                    >
                      <div class="planTb">
                        <span class="priceTit">
                          <strong>{{
                              item.agency
                            }}</strong>
                          {{ item.name }}
                        </span>
                        <ul class="planAr">
                          <li>
                            <span class="iconAr">
                              <img
                                  src="../../images/iconData.png"/>
                            </span>
                            <span
                                class="iconName">
                              데이터
                            </span>
                            <span class="iconVol">
                              {{ item.data_text }}
                            </span>
                          </li>
                          <li>
                            <span class="iconAr">
                              <img
                                  src="../../images/iconPhone.png"/>
                            </span>
                            <span
                                class="iconName">
                              통화
                            </span>
                            <span class="iconVol">
                              {{
                                item.voice_text
                              }}
                            </span>
                          </li>
                          <li>
                            <span class="iconAr">
                              <img
                                  src="../../images/iconSms.png"/>
                            </span>
                            <span
                                class="iconName">
                              문자
                            </span>
                            <span class="iconVol">
                              {{ item.sms_text }}
                            </span>
                          </li>
                        </ul>
                        <span class="planPrice">
                          <div class="priceWrap">
                            월<strong
                              :key="item.amount">{{
                              item.amount | currency
                            }}</strong
                          >원
                          </div>
                          <div class="btnWrap">
                            <a class="btnTem wh"
                               @click="onShowDetailPop(item)"
                            >자세히보기</a
                            >
                            <router-link
                                tag="a"
                                class="btnTem"
                                :to="{
                                name: 'Step1View',
                                query: { rate: item.seqnum },
                              }"
                            >가입하기</router-link
                            >
                          </div>
                        </span>
                      </div>
                    </swiper-slide>
                  </swiper>
                </div>
                <!-- Add Pagination -->
                <div id="allPage"
                     class="swiper-pagination"></div>
              </div>
              <div id="allprev"
                   class="swiper-button-prev pc-con"></div>
              <div id="allnext"
                   class="swiper-button-next pc-con"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 예상금액 플로팅 -->
      <div id="priceFloat">
        <div class="priceFloatAr">
          <div class="lt wide fW-L">
            <!--<span class="benefitTxt"><img src="../images/benefit3.png"> 장기할인 (3개월)</span>
            <span class="benefitTxt"><img src="../images/benefit9.png"> 장기할인 (9개월)</span>
            <span class="benefitTxt"><img src="../images/benefitlabel.png"> 자동 결제 중복할인</span>-->
            <span class="benefitPrice notranslate"
            >{{ $t('trans.t02_11') }}</span
            >
          </div>
          <div class="rt narrow">
            <span
                :class="['arrowPrice', { active: isExtention }]"
                @click="isExtention = !isExtention"
            ></span>
          </div>
        </div>
        <div class="pricefDetail"
             id="pricefDetail"
             v-show="isExtention">
          <div class="rangeAr mT30 mB20">
            <div class="left">
              <div class="rangeSection">
                <p class="mainTit fW-R"
                   style="color: #fff;">
                  장기 할인
                </p>
                <p class="info">
                  <i class="xi-help-o"
                     style="color: #f7d05d;"></i>
                  <span class="tooltiptext">
                    연속 사용기간에 따라 기본료 할인 혜택을 드리는 제도로 할인 적용
                    시점의 예상 납부 요금을 미리 확인하실 수 있습니다. (요금제별
                    할인금액 상이)<br/>
                    - 4회차 부터 : 1,000~3,000원 할인<br/>
                    - 10회차부터 : 2,000~6,000원 할인
                  </span>
                </p>
                <p class="subTxt fW-L"
                   style="color: #fff;">
                  사용 할수록 저렴해지는 요금을 미리 확인하세요.
                </p>
                <!--<input type="range" min="0" max="100" value="0">-->
                <div id="slider-max">
                  <div class="numWrap">
                    <span class="sliderNum"
                          style="padding-left: 14px;">1</span>
                    <span
                        class="sliderNum">2</span>
                    <span
                        class="sliderNum">3</span>
                    <span
                        class="sliderNum">4</span>
                    <span
                        class="sliderNum">5</span>
                    <span
                        class="sliderNum">6</span>
                    <span
                        class="sliderNum">7</span>
                    <span
                        class="sliderNum">8</span>
                    <span
                        class="sliderNum">9</span>
                    <span
                        class="sliderNum">10</span>
                  </div>
                </div>
                <div class="priceGuide fW-L">
                  <span class="priceArrow"></span>
                  <span
                      style="color: #f7d05d;">할인</span>
                  <span class="priceArrow"></span>
                  <span style="color: #f7d05d;">추가 할인</span>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="rangeSection">
                <p class="mainTit fW-R"
                   style="color: #fff;">
                  자동결제 할인
                </p>
                <p class="info">
                  <i class="xi-help-o"
                     style="color: #f7d05d;"></i>
                  <span class="tooltiptext">
                    자동결제를 신청하시면 월 1,000~3,000원(요금제별 상이)의 할인
                    혜택을 드립니다. 할인은 2회차 자동 납부분부터 적용됩니다.
                  </span>
                </p>
                <div class="switchAr">
                  <div class="onoffswitch">
                    <input
                        type="checkbox"
                        name="onoffswitch"
                        class="onoffswitch-checkbox"
                        id="myonoffswitch"
                        @click="switchOn = !switchOn"
                        :checked="switchOn"
                    />
                    <label
                        class="onoffswitch-label"
                        for="myonoffswitch">
                      <span
                          class="onoffswitch-inner"></span>
                      <span
                          class="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
                <p class="subTxt mo-con fW-L"
                   style="color: #fff;">
                  사용 할수록 저렴해지는 요금을 미리 확인하세요.
                </p>
                <div class="priceGuide pc-con">
                  <p style="color: #f7d05d;">
                    중복할인</p>
                </div>
                <div class="onPop"
                     v-show="!switchOn">1,000원 ~
                  3,000원
                </div>
                <div class="plusAr pc-con"
                     style="color: #f7d05d;">
                  +
                </div>
              </div>
            </div>
            <div style="clear: both;"></div>
          </div>
        </div>
      </div>

      <div class="popWpper paymentSystemPop"
           v-show="isDetailPop">
        <div class="popContainer center">
          <button class="close ic"
                  @click="onCloseDetailPop">닫기
          </button>
          <div class="popWP">
            <div class="popContTit line">
              <p class="tit notranslate">
                {{ $t('trans.t03_01') }}</p>
            </div>
            <div class="popBody">
              <!-- pc -->
              <div class="priceDetailTb pc-con">
                <table>
                  <thead>
                  <tr class='notranslate'>
                    <th>{{ $t('trans.t01_09') }}
                    </th>
                    <th>30일 기본료</th>
                    <!-- <th>할인 적용 기본료</th> -->
                    <th>{{ $t('trans.t01_25') }}
                    </th>
                    <th>{{ $t('trans.t01_26') }}
                    </th>
                    <th>{{ $t('trans.t01_27') }}
                    </th>
                    <!-- <th>국제전화</th> -->
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{{ rateDetail.name }}</td>
                    <td :key="rateDetail.amount">
                      {{
                        rateDetail.amount | currency
                      }}원
                    </td>
                    <!-- <td>{{ rateDetail.amount }}</td> -->
                    <td>{{
                        rateDetail.data_text
                      }}
                    </td>
                    <td>{{
                        rateDetail.voice_text
                      }}
                    </td>
                    <td>{{
                        rateDetail.sms_text
                      }}
                    </td>
                    <!-- <td>{{rateDetail.nationcall}}</td> -->
                  </tr>
                  </tbody>
                </table>

              </div>
              <!-- mobile -->
              <div class="priceDetailTb mo-con">
                <table>
                  <thead>
                  <tr>
                    <th>
                      {{ $t('trans.t01_09') }}
                    </th>
                    <th>30일 기본료</th>
                    <!-- <th>할인 적용 기본료</th> -->
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{{ rateDetail.name }}</td>
                    <td>
                      {{
                        rateDetail.amount | currency
                      }}
                    </td>
                    <!-- <td>
                      {{ rateDetail.amount | currency }}
                    </td> -->
                  </tr>
                  </tbody>
                </table>

              </div>
              <div class="price_content">
                <ul class="chargeGuide">
                  <li>
                    <div
                        @click="
                        rateDeatailOnOff.basicOnOff = !rateDeatailOnOff.basicOnOff
                      "
                        class="guideTop"
                        style="display: block;"
                    >
                      <span
                          class="guideTit notranslate">
                        {{ $t('trans.t03_08') }}
                      </span>
                      <span class="guideArrow">
                        <span
                            class="arrowDown"></span>
                      </span>
                    </div>
                    <div
                        class="guideBottom"
                        :style="[
                        !rateDeatailOnOff.basicOnOff
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                    >
                      <div
                          v-html="rateDetail.basic_text"></div>
                    </div>
                  </li>
                  <li>
                    <div
                        @click="
                        rateDeatailOnOff.discountOnOff = !rateDeatailOnOff.discountOnOff
                      "
                        class="guideTop"
                        style="display: block;"
                    >
                      <span
                          class="guideTit notranslate">
                        {{ $t('trans.t03_09') }}
                      </span>
                      <span class="guideArrow">
                        <span
                            class="arrowDown"></span>
                      </span>
                    </div>
                    <div
                        class="guideBottom"
                        :style="[
                        !rateDeatailOnOff.discountOnOff
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                    >
                      <div
                          v-html="rateDetail.discount_text"></div>
                    </div>
                  </li>
                  <li>
                    <div
                        @click="
                        rateDeatailOnOff.overRateOnOff = !rateDeatailOnOff.overRateOnOff
                      "
                        class="guideTop"
                        style="display: block;"
                    >
                      <span
                          class="guideTit notranslate">
                        {{ $t('trans.t03_10') }}
                      </span>
                      <span class="guideArrow">
                        <span
                            class="arrowDown"></span>
                      </span>
                    </div>
                    <div
                        class="guideBottom"
                        :style="[
                        !rateDeatailOnOff.overRateOnOff
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                    >
                      <div
                          v-html="rateDetail.overrate_text"></div>
                    </div>
                  </li>
                  <li>
                    <div
                        @click="
                        rateDeatailOnOff.chargeOnOff = !rateDeatailOnOff.chargeOnOff
                      "
                        class="guideTop"
                        style="display: block;"
                    >
                      <span
                          class="guideTit notranslate">
                        {{ $t('trans.t03_11') }}
                      </span>
                      <span class="guideArrow">
                        <span
                            class="arrowDown"></span>
                      </span>
                    </div>
                    <div
                        class="guideBottom"
                        :style="[
                        !rateDeatailOnOff.chargeOnOff
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                    >
                      <div
                          v-html="rateDetail.charge_text"></div>
                    </div>
                  </li>
                  <li>
                    <div
                        @click="
                        rateDeatailOnOff.noticeOnOff = !rateDeatailOnOff.noticeOnOff
                      "
                        class="guideTop"
                        style="display: block;"
                    >
                      <span
                          class="guideTit notranslate">
                        {{ $t('trans.t03_12') }}
                      </span>
                      <span class="guideArrow">
                        <span
                            class="arrowDown"></span>
                      </span>
                    </div>
                    <div
                        class="guideBottom"
                        :style="[
                        !rateDeatailOnOff.noticeOnOff
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                    >
                      <div
                          v-html="rateDetail.notice_text"></div>
                    </div>
                  </li>
                </ul>
              </div>
              <!-- 가입 신청 버튼 -->
              <router-link
                  class="selectBtn notranslate"
                  :to="{
                  name: 'Step1View',
                  query: { rate: rateDetail.seqnum },
                }"
              >{{ $t('trans.t03_13') }}
              </router-link
              >
            </div>
          </div>
        </div>
        <div class="dim"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {product} from "../../api/index";
import {
  Swiper,
  SwiperSlide
} from "vue-awesome-swiper";
import * as util from "../../js/util";
import RangeSlider
  from "../../js/lib/rangeslider.js";
import {orderMixin} from "../../mixin/storeMixin";

const rateDetail = {
  basicOnOff: false,
  overRateOnOff: false,
  discountOnOff: false,
  chargeOnOff: false,
  noticeOnOff: false,
};

export default {
  name: "PaymentSystem",
  components: {
    Swiper,
    SwiperSlide,
  },
  mixins: [orderMixin],
  data() {
    return {
      isList: true,
      isExtention: false,
      isDetailPop: false,
      rateDetail: {},
      rateDeatailOnOff: {...rateDetail},
      plan: [],
      planAll: [],
      stepDiscount: [],
      rangeFilter: "",
      dataUsageFilter: "",
      voiceUsageFilter: "",
      swiperOptionPlanAll: {
        grabCursor: false,
        centeredSlides: false,
        slidesPerView: 'auto',
        loop: false,
        spaceBetween: 10,
        slidesPerColumn: 1,
        // loop: true,
        navigation: {
          nextEl: "#allnext",
          prevEl: "#allprev",
        },
        pagination: {
          el: "#allPage",
          clickable: true,
        },
      },
      planFilters: [],
      amountFilter: {},
      dataFilter: {},
      voiceFilter: {},
      slideRange: "",
      switchOn: true,
      discounts: [], // 플랜별 회차 할인
      plans: []
    };
  },
  methods: {
    async fetchRate() {
      const [plan, stepDiscount] = await product.fetchRate();

      const resultPlan = [];

      //중복제거
      const map = new Map();
      for (const item of plan.data.ResponseData) {
        if (!map.has(item.seqnum)) {
          map.set(item.seqnum, true);
          resultPlan.push(item);
        }
      }

      this.planAll = plan.data.ResponseData;
      this.plan = resultPlan;
      this.planFilters = util.sortBy(resultPlan, "orderby");
      this.stepDiscount = stepDiscount.data.ResponseData;

      let discountArr = [];
      let objs = [];
      for (const item of plan.data.ResponseData) {
        if (item.discountJson) {
          discountArr.push(JSON.parse(item.discountJson));
        }
      }

      // 회차할인 중복제거
      const mapDiscount = new Map();
      for (const item of discountArr) {
        for (const item2 of item) {
          if (!mapDiscount.has(item2.id)) {
            mapDiscount.set(item2.id, true);
            objs.push(item2);
          }
        }
      }

      this.discounts = objs;

      // 플랜중복제거
      const mapPlans = new Map();
      for (const item of this.planlist) {
        if (!mapPlans.has(item.name_cd)) {
          mapPlans.set(item.name_cd, true);
          this.plans.push({
            name_cd: item.name_cd,
            amount: item.amount,
          });
        }
      }
    },
    getStepDiscount(payload) {
      return this.discounts.filter(
          (x) =>
              x.name_cd === payload &&
              x.startnumber <= this.slideRange.value &&
              x.endnumber >= this.slideRange.value
      );
    },

    getOrignAmount(payload) {
      const amt = this.plans.find((x) => x.name_cd === payload).amount || 0;
      return amt;
    },

    onShowDetailPop(payload) {
      this.rateDetail = payload;
      this.isDetailPop = true;
      this.isDim = true;
    },
    onCloseDetailPop() {
      this.isDetailPop = false;
      this.rateDeatailOnOff = {...rateDetail};
      this.isDim = false;
    },
    onSelectedAmount(e) {
      if (e.target.value === "") {
        this.amountFilter = {};
        //this.planFilter();
        return;
      }

      this.amountFilter = {
        minValue: e.target.options[e.target.selectedIndex].getAttribute("data-min"),
        maxValue: e.target.options[e.target.selectedIndex].getAttribute("data-max"),
      };

      //this.planFilter();
    },
    onSelectedDataUsage(e) {
      if (e.target.value === "") {
        this.dataFilter = {};
        //this.planFilter();
        return;
      }

      this.dataFilter = {
        minValue: e.target.options[e.target.selectedIndex].getAttribute("data-min"),
        maxValue: e.target.options[e.target.selectedIndex].getAttribute("data-max"),
      };

      //this.planFilter();
    },

    onSelectedVoiceUsage(e) {
      if (e.target.value === "") {
        this.voiceFilter = {};
        //this.planFilter();
        return;
      }

      this.voiceFilter = {
        minValue: e.target.options[e.target.selectedIndex].getAttribute("data-min"),
        maxValue: e.target.options[e.target.selectedIndex].getAttribute("data-max"),
      };

      //this.planFilter();
    },

    planFilter() {
      this.planFilters = this.plan;

      if (Object.keys(this.amountFilter).length > 0) {
        this.planFilters = this.planFilters.filter((item) => {
          return (
              item.amount >= this.amountFilter.minValue &&
              item.amount <= this.amountFilter.maxValue
          );
        });
      }

      if (Object.keys(this.dataFilter).length > 0) {
        this.planFilters = this.planFilters.filter((item) => {
          return (
              item.data_value >= this.dataFilter.minValue &&
              item.data_value <= this.dataFilter.maxValue
          );
        });
      }

      if (Object.keys(this.voiceFilter).length > 0) {
        this.planFilters = this.planFilters.filter((item) => {
          return (
              item.voice_value >= this.voiceFilter.minValue &&
              item.voice_value <= this.voiceFilter.maxValue
          );
        });
      }
    },

    onChangeOrder(e) {
      const col = e.target.options[e.target.selectedIndex].getAttribute("data-col");
      const order = e.target.options[e.target.selectedIndex].getAttribute(
          "data-order"
      );

      this.planFilters =
          order === "desc"
              ? util.sortBy(this.planFilters, col).reverse()
              : util.sortBy(this.planFilters, col);
    },

    onDiscount(e) {
      const pop = document.querySelector(".rs-popup");
      document.querySelector(
          ".rs-popup"
      ).className = `rs-popup rs-popup-bottom rs-hidden`;

      if (e.value === 4) {
        pop.className = `rs-popup rs-popup-bottom`;
        pop.innerText = `${"1000원 ~ 3000"}원`;
      } else if (e.value === 10) {
        pop.className = `rs-popup rs-popup-bottom`;
        pop.innerText = `${"2000원 ~ 6000"}원`;
      }

      // 선택회차의 할인 금액 조회
      const ldiscounts = this.discounts
      .filter(
          (x) =>
              x.startnumber <= e.value &&
              x.endnumber >= e.value
      )
      .map((x) => {
        return x;
      });

      // 회차할인이 포함된 플랜
      for (const item of this.planFilters) {
        // 회차할인이 포함된 플랜이 있는경우
        let disAmt = 0;
        let amtOrigin = 0;

        amtOrigin = this.plans
        .filter((x) => x.name_cd === item.name_cd)
        .map((x) => x.amount);

        item.amount = amtOrigin;

        //플랜 할인 회차 할인금액이 존재하면
        if (ldiscounts.find((x) => x.name_cd === item.name_cd)) {
          // 플랜 할인 금액
          disAmt = ldiscounts
          .filter((x) => x.name_cd === item.name_cd)
          .map((x) => {
            return x.discountamount;
          });

          item.amount = item.amount - disAmt;

          //disAmt = 0;
        }

        item.amount = (item.amount) - (!this.switchOn ? item.auto_discountamount : 0);

        disAmt = 0;
      }
    }
  },
  created() {
    this.fetchRate();
  },
  mounted() {
    window.addEventListener("scroll", util.footFloatBaner);
    this.slideRange = new RangeSlider(document.getElementById("slider-max"), {
      step: 1,
      min: 1,
      max: 11,
      value: 0,
      unit: "회차",
      width: "600px",
      design: "3d",
      showMinMaxLabels: false,
      showCurrentValueLabel: false,
      labelsPosition: "bottom",
      popup: "bottom",
      theme: "attention",
      handle: "round",
      size: "large",
      visible: true,
    });
  },

  watch: {
    slideRange: {
      handler(val) {
        this.onDiscount(val)
      },
      deep: true
    },

    switchOn: {
      handler(val) {
        this.onDiscount(this.slideRange)
      }
    }
  },

  computed: {
    planlist() {
      return this.plan;
    },

    allPlanList() {
      return this.planFilters;
    },
  },
  filters: {
    size(payload) {
      return util.bytesToSize(payload);
    },
  },
};
</script>

<style scoped>
.chargeLargeAr,
.chargeSlimAr {
  padding-bottom: 0px;
}

.chargeAr .chargeTab-content {
  margin: 10px;
}
</style>
