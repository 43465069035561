<template>
  <div>
    <div class="wrap mainPage">
      <div class="content container" id='#scroll0'>
        <!-- section main -->
        <section class="section main">
          <main-top-slide></main-top-slide>
        </section>
        
        <!-- section -->
        <section class="section two" :style="browserNameStyle" id="#scroll1" v-if="false">
          <div class="contAr">
            <span class="conTit fW-R">신청부터 개통까지</span>
            <span class="conTxt fW-R underLine">본인인증 필요없이!</span>
            <span class="conTxt fW-R nextLine">누구나 쉽고 빠르게 선불유심 개통</span>
            <div class="slide">
              <ul class="wh">
                <li>
                  <div class="whBg">
                    <div class="txtAr">
                      <span class="number fW-R">01</span>
                      <span class="conTit fW-L notranslate">{{$t('trans.t01_08')}}</span>
                      <span class="txtRed fW-R">본인인증 NO!</span>
                    </div>
                    <div class="imgAr">
                      <img src="../images/mainSirak_1.png" />
                    </div>
                    <div class="btnWp">
                      <button class="btnBlue fW-R" @click="phonePop = true">
                        이용가능 휴대폰 확인
                      </button>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="whBg">
                    <div class="txtAr">
                      <span class="number fW-R">02</span>
                      <span class="conTit fW-L"
                        >유심 배송<strong class='notranslate'>({{$t('trans.t07_38')}})</strong></span
                      >
                      <span class="txtRed fW-R">유심 배송.<span class="notranslate">({{$t('trans.t07_38')}}</span>) 신분증 반드시 확인</span>
                    </div>
                    <div class="imgAr">
                      <img src="../images/mainSirak_2.png" />
                    </div>
                    <div class="btnWp">
                      <div class="noteAr">
                        <span class="noteTit fW-R notranslate">{{$t('trans.t07_38')}}?</span>
                        <span class="noteTxt fW-L"
                          >서비스 기사가 직접 방문하여 신분증 확인 후 유심을
                          전달드립니다.</span
                        >
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="whBg">
                    <div class="txtAr">
                      <span class="number fW-R">03</span>
                      <span class="conTit fW-L">개통 요청 후 사용</span>
                    </div>
                    <div class="imgAr">
                      <img src="../images/mainSirak_3.png" />
                    </div>
                    <div class="btnWp">
                      <button class="btnBlue fW-R" @click="guidePop = isDim = true">
                        개통요청 및 유심분리/장착 가이드
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <!-- section -->
        <section class="section three" id="#scroll2">
          <div class="contAr notranslate">
            <div v-html="$t('trans.t01_10')"></div>"

            <div class="slide">
              <ul class="yBorder">
                <li>
                  <div class="yellowB">
                    <div class="imgAr">
                      <img src="../images/mainCharge01.png" />
                    </div>
                    <div class="txtAr">
                      <span class="number fW-R">01</span>
                      <span class="conTit fW-R" v-html="$t('trans.t01_11')"></span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="yellowB">
                    <div class="imgAr">
                      <img src="../images/mainCharge02.png" />
                    </div>
                    <div class="txtAr">
                      <span class="number fW-R">02</span>
                      <span class="conTit fW-R" v-html="$t('trans.t01_12')"></span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="yellowB">
                    <div class="imgAr">
                      <img src="../images/mainCharge03.png" />
                    </div>
                    <div class="txtAr">
                      <span class="number fW-R">03</span>
                      <span class="conTit fW-R" v-html="$t('trans.t01_13')"></span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="yellowB">
                    <div class="imgAr">
                      <img src="../images/mainCharge04.png" />
                    </div>
                    <div class="txtAr">
                      <span class="number fW-R">04</span>
                      <span class="conTit fW-R" v-html="$t('trans.t01_14')"></span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div style="clear: both"></div>
            <div class="guideBtn fW-R notranslate">
              <a
                href="https://www.dosirakmobile.com/ChargeStation/ChargeStationAdditional"
                target="blank"
                >{{$t('trans.t01_15')}}</a
              >
            </div>
          </div>
        </section>
        <!-- section prepaid -->
        <section class="section four" id="#scroll3">
          <main-plan></main-plan>
        </section>

        <section class="section five" id="#scroll4">
          <div class="csAr">
            <div class="csCon">
              <div class="left">
                <h1 class='notranslate'>{{ $t('trans.t01_02')}}</h1>
                <span class="txtCall fW-R">1566-9071</span>
                <span class="txtOpen fW-L notranslate"
                  >{{$t('trans.t01_34')}}</span
                >
                <span class="kakaoBtn"
                  ><a
                    class="kakaobizM"
                    @click="
                      kakaotalkConsult({
                        uuid: '@도시락모바일',
                        extra: '도시락모바일',
                      })
                    "
                    ><img src="../images/kakaoBtn.png"/></a
                ></span>
              </div>
              <div class="right">
                <h1 class='notranslate'>{{$t('trans.t01_35')}}</h1>
                <div class="row">
                  <span class="txtCs fW-R notranslate">{{$t('trans.t01_36')}}</span>
                  <a href="https://www.dosirakmobile.com/Help/Faq" target="blank"
                    ><span class="btnClick fW-R">CLICK</span></a
                  >
                </div>
                <div class="row">
                  <span class="txtCs fW-R notranslate">{{$t('trans.t01_37')}}</span>
                  <a href="https://www.dosirakmobile.com/Help/Notice" target="blank"
                    ><span class="btnClick fW-R">CLICK</span></a
                  >
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <!-- 유심 이용 가능 휴대폰보기 팝업 -->
    <div class="popWpper usablePop" v-if="phonePop">
      <div class="popContainer center">
        <button class="close ic" @click="phonePop = false"></button>
        <div class="popWP">
          <div class="popContTit line">
            <p class="tit">유심 이용 가능 휴대폰 보기</p>
            <!-- <p class="txt">와이파이도시락 회원 정보로 로그인 하시려면<br>와이드모바일 통합 회원에 동의 후 로그인 가능합니다.</p> -->
          </div>
          <usim-of-terminal :terminalList="terminal.list"></usim-of-terminal>
        </div>
      </div>
      <div class="dim"></div>
    </div>
    <!-- 개통 가이드 팝업 -->
    <div class="popWpper guidePop" v-if="guidePop">
      <div class="popContainer center">
        <button class="close ic" @click="guidePop = isDim = false">닫기</button>
        <div class="popWP">
          <div class="popContTit line">
            <p class="tit">도시락모바일 개통 가이드</p>
            <!-- <p class="txt">와이파이도시락 회원 정보로 로그인 하시려면<br>와이드모바일 통합 회원에 동의 후 로그인 가능합니다.</p> -->
          </div>
          <div class="popBody">
            <!-- pc -->
            <div class="guideAr">
              <span class="mainTit">개통요청 가이드</span>
              <span class="subTit">신규가입</span>
              <span class="desc"
                >수령하신 유심을 휴대폰에 장착하여 즉시 사용 가능합니다.</span
              >
              <span class="subTit">번호이동</span>
              <span class="desc"
                >유심 수령 후 도시락모바일 홈페이지 또는 고객센터를 통해 직접 개통해
                바로 이용할 수 있습니다.<br />[{{$t('trans.t01_03')}}] > [도시락모바일] > [‘개통신청’
                버튼 클릭] 고객센터 : 1566-9071</span
              >
              <span class="mainTit">유심분리/장착 가이드</span>
              <span class="desc"
                >수령하신 카드에서 유심을 분리하고 휴대폰에 장착해주세요.<br /><b
                  >※유심은 꼭! 개통완료 후 휴대폰에 장착해 주세요.</b
                ></span
              >
              <span class="subTit"
                >1. 휴대폰 유심 슬롯 크기에 맞게 유심을 분리하세요.</span
              >
              <div class="imgBox">
                <img src="../images/pop_guide_01.png" />
              </div>
              <span class="alert">잠깐! 휴대폰 마다 유심 사이즈가 달라요!</span>
              <span class="desc"
                >도시락모바일 유심은 일반, 마이크로, 나노 모두 사용 가능한 3in1
                유심입니다.</span
              >
              <span class="desc"
                >이용하시려는 휴대폰의 유심슬롯 크기를 반드시 확인 하신 후, 크기에
                맞게 분리해서 사용하세요.</span
              >
              <span class="subTit">2. 휴대폰에 유심을 장착하세요.</span>
              <div class="imgBox">
                <span class="boxTit">배터리 일체형 휴대폰</span>
                <img src="../images/pop_guide_02.jpg" />
                <span class="boxTit">휴대폰 측면에 유심 슬롯이 있어요.</span>
                <span class="boxTxt"
                  >유심 분리용 핀을 이용하여 휴대폰 측면의 구멍을 눌러 유심 트레이를
                  꺼내 유심을 장착하세요.</span
                >
                <span class="boxTit">배터리 분리형 휴대폰</span>
                <img src="../images/pop_guide_03.jpg" />
                <span class="boxTit">휴대폰 뒷면에 유심 슬롯이 있어요.</span>
                <span class="boxTxt"
                  >휴대폰 배터리를 분리한 후 슬롯을 확인하고 유심을 삽입하세요.</span
                >
              </div>
              <span class="subTit">3. 전원을 켜고 신호를 확인해주세요.</span>
              <div class="imgBox nopadd">
                <img src="../images/pop_guide_04.jpg" />
              </div>
              <span class="desc mT20"
                >유심이 정상 인식된 경우 통신사가 “LG U+”로 표시됩니다.</span
              >
              <span class="desc"
                >신호를 잡지 못할 경우, 휴대폰 전원을 2~3회 반복해서 껐다 켰다
                해주세요.</span
              >
              <span class="desc"
                >“USIM카드 장착을 확인해주세요” 또는 “USIM카드를 인식할 수 없습니다.
                고객센터에 문의해주세요” 등의 메시지가 나타날 경우 고객센터로
                연락주세요.</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="dim"></div>
    </div>
    <!-- nav floating -->
    <div id="gnbFloat">
      <!--<ul class="gnb">
        <li :class="{'on': selectedMenu === '#scroll1'}">
          <a style="cursor:pointer;" @click="scrollMove('#scroll1')"
            >신청부터 개통까지</a
          >
        </li>
        <li :class="{'on': selectedMenu === '#scroll2'}">
          <a style="cursor:pointer;" @click="scrollMove('#scroll2')"
            >연장/충전방법</a
          >
        </li>
        <li class="fW-R">
          <a style="cursor:pointer;" @click="onNext">선불유심 신청</a>
        </li>
        <li :class="{'on': selectedMenu === '#scroll3'}">
          <a style="cursor:pointer;" @click="scrollMove('#scroll3')"
            >요금 및 부가서비스</a
          >
        </li>
        <li :class="{'on': selectedMenu === '#scroll4'}">
          <a style="cursor:pointer;" @click="scrollMove('#scroll4')">고객만족센터</a>
        </li>
      </ul>-->
      <!-- gnb 수정 210427 -->
      <ul class="btnSection notranslate">
        <li><a class="blue"  @click="onNext">{{$t('trans.t01_08')}}</a></li>
        <li><a class="yellow" @click="$router.push({name:'PaymentSystemView'})">{{$t('trans.t01_09')}}</a></li>
      </ul>
      <!-- gnb 수정 210427 end -->
      <button class="btnApplication" @click="onNext">{{$t('')}} 바로가기</button>
      <button class="toTop" type="button" @click="scrollMove('#scroll0')">
        <img src="../images/goTopBtn.png">
        </button>
    </div>

    <!-- 팝업 -->
    <div class="popWpper paymentSystemPop" v-show="isDetailPop">
      <div class="popContainer center" style="max-width:485px; height:auto;">
        <!-- <button class="close ic" @click="onCloseDetailPop">
          닫기
        </button> -->
        <div class="popWP">
          <!-- <div class="popContTit line">
            <p class="tit notranslate">
              {{ $t("trans.t03_01") }}
            </p>
          </div> -->
          <div class="popBody">
            <div class="price_content">
              <img style="width:100%;"
                src="https://d01.widemobile.com/dosirakmobile/images/ChuseokPopup02.png"
                alt="rest"
              />
            </div>
            <button class="selectBtn" style="margin:10px 0 10px;" @click="onOk">
              확인
            </button>
          </div>
        </div>
      </div>
      <!-- <div class="dim"></div> -->
    </div>    

    <form
      id="form-biz"
      action="https://bizmessage.kakao.com/chat/open"
      method="post"
      target="KAKAO_TALK"
      accept-charset="utf-8"
    >
      <input type="hidden" id="uuid" name="uuid" value="@bzc_tck034" />
      <input type="hidden" id="extra" name="extra" value="bzc_tck034" />
    </form>
  </div>
</template>

<script>
import * as util from "../js/util";
import * as Enum from "../js/enum";
import $ from "jquery";
import { product } from "../api/index";
import JwPagination from "jw-vue-pagination";
import MainTopSlide from "../components/MainTopSlide.vue";
import MainPlan from "../components/MainPlan.vue";
import UsimOfTerminal from "../components/UsimOfTerminal.vue";
import { orderMixin } from "../mixin/storeMixin";
import { fetchTempData } from "../api/order";

export default {
  mixins: [orderMixin],
  name: "Home",
  components: {
    MainTopSlide,
    MainPlan,
    JwPagination,
    UsimOfTerminal,
  },
  data() {
    return {
      isDetailPop: false,
      extra: [],
      plan: [],
      planBest: [],
      planLarge: [],
      planStall: [],
      phonePop: false,
      guidePop: false,
      total: 0,
      count: 10,
      current: 1,
      terminal: [],
      terminalList: [],
      terminalOfItem: [],
      selectedMenu:"#scroll1",
    };
  },
  destroyed() {
    window.removeEventListener("scroll", util.footFloatBanerHome);
  },
  methods: {
    onOk(){
      this.isDetailPop = false;
    },
        
    onChangeTerminalPage(payload) {
      this.terminalOfItem = payload;
    },

    login() {
      this.$router.push({ name: "LoginView" });
    },

    application() {
      this.$router.push({ name: "ApplicationListView" });
    },

    home() {
      this.$router.push({ name: "Home" });
    },

    async main() {
      const [extra, plan, term] = await product.fetchMain({
        page_no: this.current,
        list_count: this.count,
      });

      this.extra = extra.data.ResponseData;
      this.terminal = term.data.ResponseData;
      this.terminalList = term.data.ResponseData.list;
      this.total = this.terminal.common.totalCount;
    },

    scrollMove(id) {
      this.selectedMenu = id
      util.scrollMove(id);
    },

    order() {
      this.$router.push({ name: "About", params: { rate: this.extra } });
    },

    onChargeNo(payload) {
      this.current = payload;
    },

    async onNext() {
      if (
        this.memberInfo.member_login_type === Enum.loginType.EMAIL ||
        this.memberInfo.member_login_type === Enum.loginType.SNS
      ) {
          this.continueOrder(this.memberInfo.member_seq);
      } else if(this.memberInfo.member_login_type === Enum.loginType.MOBILE) {
          this.continueOrder(this.memberInfo.member_mvno.celnumber);
      } else {
          this.$router.push({ name: "Step1View" });
      }
    },

    async continueOrder(payload) {
      const saveMsg = await this.kakaoTrans(
        "작성 중인 내용이 존재합니다. 계속 작성하시겠습니까?"
      );

      const [data, image] = await fetchTempData({
        key: payload,
      });

      sessionStorage.removeItem('imageCard')
      sessionStorage.removeItem('imageFace')

      if (data.data.ResultCode) {
        if (confirm(saveMsg)) {
          try {
            let step = "";

            if (data.data.ResultCode) {
              step = JSON.parse(
                this.aesDecrypt(data.data.ResponseData.data, payload)
              );
            }

            if (image.data.ResultCode) {
              sessionStorage.setItem(
                "imageCard",
                this.aesDecrypt(image.data.ResponseData.card, payload)
              );
              sessionStorage.setItem(
                "imageFace",
                this.aesDecrypt(image.data.ResponseData.face, payload)
              );
            }

            if (step) {
              this.MTS_SET_ORDER(step);
              this.$router.push(step.currentPage);
              return;
            }

            this.$router.push({ name: "Step1View" });
          } catch (error) {
            console.log(error);
          }
        } else {
          this.$router.push({ name: "Step1View" });
        }
      } else{
          this.$router.push({ name: "Step1View" });
      }
    },

    kakaotalkConsult(payload) {
      document.querySelector("#uuid").value = payload.uuid;
      document.querySelector("#extra").value = payload.extra;

      window.open("", "KAKAO_TALK", "width=1024,height=700");
      document.getElementById("form-biz").target = "KAKAO_TALK";
      document.getElementById("form-biz").submit();
    },
  },
  created() {
    this.main();
  },
  mounted() {
    window.addEventListener("scroll", util.footFloatBanerHome);

    $(".mainPriceAr .swiper-slide .planTb .fav-btn").click(function() {
      $(this).toggleClass("active");
    });

    if(this.$route.query.scroll){
      util.scrollMove(this.$route.query.scroll)
    }
  },
  computed: {
    totalcount() {
      return this.total / this.count;
    },

    browserNameStyle() {
      if(this.browserName.includes('WebView')){
        return {
          height: '100% !important'
        }
      } else {
        return {
          height: '100vh !important'
        }
                
      }
    }
  },
};
</script>
<style scoped>
.csAr .csCon > div .kakaoBtn {
  display: block;
  position: absolute;
  top: 100px;
  right: 20px !important;
}

    .popContTit + .popBody {
        padding: 30px 40px;
        height: 800px;
        overflow-y: scroll;
    }
.popBody {
  height:100% !important
}

</style>
