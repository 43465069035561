import axios from 'axios'


import {loadProgressBar} from 'axios-progress-bar'
import '../css/nprogress.css'

const dosirak = axios.create({
    ///baseURL: 'http://192.168.0.173:7777',
    baseURL: 'https://api.wifidosirak.com'
});

const dosirakCall = axios.create({
    //baseURL: 'http://192.168.0.173:7777',
    baseURL: 'https://api.wifidosirak.com',
});

const payCall = axios.create({
    //baseURL: 'http://192.168.0.173:8888',
    baseURL: 'https://pay.wifidosirak.com',
});

const global = axios.create({
    //baseURL: 'http://192.168.0.173:9999'sd
    baseURL: 'https://openapi.wifidosirak.com'
});

const pay = axios.create({
    //baseURL: 'http://192.168.0.173:8888',
    baseURL: 'https://pay.wifidosirak.com',
});

const kakao = axios.create({baseURL: 'https://kapi.kakao.com/v2/user/me'});

const cloud = axios.create({});

const kakaoTrans = axios.create({ // baseURL: 'https://dapi.kakao.com/v2/translation/translate',
    headers: {
        'Authorization': 'KakaoAK 8c588e536f974f84e73424537f636452',
        'Content-type': 'application/x-www-form-urlencoded'
    }
})

export function axiosAll(payload) {
    if (Array.isArray(payload)) {
        return axios.all(payload)
    }
}

loadProgressBar(undefined, dosirak);
loadProgressBar(undefined, global);
loadProgressBar(undefined, kakao);
loadProgressBar(undefined, cloud);
loadProgressBar(undefined, pay);

dosirak.interceptors.request.use(function (config) { // 요청 전에 로딩 오버레이 띄우기
    return config;
}, function (error) { // 에라 나면 로딩 끄기
    return Promise.reject(error);
});

// 응답 인터셉터
dosirak.interceptors.response.use(function (response) { // 응답 받으면 로딩 끄기
    return response;
}, function (error) { // 응답 에러 시에도 로딩 끄기
    return Promise.reject(error);
});

export {
    dosirak,
    global,
    pay,
    kakao,
    cloud,
    kakaoTrans,
    dosirakCall,
    payCall
}
