<template>
  <div class="wrap subPage">
    <div class="container">
      <!-- sub title -->
      <div class="subTitAr fW-R" v-html="$t('trans.t06_01')">
      </div>
      <!-- 예상금액 플로팅 -->
      <top-rate-banner></top-rate-banner>
      <!-- 가입신청서 STEP -->
      <div class="subStepWrap">
        <ul class="stepAr">
          <li>
            <span class="stepNum fW-L notranslate">{{$t('trans.t06_03')}}</span>
          </li>
          <li>
            <span class="stepNum fW-L on notranslate">{{$t('trans.t07_01')}}</span>
          </li>
          <li>
            <span class="stepNum fW-L notranslate">{{$t('trans.t08_01')}}</span>
          </li>
        </ul>
      </div>
      <div style="clear: both;"></div>

      <!-- 서브페이지 컨텐츠 -->
      <div class="subPageAr">
        <form @submit.prevent="onJoin" data-vv-scope="frmJoin">
          <!-- 번호이동정보 -->
          <div class="prod_option" v-if="joinType === 2">
            <div class="prod_subject">
              <span class="subjectTit fW-R notranslate">{{$t('trans.t07_07')}}</span>
              <span class="subjectLine"></span>
            </div>
            <div class="prod_content document">
              <div class="inputAr">
                <div class="inpGroup">
                  <select
                    v-model="beforeAgency"
                    data-vv-name="beforeAgency"
                    v-validate="'required'"
                  >
                    <option value=""><span class='notranslate'>{{$t('trans.t07_08')}}</span></option>
                    <option value="1"><span class='notranslate'>SKT</span></option>
                    <option value="2"><span class='notranslate'>KT</span></option>
                    <option value="3"><span class='notranslate'>LG U+</span></option>
                    <option value="4"><span class='notranslate'>{{$t('trans.t07_12')}}</span></option>
                  </select>
                  <div class="grouping" v-if="beforeAgency === '4'">
                    <select
                      v-model="beforeMvnoAgency"
                      v-validate="'required'"
                      data-vv-name="beforeMvnoAgency"
                      key="beforeMvnoAgency"
                    >
                      <option value=""><span class='notranslate'>{{$t('trans.t07_22')}}</span></option>
                      <option
                        v-for="(item, index) in mvnoPatterns"
                        :key="index"
                        :value="item.key"
                        >{{ item.value }}</option
                      >
                    </select>
                  </div>
                </div>
              </div>
              <div class="inputAr">
                <div class="inpGroup">
                  <input
                    type="text"
                    class="idNum"
                    :placeholder="$t('trans.t07_13')"
                    v-model="beforeCell"
                    data-vv-name="beforeCell"
                    v-validate="'required|numeric'"
                    :maxlength="15"
                  />
                </div>
              </div>
              <div class="inputAr">
                <div class="inpGroup">
                  <select
                    v-model="accAuthType"
                    data-vv-name="accAuthType"
                    v-validate="'required'"
                  >
                    <option value=""><span class='notranslate'>{{$t('trans.t07_14')}}</span></option>
                    <!-- <option value="1">휴대폰 일련번호</option> -->
                    <option value="2"><span class='notranslate'>{{$t('trans.t07_15')}}</span></option>
                    <option value="3"><span class='notranslate'>{{$t('trans.t07_16')}}</span></option>
                    <option value="4"><span class='notranslate'>{{$t('trans.t07_17')}}</span></option>
                  </select>
                  <div class="grouping selectInput">
                    <input
                      type="text"
                      class="idNum"
                      :placeholder="$t('trans.t07_18')"
                      v-model="acc4Num"
                      data-vv-name="acc4Num"
                      v-validate="'required'"
                      :maxlength="4"
                      v-if="accAuthType !== '4'"
                    />
                  </div>
                </div>
              </div>
              <div class="inputAr">
                              <span class="inputNotice notranslate"
                >{{$t('trans.t07_19')}}</span
              >
                <div class="inpGroup">
                  <select disabled="disabled"
                  >
                    <option value="1"><span class='notranslate'>{{$t('trans.t07_20')}}</span></option>
                  </select>
                  <div class="grouping selectInput">
                    <input
                      type="text"
                      class="idNum"
                      :placeholder="$t('trans.t07_18')"
                      v-model="accCell4Num"
                      data-vv-name="accCell4Num"
                      v-validate="'required|length:4'"
                      :maxlength="4"
                    />
                  </div>
                  <br>
                  <div class='notranslate' v-html="$t('trans.t07_21')">

                  </div>
                </div>
              </div>              


              <span class="inputTit fW-R notranslate">{{$t('trans.t07_23')}}</span>
              <div class="inputAr">
                <div class="inpGroup">
                  <select
                    v-model="beforePattern"
                    v-validate="'required'"
                    data-vv-name="beforePattern"
                    key="beforePattern"
                  >
                    <option value="" selected="">{{$t('trans.t07_24')}}</option>
                    <option value="1"><span class="notranslate">{{$t('trans.t07_25')}}</span></option>
                    <option value="2"><span class="notranslate">{{$t('trans.t07_26')}}</span></option>
                    <option value="3"><span class="notranslate">{{$t('trans.t07_27')}}</span></option>
                    <option value="4"><span class="notranslate">{{$t('trans.t07_28')}}</span></option>
                    <option value="5"><span class="notranslate">{{$t('trans.t07_29')}}</span></option>
                  </select>

                  <div class="grouping selectInput">
                    <input
                      type="text"
                      class="idNum"
                      :placeholder="$t('trans.t07_30')"
                      v-validate="'required'"
                      data-vv-name="modeName"
                      key="modeName"
                      @input="inputModelName"
                      :maxlength="30"
                      :value="modeName"
                    />
                  </div>
                </div>
              </div>

              <div class="notranslate" v-html="$t('trans.t07_31')">

              </div>
            </div>
          </div>

          <!-- 신규가입정보-->
          <div class="prod_option" v-if="joinType === 1">
            <div class="prod_subject">
              <span class="subjectTit fW-R notranslate">{{$t('trans.t07_02')}}</span>
              <span class="subjectLine"></span>
            </div>
            <div class="prod_content document">
              <span class="inputTit fW-L notranslate">{{$t('trans.t07_03')}}</span>
              <div class="inputAr">
                <div class="inpGroup">
                  <input
                    type="text"
                    class="name"
                    :placeholder="$t('trans.t07_04')"
                    :maxlength="maxNo1"
                    v-model="firstNumber"
                    v-validate="'required|numeric|length:4'"
                    data-vv-name="firstNumber"
                    key="firstNumber"
                  />
                  <div class="grouping nextLine">
                    <input
                      type="text"
                      class="name"
                      :placeholder="$t('trans.t07_05')"
                      :maxlength="maxNo1"
                      v-model="secondNumber"
                      v-validate="'required|numeric|length:4'"
                      data-vv-name="secondNumber"
                      key="secondNumber"
                    />
                  </div>
                  <span class="inputNotice notranslate"
                    >{{$t('trans.t07_06')}}</span
                  >
                </div>
              </div>
              <span class="inputTit fW-L notranslate">{{$t('trans.t07_23')}}</span>
              <div class="inputAr">
                <div class="inpGroup">
                  <select
                    v-model="beforePattern"
                    v-validate="'required'"
                    data-vv-name="beforePattern"
                    key="beforePattern"
                  >
                    <option value="" selected=""><span class='notranslate'>{{$t('trans.t07_24')}}</span></option>
                    <option value="1"><span class="notranslate">{{$t('trans.t07_25')}}</span></option>
                    <option value="2"><span class="notranslate">{{$t('trans.t07_26')}}</span></option>
                    <option value="3"><span class="notranslate">{{$t('trans.t07_27')}}</span></option>
                    <option value="4"><span class="notranslate">{{$t('trans.t07_28')}}</span></option>
                    <option value="5"><span class="notranslate">{{$t('trans.t07_29')}}</span></option>
                  </select>
                  <!-- <select
                      v-model="company"
                      v-validate="'required'"
                      data-vv-name="company"
                      key="company"
                    >
                      <option value="" selected="">제조사</option>
                      <option value="APPLE">APPLE</option>
                      <option value="SAMSUNG">SAMSUNG</option>
                      <option value="LG">LG</option>
                      <option value="XIAOMI">XIAOMI</option>
                      <option value="HUAWEI">HUAWEI</option>
                      <option value="OPPO">OPPO</option>
                      <option value="VIVO">VIVO</option>
                      <option value="LENOVO">LENOVO</option>
                      <option value="REALME">REALME</option>
                      <option value="TECNO">TECNO</option>
                      <option value="OTHERS">OTHERS</option>
                    </select> -->
                  <div class="grouping selectInput">
                    <input
                      type="text"
                      class="idNum"
                      :placeholder="$t('trans.t07_30')"
                      v-validate="'required'"
                      data-vv-name="modeName"
                      key="modeName"
                      @input="inputModelName"
                      :value="modeName"
                      :maxlength="30"
                    />
                  </div>
                </div>

                <div class='notranslate' v-html="$t('trans.t07_31')">

                </div>
              </div>
            </div>
          </div>

          <!-- 가입자정보 -->
          <div class="prod_option">
            <div class="prod_subject">
              <span class="subjectTit fW-L notranslate>">{{$t('trans.t07_32')}}</span>
              <span class="subjectLine"></span>
            </div>
            <div class="prod_content document">
              <div style="clear: both"></div>
              <div class="inputAr">
                <div class="inpGroup">
                  <input
                    type="text"
                    class="name"
                    :placeholder="$t('trans.t12_04')"
                    v-model="accName"
                    v-validate="'required'"
                    data-vv-name="accName"
                    key="accName"
                    :maxlength="50"
                  />
                  <div class="grouping nextLine">
                    <input
                      type="text"
                      class="idNum"
                      :placeholder="$t('trans.t07_13')"
                      v-validate="'required'"
                      data-vv-name="accCell"
                      key="accCell"
                      v-model="accCell"
                      :maxlength="15"
                    />
                  </div>
                </div>
              </div>
              <div class="inputAr">
                <input
                  type="text"
                  class="inputFull"
                  :placeholder="$t('trans.t07_34')"
                  v-model="accMail"
                  data-vv-name="accMail"
                  key="accMail"
                  v-validate="'required|email'"
                  :maxlength="50"
                />
              </div>
              <div class="notranslate" v-html="$t('trans.t07_35')"></div>
            </div>
          </div>
        </form>
        <!-- 배송정보 -->
        <div class="prod_option">
          <div class="prod_subject">
            <span class="subjectTit notranslate">{{$t('trans.t07_36')}}</span>
            <span class="subjectLine"></span>
          </div>
          <div class="prod_content document">
            <div class="inputAr notranslate">
              <span
                :class="['check-btn', { active: isAccEqualYn }]"
                @click="isEquals"
                >{{$t('trans.t07_37')}}</span
              >
            </div>

            <!-- 배송방법 Tab -->
            <ul class="deliveryTab">
              <li
                v-for="(item, index) in arrDeliveryOption"
                @click="onDelivery(item)"
                :key="index"
                :class="[
                  'deliveryTab-link fW-R',
                  { active: selectedDelivery.delivery_cd === item.delivery_cd },
                ]"
              >
                <span class='notranslate' v-if="item.delivery_cd === 4">{{$t('trans.t07_38')}}</span>
                <span class='notranslate' v-else-if="item.delivery_cd === 6">{{$t('trans.t07_52')}}</span>
                <span v-else>{{ item.delivery }}</span>

              </li>
            </ul>

            <div style="clear: both;"></div>

              <!-- 찾아가는 방문서비스 -->
              <form @submit.prevent="onZeniel" data-vv-scope="frmZeniel">
                <div
                  :class="[
                    'deliveryTab-content',
                    { active: selectedDelivery.delivery_cd === 4 },
                  ]"
                  v-show="selectedDelivery.delivery_cd === 4"
                >
                <div class="slide">
                  <ul class="imgBox">
                    <li>
                      <span class="numCircle">1</span>
                      <img src="../../images/delivery_01.png" />
                      <span class="boxTxt notranslate">{{$t('trans.t07_39')}}</span>
                    </li>
                    <li>
                      <span class="numCircle">2</span>
                      <img src="../../images/delivery_02.png" />
                      <span class="boxTxt notranslate">{{$t('trans.t07_40')}}</span>
                    </li>
                    <li>
                      <span class="numCircle">3</span>
                      <img src="../../images/delivery_03.png" />
                      <span class="boxTxt notranslate">{{$t('trans.t07_41')}}</span>
                    </li>
                    <li>
                      <span class="numCircle">4</span>
                      <img src="../../images/delivery_04.png" />
                      <span class="boxTxt notranslate">{{$t('trans.t07_42')}}</span>
                    </li>
                  </ul>
                </div>
                  <div style="clear: both;"></div>

                  <div class="noteAr notranslate" v-html="$t('trans.t07_43')">
                   
                  </div>

                  <div class="inputAr">
                    <div class="inpGroup">
                      <input
                        type="text"
                        class="name"
                        :placeholder="$t('trans.t07_44')"
                        v-model="reciName"
                        data-vv-name="reciName"
                        v-validate="'required'"
                        key="reciName"
                        :maxlength="15"
                      />
                      <div class="grouping nextLine">
                        <input
                          type="text"
                          class="idNum"
                          :placeholder="$t('trans.t07_13')"
                          v-model="reciCell"
                          data-vv-name="reciCell"
                          key="reciCell"
                          v-validate="'required|numeric'"
                          :maxlength="15"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="inputAr">
                    <input
                      type="text"
                      class="inputFull"
                      :placeholder="$t('trans.t07_34')"
                      v-model="reciMail"
                      data-vv-name="reciMail"
                      key="reciMail"
                      v-validate="'required|email'"
                      :maxlength="50"
                    />
                  </div>

                  <div class="inputAr">
                    <div class="inpGroup">
                      <input
                        type="text"
                        class="code"
                        :placeholder="$t('trans.t07_47')"
                        v-model="reciZipcode"
                        v-validate="'required|numeric'"
                        data-vv-name="reciZipcode"
                        key="reciZipcode"
                        readonly
                      />
                      <div class="grouping selectInput">
                        <button
                          class="inputBtn"
                          type="button"
                          @click="isShowZip = !isShowZip"
                        >
                          <span class="notranslate">{{$t('trans.t07_48')}}</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="inputAr" v-if="isShowZip">
                    <vue-daum-postcode
                      q=""
                      :animation="true"
                      :no-shorthand="true"
                      :no-auto-mapping="false"
                      :please-read-guide="3"
                      :please-read-guide-timer="0"
                      :max-suggest-items="3"
                      :show-more-h-name="true"
                      :hide-map-btn="false"
                      :hide-eng-btn="false"
                      :always-show-eng-addr="true"
                      :zonecode-only="true"
                      :theme="{
                        searchBgColor: '#3a68b2',
                        queryTextColor: '#FFFFFF',
                      }"
                      style="height:480px; width:100%; overflow-y:scroll; border:0px solid #000"
                      @complete="onComplete($event)"
                    />
                  </div>

                  <div class="inputAr">
                    <div class="inpGroup">
                      <input
                        type="text"
                        class="idNum  gray"
                        :placeholder="$t('trans.t07_49')"
                        v-model="reciAddressMaster"
                        data-vv-name="reciAddressMaster"
                        key="reciAddressMaster"
                        v-validate="'required'"
                      />
                      <div class="grouping nextLine">
                        <input
                          type="text"
                          class="name"
                          :placeholder="$t('trans.t07_50')"
                          @input="inputAddressDetail"
                          data-vv-name="reciAddressDetail"
                          key="reciAddressDetail"
                          v-validate="'required'"
                          v-model="reciAddressDetail"
                        />
                      </div>
                    </div>
                    <span class="inputNotice red notranslate">{{$t('trans.t07_51')}}</span>
                  </div>

                  <div class="btnAr notranslate">
                    <button
                      class="btnBefore fW-R"
                      type="button"
                      @click="$router.push({ name: 'Step2View' })"
                    >
                      {{$t('trans.t06_26')}}
                    </button>
                    <button class="btnCertify fW-R" type="submit">
                      {{$t('trans.t06_27')}}
                    </button>
                  </div>
                  <!-- <div class="inputAr">
                                    <span class="check-btn"
                                        >자동개통에 동의합니다.<br />개통요청이 없는
                                        경우, 택배수령(배송완료) 익일 자동
                                        개통됩니다.</span
                                    >
                                </div> -->
                </div>
              </form>


            <!-- 직접수령 -->
            <form @submit.prevent="onDirect" data-vv-scope="frmDirect">
              <div
                :class="[
                  'deliveryTab-content',
                  { active: selectedDelivery.delivery_cd === 6 },
                ]"
                v-show="selectedDelivery.delivery_cd === 6"
              >
                <div class="directMap" v-show="false"></div>
                <div class="noteAr notranslate" v-html="$t('trans.t07_53')">
                  
                </div>
                <div class="btnAr notranslate">
                  <button
                    class="btnBefore"
                    type="button"
                    @click="$router.push({ name: 'Step2View' })"
                  >
                    {{$t('trans.t06_26')}}
                  </button>
                  <button class="btnCertify" type="submit">
                    {{$t('trans.t06_27')}}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- 팝업 -->
    <div class="popWpper paymentSystemPop" v-show="isDetailPop">
      <div class="popContainer center" style="max-width:485px; height:auto;">
        <!-- <button class="close ic" @click="onCloseDetailPop">
          닫기
        </button> -->
        <div class="popWP">
          <!-- <div class="popContTit line">
            <p class="tit notranslate">
              {{ $t("trans.t03_01") }}
            </p>
          </div> -->
          <div class="popBody">
            <div class="price_content">
              <img style="width:100%;"
                src="https://d01.widemobile.com/dosirakmobile/images/ChuseokPopup02.png"
                alt="rest"
              />
            </div>
            <button class="selectBtn" style="margin:10px 0 10px;" @click="onOk">
              확인
            </button>
          </div>
        </div>
      </div>
      <div class="dim"></div>
    </div>    
  </div>
</template>

<script>
import TopRateBanner from "../../components/TopRateBanner";
import { VueDaumPostcode } from "vue-daum-postcode";
import { fetchZipcode } from "../../api/order";
import { orderMixin } from "../../mixin/storeMixin";
import { mvnoPatterns } from "../../api/Repo";
import { fetchShop, fetchDelivery, fetchShopDelivery } from "../../api/common";

export default {
  mixins: [orderMixin],
  data() {
    return {
      isShowZip: false, //우편번호창
      maxNo1: 4,
      maxNo2: 4,
      isZenielPass: false, //배송가능여부
      mvnoPatterns: mvnoPatterns,
      arrDeliveryOption: [],
      arrDelivery: [],
      shop: {},
      isDetailPop: false,
      isConfirm:false,      
    };
  },

  methods: {
    onOk(){
      this.isConfirm = true;
      this.isDetailPop = false;
    },    
    isEquals() {
      if (this.selectedDelivery.delivery_cd === 6) {
        return;
      }

      this.isAccEqualYn = !this.isAccEqualYn;

      if (this.isAccEqualYn) {
        this.reciName = this.accName;
        this.reciCell = this.accCell;
        this.reciMail = this.accMail;

        return;
      }

      this.reciName = "";
      this.reciCell = "";
      this.reciMail = "";
    },

    async onComplete(e) {
      if (e.zonecode !== "") {
        if (!(await this.onZipcodeCheck(e.zonecode))) {
          this.isZenielPass = false;
          this.reciZipcode = "";
          this.reciAddressMaster = "";
          this.reciAddressDetail = "";

          this.kakaoTrans("배송불가 지역입니다.").then((res) => alert(res));
          return;
        }

        this.isZenielPass = true;
        this.reciZipcode = e.zonecode;

        if (e.userSelectedType === "R") {
          this.reciAddressMaster = e.address;
          this.isShowZip = false;
          return;
        }

        this.reciAddressMaster = e.jibunAddress;
        this.isShowZip = false;
      }
    },

    // 제니엘배송가능여부 확인
    async onZipcodeCheck(payload) {
      const res = await fetchZipcode(payload);

      return res.data.ResultCode;
    },

    async onZeniel() {
      try {

        const frmJoinVd = await this.$validator.validateAll("frmJoin");

        if (!frmJoinVd) {
          throw this.errors.items[0].msg;
        }

        const validate = await this.$validator.validateAll("frmZeniel");

        if (!validate) {
          throw this.errors.items[0].msg;
        }

        if (validate) {
          if (!(await this.onZipcodeCheck(this.reciZipcode))) {
            throw "배송불가 지역입니다.";
          }
        }

        this.MTS_STEP3(true);
        this.$router.push({ name: "Step4View" });
      } catch (e) {
        this.kakaoTrans(e).then((res) => alert(res));
      }
    },

    async onDirect() {
      try {
      
        const frmJoinVd = await this.$validator.validateAll("frmJoin");

        if (!frmJoinVd) {
          throw this.errors.items[0].msg;
        }

        this.MTS_STEP3(true);
        this.$router.push({ name: "Step4View" });
      } catch (e) {
        this.kakaoTrans(e).then((res) => alert(res));
      }
    },

    inputAddressDetail(e) {
      this.reciAddressDetail = e.target.value;
      this.MTS_RECI_ADDRESS_FULL(
        `${this.reciAddressMaster} ${this.reciAddressDetail}`
      );
    },

    async fetchShopDelivery() {
      const [shop, delivery] = await fetchShopDelivery({
        shop_cd: 2,
      });

      this.shop = shop.data.ResponseData;
      this.arrDelivery = delivery.data.ResponseData;
      this.arrDeliveryOption = delivery.data.ResponseData.filter((item) => {
        return item.delivery_cd === 4 || item.delivery_cd === 6;
      });
    },

    inputModelName(e) {
      this.modeName = e.target.value;
    },

    onDelivery(payload) {
      this.selectedDelivery = payload;

      if (payload.delivery_cd === 6) {
        this.isAccEqualYn = false;
        this.reciName = "";
        this.reciCell = "";
        this.reciMail = "";
        this.reciZipcode = "";
        this.reciAddressMaster = "";
        this.reciAddressDetail = "";
      }
    },
  },

  computed: {},

  components: {
    TopRateBanner,
    VueDaumPostcode,
  },

  mounted() {
    if (!this.OrderDataSet.isStep2) {
      this.$router.replace("/");
    }

    if (!sessionStorage.getItem("order")) {
      this.$router.replace({ name: "Home" });
    }

    if (this.memberInfo.member_cellnumber !== "") {
      this.accCell = this.memberInfo.member_cellnumber;
    }

    if (this.memberInfo.member_id !== "") {
      this.accMail = this.memberInfo.member_id;
    }

    // 현재페이지 저장
    this.MTS_CURRENT_PAGE(this.$router.currentRoute.path);

    this.fetchShopDelivery();
  },
};
</script>

<style scoped>
/* .subPageAr .prod_option .prod_content .inputAr input.idNum {
  width: 200px;
} */
</style>
