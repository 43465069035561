<template>
  <div>
    <!-- <dosirak-talk-banner></dosirak-talk-banner> -->
    <header>
      <div :class="['headerWrap', { Sub: $route.name !== 'Home' }]">
        <!-- <div class="headerWrap Sub"> -->
        <div class="navWp">
          <h1>
            <router-link to="/">Dosirak Mobile</router-link>
          </h1>

          <div class="nav" v-if="!isMobileOnly && isMainSite">
            <nav>
              <ul class="gnb">
                <li v-if="$route.name !== 'Home'">
                  <router-link
                    :to="{
                      name: 'Home',
                      query: { scroll: '#scroll2' },
                    }"
                    ><span class="notranslate">{{
                      $t("trans.t01_01")
                    }}</span>
                  </router-link>
                </li>
                <li v-else>
                  <a
                    style="cursor:pointer;"
                    @click="scrollMove('#scroll2')"
                    ><span class="notranslate">{{
                      $t("trans.t01_01")
                    }}</span></a
                  >
                </li>

                <li v-if="$route.name !== 'Home'">
                  <router-link
                    :to="{
                      name: 'Home',
                      query: { scroll: '#scroll4' },
                    }"
                    ><span class="notranslate">{{
                      $t("trans.t01_02")
                    }}</span>
                  </router-link>
                </li>

                <li v-else>
                  <a
                    style="cursor:pointer;"
                    @click="scrollMove('#scroll4')"
                    ><span class="notranslate">{{
                      $t("trans.t01_02")
                    }}</span></a
                  >
                </li>
              </ul>
            </nav>
            <div>
              <span
                ><a href="09-2_dm_memberInfo.html">정보수정</a></span
              >
              <span><a href="#">로그아웃</a></span>
            </div>
            <a href="#a" class="close">Close</a>
          </div>

          <ul class="headerRight">
            <li>
              <a @click="toggl = !toggl"><i class="xi-user-o"></i></a>
              <ul class="myList" v-show="toggl">
                <li
                  v-if="!memberInfo.isLogin"
                  @click="toggl = !toggl"
                >
                  <router-link :to="{ name: 'LoginView' }"
                    ><span class="notranslate">{{
                      $t("trans.t01_03")
                    }}</span>
                  </router-link>
                </li>
                <li @click="toggl = !toggl" v-else>
                  <router-link @click.native="LogOut" to="/"
                    >로그아웃
                  </router-link>
                </li>
                <li @click="toggl = !toggl">
                  <router-link :to="{ name: 'MyPageView' }"
                    ><span class="notranslate">{{
                      $t("trans.t01_04")
                    }}</span>
                  </router-link>
                </li>
                <li @click="toggl = !toggl">
                  <router-link :to="{ name: 'ApplicationListView' }"
                    ><span class="notranslate" v-html="$t('trans.t01_05')">{{

                    }}</span></router-link
                  >
                </li>
              </ul>
            </li>
            <li>
              <select id="selectLang" @change="onChangeLang($event)">
                <option data-lang="en" value="/ko/en">영어</option>
                <option data-lang="vi" value="/ko/vi"
                  >베트남어</option
                >
                <option data-lang="ko" value="/ko/ko">한국어</option>
                <option data-lang="cn" value="/ko/zh-CN"
                  >중국어
                </option>
              </select>
            </li>
          </ul>
        </div>
      </div>
      <div class="myList"></div>
    </header>
    <div class="headerDim"></div>
  </div>
</template>

<script>
import { loginType } from "../js/enum";
import { memberMixin, orderMixin } from "../mixin/storeMixin";
import DosirakTalkBanner from "../components/DosirakTalkBanner";
import * as util from "../js/util";

export default {
  name: "Header",
  mixins: [memberMixin, orderMixin],
  data() {
    return {
      toggl: false,
    };
  },

  components: {
    DosirakTalkBanner,
  },

  methods: {
    scrollMove(id) {
      this.selectedMenu = id;
      util.scrollMove(id);
    },

    LogOut() {
      this.MTS_SET_LOGOUT({
        member_login_type: loginType.EMAIL,
        member_seq: "",
        member_cellnumber: "",
        member_name: "",
        sns: "",
        isLogin: false,
      });

      // if (Kakao.Auth.getAccessToken()) {
      //     Kakao.Auth.logout(function() {
      //         Kakao.Auth.getAccessToken()
      //     })
      // }

      localStorage.removeItem("user_session");

      this.MTS_ORDER_INIT();

      this.$router.replace({ name: "Home" }).catch(() => {});

      window.location.reload(true);
    },

    onChangeLang(event) {
      //util.removeCookie("googtrans");
      this.$cookies
        .keys()
        .forEach((cookie) => this.$cookies.remove(cookie));

      const lang = event.target.options[
        event.target.selectedIndex
      ].getAttribute("data-lang");
      this.$i18n.locale = lang;

      sessionStorage.setItem("googtrans", event.target.value);

      if (location.hostname.includes("dosirakmobile.com")) {
        document.cookie = `googtrans=${event.target.value}; domain=.dosirakmobile.com;`;
        window.location.reload(true);
      }

      document.cookie = decodeURIComponent(`googtrans=${event.target.value};`);
      this.googtrans = event.target.value;
      window.location.reload(true);
    },
  },

  mounted() {
    if (sessionStorage.getItem("googtrans") === null) {
      document.cookie = decodeURIComponent(`googtrans=/ko/ko`)
      sessionStorage.setItem("googtrans", "/ko/ko");
    }
    
    this.$cookies
      .keys()
      .forEach((cookie) => this.$cookies.remove(cookie));

    document.querySelector("#selectLang").value = decodeURIComponent(
      sessionStorage.getItem("googtrans")
    );

    document.cookie = decodeURIComponent(`googtrans=${sessionStorage.getItem("googtrans")}`)

    switch (decodeURIComponent(sessionStorage.getItem("googtrans"))) {
      case "/ko/ko":
        this.$i18n.locale = "ko";
        break;
      case "/ko/en":
        this.$i18n.locale = "en";
        break;
      case "/ko/vi":
        this.$i18n.locale = "vi";
        break;
      case "/ko/zh-CN":
        this.$i18n.locale = "cn";
        break;
      default:
        break;
    }

    if (this.$route.query.q) {
      this.$router.replace({ query: {} });
      const event = new Event("change");
      document.querySelector("#selectLang").dispatchEvent(event);
    }
  },
};
</script>

<style scoped>
.mainVisualAr {
  width: 100%;
  margin: auto;
  position: relative;
  height: 60px;
}
</style>
