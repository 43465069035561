export const loginType = Object.freeze({EMAIL: 1, MOBILE: 2, SNS: 3});

export const pay = {

    POST_PAYMENT: 1, // 후결제
    PRE_PAYMENT: 2 // 선결제
}

export const payModule = {
    CREDIT: 1, // 신용카드
    AUTOMATIC: 2, // 자동이체
    KAKAO: 3, // 카카오
    VISUAL_ACCOUNT: 4, // 가상계좌
    STORE: 5, // 편의점결제
    SPOT:6, //현장결제
    POST: 7, // 후정산
    CASH:8, //현금
    ETC:9, //기타
    MANUAL:10, //수기결제KICC
    ACCOUNT_DEPOSIT:11 //계좌입금
}

export const pgModule = {
    HY_CREDIT:1,//효성신용카드
    HY_ACCOUNT:2, //효성계좌이체
    KAKAO:3, //카카오결제
    HY_VIRTUAL_ACCOUNT:4,//효성 가상계좌
    STORE:5, //편의점결제
    ON_SITE:6,//현장결제
    POST:7,//후정산
    BILLKEY_POS:8,//빌키포드
    ACCOUNT_DEPOSIT:10//계좌입금
}


export const openStatus = {
    UN_OPENED: 1,
    OPENING: 2,
    PENDING_OPENING: 3,
    OPENING_REQUEST: 4
}

export const identity = {
    REGIST_CARD: 1, // 주민등록증
    DRIVER_CARD: 2, // 운전면허증
    PASSPORT_CARD: 3, // 여권번호
    ALIEN_REGIST_CARD: 4 // 외국인등록증

}

export const sns = {
    keys: {
        kakao: {},
        naver: {},
        facebook: {}
    }
}

export const snsKeys = {
    kakao: {
        key: '2bcd2636d2020bdfc45e2063511b94b9',
        callback: ""
    },
    naver: {
        key: 'FbdILaydqf09OfnRERMB',
        callback: 'http://192.168.0.173:8080/sns/callback/naver'
    }
}

export const snsType = {
    kakao: 'K',
    naver: 'N',
    facebook: 'F'
}

// 국내, 해외카드
export const CARD_TYPE = {
    domestic: "1",
    overseas: "2"
}

// 신규,번호이동
export const JOIN_TYPE = {
    NEW: 1,
    TRANSFER: 2
}

// POST URL
export const URL = {
    PAY: "http://pay.wifidosirak.com",
    PAYS: "https://pay.wifidosirak.com",
    PAY_TEST: "http://192.168.0.173:8888"
}

// 결제응답
export const PAY_RES = {
    SUCCESS: "0000"
}

// 취소/반품
export const CANCEL_TYPE = {
    CANCEL:1,
    REFUND:2
}