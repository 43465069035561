<template>
  <div>
    <div id="pdf" ref="document" v-show="false">
      <div class="applicationPdfForm">
        <div class="formTopWrap">
          <div class="formLogo">
            <!-- <img src="../../images/formLogo.png" /> -->
          </div>
          <div class="formTit">
            <span class='notranslate'>{{$t('trans.t09_13')}}</span>
          </div>
          <!-- 가입자 정보 -->
          <div class="formConWrap">
            <span class="formConTit notranslate">
              {{$t('trans.t07_32')}}
            </span>
            <div class="formAr">
              <div class="formGroup">
                <div class="infoAr halfAr">
                  <span class="infoTit notranslate">{{$t('trans.t14_05')}}</span>
                  <span class="infoTxt">{{ account.clientname }}</span>
                </div>
                <div class="infoAr halfAr">
                  <span class="infoTit notranslate">{{$t('trans.t08_17')}}</span>
                  <span class="infoTxt">{{
                    account.birth
                  }}</span>
                </div>
                <div style="clear: both;"></div>
              </div>
            </div>
            <div class="formAr">
              <div class="infoAr">
                <span class="infoTit">가입자 주소</span>
                <span class="infoTxt"
                  >{{`${account.zipcode}`}} {{ account.address }}</span
                >
              </div>
            </div>
            <div class="formAr">
              <div class="infoAr">
                <span class="infoTit">연락처</span>
                <span class="infoTxt">{{ account.celnumber }}</span>
              </div>
            </div>
            <div class="formAr">
              <div class="infoAr">
                <span class="infoTit notranslate">{{$t('trans.t07_34')}}</span>
                <span class="infoTxt">{{ account.clientmail }}</span>
              </div>
            </div>
            <div class="formAr">
              <div class="infoAr">
                <span class="infoTit">자동결제</span>
                <span class="infoTxt">{{ account.autopay_yn }}</span>
              </div>
            </div>
            <div class="formAr" v-if="account.pgtype_cd === 4">
              <div class="infoAr">
                <span class="infoTit">은행/계좌번호</span>
                <span class="infoTxt">{{
                  `${account.bankname}/${account.accountnumber}`
                }}</span>
              </div>
            </div>
          </div>
          <!-- 가입내역 -->
          <div class="formConWrap">
            <span class="formConTit">
              가입내역
            </span>
            <div class="formAr">
              <div class="formGroup">
                <div class="infoAr">
                  <span class="infoTit">가입번호</span>
                  <span class="infoTxt">{{ account.opencelnumber }}</span>
                </div>
                <div class="infoAr">
                  <span class="infoTit">요금제명</span>
                  <span class="infoTxt">{{ account.name }}</span>
                </div>
                <div style="clear: both;"></div>
              </div>
            </div>
            <div class="formAr">
              <div class="infoAr">
                <span class="infoTit notranslate">{{$t('trans.t09_06')}}</span>
                <span class="infoTxt">{{ account.extra_service }}</span>
              </div>
            </div>
          </div>

            <div class="formConWrap">
              <div style="page-break-after:always">
            </div>
          </div>
          <!-- 신규가입고객 -->

          <div class="formConWrap" v-show="account.subtype_cd === 1">
            <span class="formConTit">
              신규가입고객
            </span>
            <div class="formAr">
              <div class="infoAr">
                <span class="infoTit">희망번호</span>
                <span class="infoTxt"
                  >{{ account.new_number1 }},{{ account.new_number2 }}</span
                >
              </div>
            </div>
          </div>

          <!-- 번호이동고객 -->
          <div class="formConWrap" v-show="account.subtype_cd === 2">
            <span class="formConTit">
              번호이동고객
            </span>
            <div class="formAr">
              <div class="formGroup">
                <div class="infoAr halfAr">
                  <span class="infoTit">이동할 전화번호</span>
                  <span class="infoTxt">{{ account.previous_celnuber }}</span>
                </div>
                <div class="infoAr halfAr">
                  <span class="infoTit">변경 전 사업자</span>
                  <span class="infoTxt">{{ account.agency }}</span>
                </div>
                <div style="clear: both;"></div>
              </div>
            </div>
            <div class="formAr">
              <div class="formGroup">
                <div class="infoAr halfAr">
                  <span class="infoTit">번호이동 인증</span>
                  <span class="infoTxt">{{ account.accAuthNum }}</span>
                </div>
                <div class="infoAr halfAr">
                  <span class="infoTit notranslate">{{$t('trans.t07_14')}}</span>
                  <span class="infoTxt">{{ account.accAuthName }}</span>
                </div>
                <div style="clear: both;"></div>
              </div>
            </div>
          </div>
          
          <div class="formConWrap">
            <div class="formAr">
              <div class="formGroup">
                <div class="infoAr halfAr" style="width:100%">
                  <span class="infoTxt">본인은(주)와이드모바일의 개인정보처리방침,LTE/CDMA 서비스 이용약관, 위치기반서비스 이용약관, 휴대폰 본인 확인 서비스 이용약관, 발신번호 변작 방지 이용약관에 동의하며, 외국인(여권)가입의 경우 당일 입국기록 조회 불가에 따른 개통 제한시 자사의 법인 명의로 임시 개통 후 익일 입국기록 확인 시 본인 명의 변경 진행에 동의 합니다.<br>본인은 가입신청서 내용을 충분히 이해했으며, 위와 같은 내용으로 계약을 체결합니다.</span>
                </div>
              </div>
            </div>            
          </div>

          <div class="formConWrap">
            <div class="formAr">
              <div class="formGroup" style="background-color:#eeee">
                <div class="infoAr halfAr">
                  <span class="infoTit">가입 신청일</span>
                  <span class="infoTxt">{{ account.regdate }}</span>
                </div>
                <div class="infoAr halfAr">
                  <span class="infoTit">신청인</span>
                  <span class="infoTxt">{{ account.clientname }}</span>
                </div>
                <div style="clear: both;"></div>
              </div>
            </div>          
          </div>          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchAccountForm } from "../api/member";
import { eventBus } from "../js/eventBus";

function addScript(url) {
  var script = document.createElement("script");
  script.type = "application/javascript";
  script.src = url;
  document.head.appendChild(script);
}
addScript(
  "https://raw.githack.com/eKoopmans/html2pdf/master/dist/html2pdf.bundle.js"
);

export default {
  data() {
    return {
      account: {}
    };
  },

  props: {
    order_id: String,
  },

  created() {
    eventBus.$on("downApplication", () => {
      this.fetchAccount();
      this.exportToPDF();
    });
  },

  methods: {
    async fetchAccount() {
      const res = await fetchAccountForm({
        order_id: decodeURIComponent(this.order_id),
      });

      this.account = res.data.ResponseData;
    },

    exportToPDF() {
      const pdfHtml = this.$refs.document;
      const node = pdfHtml.firstChild.childNodes[0];
      node.style.width = "780px";
      node.style.padding = "0";
      node.style.border = "0";

      var opt = {
        margin: [-10, -21, 0, 0],
        filename: "가입신청서.pdf",
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          scale: 4,
          dpi: 192,
          letterRendering: true,
        },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        },
      };

      html2pdf()
        .set(opt)
        .from(pdfHtml.innerHTML)
        .save();

      //pdfHtml.innerHTML = "";
    },
  },

  mounted() {
    this.fetchAccount();
  },
};
</script>

<style scoped>
.pdf {
  width: 760px;
}
.applicationForm {
  padding: 90px;
}

/* @import "./fonts.css";
@charset "utf-8"; */

.applicationPdfForm {
  display: block;
  width: 780px;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  padding: 50px;
}
.applicationPdfForm .formTopWrap {
  display: block;
  width: 850px;
  padding: 20px;
  margin: auto;
  border: 1px solid #c2c2c2;
}
.applicationPdfForm .formTopWrap .formTit {
  display: block;
  text-align: center;
  font-size: 30px;
  color: #333;
  font-weight: bold;
  padding: 20px 0;
}
.applicationPdfForm .formTopWrap .formConWrap {
  display: block;
  padding: 20px 50px;
}
.applicationPdfForm .formTopWrap .formConWrap .formConTit {
  display: block;
  text-align: left;
  font-size: 24px;
  color: #333;
  font-weight: bold;
  border-bottom: 3px solid #333;
  padding: 0 10px 15px;
}
.applicationPdfForm .formTopWrap .formConWrap .formAr {
  display: block;
}
.applicationPdfForm .formTopWrap .formConWrap .formAr .formGroup {
  display: block;
}
.applicationPdfForm .formTopWrap .formConWrap .formAr .formGroup .formGrouping {
  display: inline-block;
  margin-left: 25px;
  vertical-align: middle;
}
.applicationPdfForm .formTopWrap .formConWrap .formAr .infoAr {
  display: block;
  text-align: left;
  padding: 20px 10px;
  border-bottom: 1px solid #c2c2c2;
  box-sizing: border-box;
}
.applicationPdfForm .formTopWrap .formConWrap .formAr .infoAr.halfAr {
  width: 48%;
  display: inline-block;
}
.applicationPdfForm
  .formTopWrap
  .formConWrap
  .formAr
  .infoAr.halfAr:nth-of-type(n + 2) {
  margin-left: 4%;
  float: right;
}
.applicationPdfForm .formTopWrap .formConWrap .formAr .infoAr .infoTit {
  display: inline-block;
  width: 150px;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  vertical-align: middle;
}
.applicationPdfForm .formTopWrap .formConWrap .formAr .infoAr .infoTxt {
  text-align: left;
  font-size: 16px;
  color: #646464;
  vertical-align: middle;
}
</style>
