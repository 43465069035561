<template>
  <div id="app">
    <div
      :class="['wrap mainPage']"
      :style="isDim ? 'overflow: hidden; position: fixed;' : ''"
    >
      <div class="content container">
        <main-header></main-header>
        <transition :name="transitionName" mode="out-in">
          <router-view></router-view>
        </transition>
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>
<script src="./js/common.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/prefixfree/1.0.7/prefixfree.min.js"></script>
<script>
const DEFAULT_TRANSITION = "fade";

import MainHeader from "./components/MainHeader.vue";
import Footer from "./components/Footer.vue";
import { request } from "./api/common";
import { orderMixin } from "./mixin/storeMixin";

export default {
  metaInfo: {
    title: "도시락모바일, 조건없이 누구나 가입",
    titleTemplate: "%s | prepaid.dosirakmobile.com", // title 뒤에 붙일 공통된 이름 (주로 사이트이름)
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "인증없는 온라인 가입으로 누구나 조건없이 가입. 찾아가는 개통서비스로 본인 명의 바로 개통. 약정없는 선불유심 요금제 판매, 해외무료통화 제공",
        vmid: "description",
      },
    ],
  },
  components: {
    MainHeader,
    Footer,
  },
  mixins: [orderMixin],
  data() {
    return {
      preHeight: 0,
      transitionName: DEFAULT_TRANSITION,
    };
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      let transitionName =
        to.meta.transitionName || from.meta.transitionName;

      if (transitionName === "slide") {
        const toDepth = to.path.split("/").length;
        const fromDepth = from.path.split("/").length;
        transitionName =
          toDepth < fromDepth ? "slide-right" : "slide-left";
      }

      this.transitionName = transitionName || DEFAULT_TRANSITION;      

      next();
    });

    window.googleTranslateElementInit = function() {
      const tran = new google.translate.TranslateElement(
        {
          pageLanguage: "ko",
          layout:
            google.translate.TranslateElement.InlineLayout.SIMPLE,
          includedLanguages: "ko,en,ja,zh-CN",
          autoDisplay: false,
        },
        "google_translate_element"
      );
    };

    const playAlert = setInterval(async function() {
      const [d, p] = await request();
    }, 5000);

  },

  methods: {
    beforeLeave(element) {
      this.preHeight = getComputedStyle(element).height;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Quicksand:300,600&display=swap");
@import url("https://cdn.jsdelivr.net/npm/xeicon@2.3.3/xeicon.min.css");

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.2s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}

.google-translate {
  display: none !important;
  display: inline-block;
  vertical-align: top;
  padding-top: 0px;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}

#google_translate_element {
  display: none;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  /* transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0); */
  transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
