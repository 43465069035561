const translate = {
    ko: {
        trans: {
            t01_01: "연장/충전방법",
            t01_02: "고객만족센터",
            t01_03: "로그인",
            t01_04: "마이페이지",
            t01_05: "신청내역조회",
            t01_06: "<span class='banTit fW-R'>이 세상 국민이라면</span><span class='banTit fW-R'><strong>조건없이 모두가 가입</strong>할 수 있는</span><span class='banTit fW-R'>Dosirak Mobile 선불유심!</span>",
            t01_07: "<span class='banTit fW-R'>1회만 연장해도 1만원 증정!</span><span class='banTit fW-R'>가장 저렴한 선불요금제</span><span class='banTit fW-R'>도시락Prepaid와 함께하세요-</span>",
            t01_08: "가입신청",
            t01_09: "요금제",
            t01_10: "<span class='conTit fW-R wh'>연장/충전방법 안내</span><span class='conTxt fW-R wh nextLine'>언제 어디서나 내가 필요할 때!</span><span class='conTxt fW-R wh nextLine'>연장/충전가능!</span>",
            t01_11: "홈페이지<br/>로그인",
            t01_12: "가입정보<br/>확인",// X
            t01_13: "결제방법<br/>선택",
            t01_14: "연장<br/>완료",
            t01_15: "연장/충전 바로가기",
            t01_16: "베스트",
            t01_17: "대용량",
            t01_18: "실속형",
            t01_19: "장기할인",
            t01_20: "사용할수록 저렴해지는 요금을 미리 확인하세요.",
            t01_21: "할인",
            t01_22: "추가 할인",
            t01_23: "자동결제 할인",
            t01_24: "중복 할인",
            t01_25: "데이터",
            t01_26: "통화",
            t01_27: "문자",
            t01_28: "가입하기",
            t01_29: "자세히보기",
            t01_30: "본인인증 없이 누구나 신청! 도시락모바일 선불 요금제",
            t01_31: "전체보기",
            t01_32: "부가서비스",
            t01_33: "고객만족센터",
            t01_34: "운영시간 09:00 ~ 18:00(점심시간 12:00 ~ 13:00) ",
            t01_35: "게시판",
            t01_36: "FAQ",
            t01_37: "공지사항",
            t01_38: "<span class='banTit fW-R'>인천공항으로 입국 예정이라면?</span><span class='banTit fW-R'>공항에서 바로 개통하세요!</span><span class='banTit fW-R'>신청하기 ></span>",
            t02_01: "도시락모바일 요금제",//X
            t02_02: "사용패턴을 선택하시면 적합한 요금제를 추천해드립니다",
            t02_03: "월 납입금액",
            t02_04: "전체",
            t02_05: "데이터 사용량",
            t02_06: "통화 사용량",
            t02_07: "※ 선불 요금제 상품입니다. (VAT 포함)",
            t02_08: "추천순",
            t02_09: "낮은가격순",
            t02_10: "높은가격순",
            t02_11: "할인 적용 시 월 납부금액을 미리 확인해보세요.",
            t02_12: "월 기본료",
            t03_01: "요금제 상세",
            t03_02: "요금제",
            t03_03: "월기본료",
            t03_04: "데이터",
            t03_05: "통화",
            t03_06: "문자",
            t03_07: "기본 제공량이 있는 정액형 상품으로 가입일 기준으로 일할 계산하여 데이터가 제공됩니다.</>사용 요율에 따라 기본료에서 금액이 추가 차감됩니다.",
            t03_08: "기본 안내",
            t03_09: "할인 안내",
            t03_10: "초과 요율",
            t03_11: "충전 안내",
            t03_12: "기타 유의사항",
            t03_13: "가입 신청",
            t04_01: "가입신청",
            t04_02: "입력하신 정보는 계약 및 개통에 활용 되오니 정확한 입력 바랍니다.",
            t04_03: "가입유형",
            t04_04: "<em class='mgl-50 fW-R'>번호이동</em><em class='mgl-50 fW-L'>사용하던 번호 그대로 통신사만 변경(수령 후 개통 신청)</em>",
            t04_05: "<em class='mgl-50 fW-R'>신규가입</em><em class='mgl-50 fW-L'>새로운 번호로 가입 신청</em>",
            t04_06: "요금제",
            t04_07: "요금제를 선택하세요.",
            t04_08: "요금제 선택",
            t04_09: "부가서비스(선택)",
            t04_10: "스팸차단<br/>스팸문자를 차단해주는 서비스",// X
            t04_11: "발신번호표시<br/>발신자의 전화번호를 표시해주는 서비스",// X
            t04_12: "이용약관",
            t04_13: "전체 약관에 모두 동의합니다.",
            t04_14: "(필수) 서비스 이용약관",
            t04_15: "(필수) 개인정보 수집 및 이용안내",
            t04_16: "(선택) 맞춤 혜택정보 및 광고 수신 동의",
            t05_01: "신청 내역 확인",
            t05_02: "가입 상품",
            t05_03: "가입 유형",
            t05_04: "유심 타입",
            t05_05: "요금제",
            t05_06: "예상 월 납부 금액",
            t05_07: "기타비용(최초 1회)",
            t05_08: "총 결제금액",
            t05_09: "<span class='nt_Tit'>※꼭 확인해주세요.</span><span class='nt_Txt'>선택하신 요금제는 선납형 요금제로 LG U+ 통신망을 사용합니다.</span><span class='nt_Txt'>유심비가 있는 경우 1회차 요금에 합산되어 결제됩니다.</span>",
            t05_10: "<div style='font-size:16px; color:red; text-align:left; font-weight:bold;'>통신사 체납이 있으신가요?</div><div style='text-align:left;'>통신사 체납이 있는 경우 가입 및 개통이 불가능 하오니 완납 후 이용 바랍니다.</div>",
            t05_11: "선택 완료",
            t06_01: "<h1>가입신청서</h1><span class='subTit fW-L'>도시락모바일</span>",
            t06_02: "총 결제금액(VAT 포함)",
            t06_03: "STEP1 인증",
            t06_04: "내국인(만 19세 이상)",
            t06_05: "외국인(만 19세 이상)",
            t06_06: "실명확인",
            t06_07: "이름",
            t06_08: "주민등록증",
            t06_09: "운전면허증",
            t06_10: "주민등록번호 전체 입력",
            t06_11: "국적",
            t06_12: "여권",
            t06_13: "여권번호입력",
            t06_14: "외국인등록증",
            t06_15: "외국인 등록 번호 입력",
            t06_16: "<span class='liDesc'>이곳을 클릭하여 사각 박스에<br/><b>선택한 신분증 이미지가 들어오게 첨부해주세요.</b></span>",
            t06_17: "<span class='liDesc'>이곳을 클릭하여 사각 박스에<br/><b>본인 얼굴 이미지가 들어오게 첨부해주세요.</b></span>",
            t06_18: "신분증 첨부 유의사항 확인하기",
            t06_19: "여권 첨부 유의사항 확인하기",
            t06_20: "내국인(만 19세 미만) 가입 바로가기",
            t06_21: "신분증은 꼭 이렇게 첨부해주세요!",
            t06_22: "신분증 첨부",
            t06_23: "본인 얼굴 촬영",
            t06_24: "<span class='nt_Txt'>정면으로 촬영해주세요.</span><span class='nt_Txt'>신분증은 원본 사진만 가능합니다.</span><span class='nt_Txt'>신분증 내용이 정확히 확인할 수 있게 촬영해주세요.</span>",
            t06_25: "확인",
            t06_26: "이전",
            t06_27: "다음",
            t06_28: "가입신청서",
            t07_01: "STEP2 가입정보",
            t07_02: "신규가입 정보",
            t07_03: "희망 번호(뒤4자리)",
            t07_04: "1지망",
            t07_05: "2지망",
            t07_06: "※ 희망번호가 없으면 가능한 가까운 번호로 임의 지정되어 개통 됩니다.",
            t07_07: "번호이동 정보",
            t07_08: "현재 통신사",
            t07_09: "SKT",
            t07_10: "KT",
            t07_11: "LGU+",
            t07_12: "알뜰폰",
            t07_13: "휴대폰 번호",
            t07_14: "인증방법",
            t07_15: "신용카드",
            t07_16: "계좌번호",
            t07_17: "지로납부",
            t07_18: "뒤 4자리 입력",
            t07_19: "※ 현재 사용중인 휴대폰 요금 납부 정보 입력",
            t07_20: "휴대폰 일련번호",
            t07_21: "<span class='inputNotice'>※ 휴대폰 일련번호 확인 방법</span><span class='inputNotice'>○ 안드로이드(삼성, LG등)</span><span class='inputNotice'>1. 설정 > 디바이스 정보 > 상태 > 일련번호 확인</span><span class='inputNotice'>2. 설정 > 휴대전화 정보 > 규제정보 > 일련번호 확인</span><br><span class='inputNotice'>○ 아이폰: 설정 > 일반 > 일련번호</span><span class='inputNotice'>현재 이용중인 통신사에 등록된 휴대폰 일련번호와 사용중인 휴대폰의 일련번호가 상이할 경우 인증이 불가할 수 있습니다.</span><br>",
            t07_22: "사업자",
            t07_23: "기존 휴대폰 정보",
            t07_24: "유형 선택",
            t07_25: "LGU+",
            t07_26: "KT/SKT",
            t07_27: "자급제폰",
            t07_28: "외산폰",
            t07_29: "확인불가",
            t07_30: "휴대폰 모델명 입력",
            t07_31: "<span class='inputNotice'>※ 휴대폰 출시일자가 2015년 이후인 기종(ex.갤럭시 노트5, 아이폰6s)은 유심호환이 되며, 해당일자 이전 기종은 LGU+용 LTE 기기만 호환됩니다.</span><span class='inputNotice'>※ 휴대폰 유형 정보가 정확하지 않은 경우 정상개통이 되지 않을 수 있습니다.</span><span class='inputNotice'>※ 해외에서 구매하신 휴대폰의 경우 국내 제조사 기기라도 호환이 되지 않습니다.(단, NEXUS X5 제외)</span>",
            t07_32: "가입자 정보",
            t07_33: "휴대폰 번호 입력",
            t07_34: "이메일",
            t07_35: "<span class='inputNotice'>※ 입력된 정보는 배송안내 등 필수 안내에 사용되므로 정확히 입력해주세요.</span>",
            t07_36: "배송정보",
            t07_37: "가입자 정보와 동일",
            t07_38: "찾아가는 방문서비스", 
            t07_39: "배송시작",
            t07_40: "배송지도착",
            t07_41: "신분증 확인",
            t07_42: "고객 수령",
            t07_43: "<span class='noticeTxt'>※ 찾아가는 방문서비스는 무료입니다.</span><span class='noticeTxt'>※ 찾아가는 방문서비스는 배송기사가 직접 방문하여 신분 확인 후 유심을 전달됩니다.</span><span class='noticeTxt'>※ 유심 수령 시 반드시 신분증을 지참하여 수령해주시길 바랍니다.</span><span class='noticeTxt'>※ 배송 사정에 의해 지연배송 될 수 있는 점 참고 부탁드립니다.</span><span class='noticeTxt'>※ 번호이동 고객은 수령 후 반드시 홈페이지를 통해 개통요청을 진행해주십시오.</span><span class='noticeTxt'>※ 유심은 개통 후 발송되며,  찾아가는 방문서비스는 배송기간 영업일 기준 3-5일 정도 소요됩니다. (주말/공휴일 출고 및 배송불가)</span><span class='noticeTxt'>※ 고객사유에 의한 상품 미수령 시 실제 사용일이 단축될 수 있습니다.(※신규가입 대상)</span><span class='noticeTxt'>※ 기사님 배송 시점에 부재인 경우 물건이 반송될 수 있습니다.<br/>수령이 지연되는 경우 반드시 고객센터로 연락 부탁드리며, 신청 시 한국 연락처가 없는 경우 기재된 이메일로 연락이 갑니다.</span>",
            t07_44: "수령인 이름을 입력해주세요",
            t07_45: "휴대폰 번호 입력",
            t07_46: "이메일",
            t07_47: "우편번호",
            t07_48: "우편번호 검색",
            t07_49: "기본주소",
            t07_50: "상세 주소를 입력해주세요",
            t07_51: "※ 배송불가 지역은 서비스 이용을 할 수 없습니다.",
            t07_52: "서울고객센터", 
            t07_53: "<span class='noticeTxt'>배송 없이 서울 본사 사무실로 직접 방문 후 수령하실 수 있습니다.</span><span class='noticeTxt'>위치 : 서울시 서대문구 통일로 135 충정빌딩 6층</span><span class='noticeTxt'>운영시간: 09:00 ~ 18:00 (점심시간 12:00 ~ 13:00)</span><span class='noticeTxt'>주말/공휴일 이용불가</span>",
            t08_01: "STEP3 결제",
            t08_02: "결제정보",
            t08_03: "가입완료 후 결제",
            t08_04: "<span class='inputNotice bk'>※ 가입완료 전/후 결제 선택에 따라 결제방법을 선택하실 수 있습니다.</span><span class='inputNotice bk'>※ ‘가입완료 후 결제’는 가입신청서 작성 완료 후 선택하신 결제방법으로 결제완료되면 가입신청이 승인됩니다.</span>",
            t08_05: "가상계좌",
            t08_06: "은행선택",
            t08_07: "가입신청서작성 완료 페이지에서 가상계좌번호를 확인하실 수 있습니다.",
            t08_08: "편의점결제",
            t08_09: "<span class='noticeTxt'>가입신청서 작성 완료 후 알림톡(문자)로 수납번호를 발송해드립니다.</span><span class='noticeTxt'>가까운 편의점에서 현금으로 결제하세요. (편의점 직원에게 수납번호를 보여주세요)</span><span class='noticeTxt'>전국 GS25, 세븐일레븐, CU, C-SPACE 매장 어디서나 결제 가능합니다.</span><span class='noticeTxt'>신청서 작성 후 7일 이내에 수납을 완료 하셔야 가입신청이 완료됩니다.</span>",
            t08_10: "즉시결제",
            t08_11: "신용/체크카드",
            t08_12: "<span class='inputNote pay'>※ 가입자 본인 명의 카드/계좌만 이용 가능합니다</span>",
            t08_13: "카드정보",
            t08_14: "국내카드",
            t08_15: "해외카드",
            t08_16: "카드번호",
            t08_17: "생년월일",
            t08_18: "유효기간 년",
            t08_19: "유효기간 월",
            t08_20: "유효성 체크",
            t08_21: "계좌이체",
            t08_22: "계좌정보",
            t08_23: "은행선택",
            t08_24: "계좌번호",
            t08_25: "카카오페이",
            t08_26: "자동결제 <em class='nextLine'>서비스</em>", //x
            t08_27: "신청",
            t08_28: "<span class='noticeTxt'>※ 이용 종료일 3일전 기본료가 자동 결제됩니다.</span><span class='noticeTxt'>※ 신청 후 익월(2회차) 자동결제분 부터 할인이 적용됩니다.</span><span class='noticeTxt'>※ 장기 할인과 중복 할인 혜택을 받으실 수 있습니다. (자동 적용됩니다.)</span>",
            t08_29: "본인은 본 신청서 계약내용에 따른 납부요금을 별도의 통지 없이 위 계좌(카드)에서 지정된 출금일에 자동 인출(결제) 되는 것에 동의합니다.",
            t08_30: "신청안함",
            t09_01: "가입신청이 접수되었습니다.",
            t09_02: "신청내역",
            t09_03: "가입유형", //x
            t09_04: "요금제", //x
            t09_05: "유심타입",
            t09_06: "부가서비스",
            t09_07: "총 결제금액",//x
            t09_08: "결제방법",
            t09_09: "자동결제",
            t09_10: "가입신청서류 다운받기",
            t09_11: "※ 신청서에 기재하신 내용으로 가입신청 서류 작성이 완료되었습니다.<br/>※ 아래에서 확인 및 다운 받으실 수 있습니다.",
            t09_12: "무선서비스 계약 표준 안내서",
            t09_13: "온라인 가입신청서",
            t10_01: "이용약관",
            t10_02: "개인정보처리방침",
            t10_03: "(주)와이드모바일 ｜ 대표자 : 김만중 ｜ 서울시 서대문구 통일로 135 충정빌딩 6층<br/>고객센터 : 1566-9071 ｜ 사업자등록번호 : 121-81-66458<br/>통신판매업신고번호 : 제 2018-서울서대문-0301호",
            t11_01: "예상 월 납부금액",
            t11_02: "월 기본요금",
            t11_03: "부가서비스",
            t11_04: "요금할인",
            t11_05: "기타비용(최초 1회)", //x
            t11_06: "유심비",
            t11_07: "배송비",
            t11_08: "조건부 무료",
            t11_09: "할인금액",
            t11_10: "자동결제할인",
            t11_11: "장기이용할인",
            t12_01: "로그인",
            t12_02: "요금 및 사용량 조회 등 더욱 편리한 서비스 이용이 가능합니다.",
            t12_03: "번호 인증 로그인",
            t12_04: "이름을 입력해주세요.",
            t12_05: "개통 번호",
            t12_06: "인증번호 발송",
            t12_07: "인증번호확인",
            t12_08: "개인정보 취급 방침",
            t12_09: "개인정보수집 내용에 동의합니다.",
            t12_10: "회원 로그인",
            t12_11: "로그인 상태 유지",
            t12_12: "메인으로",
            t12_13: "SNS 로그인",
            t12_14: "아이디찾기",
            t12_15: "비밀번호찾기",
            t12_16: "회원가입",
            t12_17: "로그인",
            t13_01: "아이디/비밀번호 찾기",
            t13_02: "아이디, 비밀번호 찾기를 위해 휴대폰 인증이 필요합니다.",
            t13_03: "아이디찾기",
            t13_04: "휴대폰 인증하기 버튼을 클릭하여<b> 본인인증을 진행해주세요.</b>",
            t13_05: "휴대폰 인증하기",
            t13_06: "취소",//todo
            t13_07: "비밀번호 찾기",
            t13_08: "아이디 확인 및 본인인증",
            t13_09: "아이디(메일)",
            t13_10: "휴대폰 인증하기",
            t14_01: "신청내역조회",
            t14_02: "가입신청 내역과 배송상태를 확인 하실 수 있습니다.",
            t14_03: "비회원 신청조회",
            t14_04: "주문번호",
            t14_05: "가입자명",
            t14_06: "회원신청조회",
            t15_01: "신청내역을 조회 하실 수 있습니다.",
            t15_02: "1개월",
            t15_03: "3개월",
            t15_04: "6개월",
            t15_05: "조회",
            t15_06: "신청일",
            t15_07: "주문번호",
            t15_08: "가입유형",
            t15_09: "신청요금제",
            t15_10: "상태",
            t15_11: "배송",
            t15_12: "취소/반품",
            t15_13: "더보기",
            t16_01: "마이페이지",
            t16_02: "내 정보 수정, 가입정보 및 부가서비스 조회 확인이 가능합니다.",
            t16_03: "회원정보",
            t16_04: "아이디",
            t16_05: "휴대폰 번호",
            t16_06: "홈페이지 회원 정보 수정",
            t16_07: "가입정보",
            t16_08: "선택",
            t16_09: "조회하기",
            t17_01: "가입정보",
            t17_02: "나의 요금제를 간편하게 조회하세요.",
            t17_03: "고객정보",
            t17_04: "요금제명",
            t17_05: "개통일자",
            t17_06: "이용기간",
            t17_07: "요금제 만료일",
            t17_08: "추가충전잔액",
            t17_09: "자동결제상태",
            t17_10: "신청",
            t17_11: "연장사용/충전 바로가기",
            t18_01: "회원정보수정",
            t18_02: "도시락모바일 회원가입 정보 수정이 가능합니다.",
            t18_03: "아이디",
            t18_04: "이름",
            t18_05: "휴대전화번호",
            t18_06: "변경하기",
            t18_07: "생년월일",
            t18_08: "비밀번호",
            t18_09: "성별",
            t18_10: "남자",
            t18_11: "여자",
            t18_12: "선택정보(수신동의)",
            t18_13: "이메일",
            t18_14: "SMS",
            t18_15: "SNS 로그인 연동",
            t18_16: "페이스북",
            t18_17: "카카오톡",
            t18_18: "네이버",
            t18_19: "회원탈퇴",
            t18_20: "정보수정완료",
            t19_01: "휴대폰 번호 변경 안내",
            t19_02: "<span class='noteTxt'>로그인한 아이디에 <strong>새로 사용하실 휴대폰번호</strong>를 입력해주세요.</span><span class='noteTxt'>휴대폰 번호 변경 시 와이파이도시락, 도시락USIM 홈페이지 아이디의 휴대폰 번호도 동일하게 변경됩니다.</span>",
            t19_03: "신규 휴대폰 번호 입력",
            t19_04: "인증번호입력",
            t19_05: "인증번호 전송",
            t19_06: "확인불가",
            t19_07: "휴대폰 번호 변경하기",
            t19_08: "취소",
            t20_01: "비밀번호 변경 안내", //todo
            t20_02: "<span class='noteTxt'>로그인한 아이디에<strong>새로 사용하실 비밀번호</strong>를 입력해주세요.</span><span class='noteTxt'>비밀번호 변경 시 와이파이도시락, 도시락USIM 홈페이지 아이디의 비밀번호도 동일하게 변경됩니다.</span>",
            t20_03: "현재 비밀번호 입력",
            t20_04: "신규 비밀번호 입력",
            t20_05: "신규 비밀번호 확인",
            t20_06: "비밀번호 변경 하기",
            t21_01: "이용약관/개인정보처리방침",
            t21_02: "사이트 및 서비스 이용약관과 개인정보처리방침을 확인하실 수 있습니다.",
            t21_03: "도시락Prepaid 이용약관",
            t21_04: "와이드모바일 통합회원 약관",
            t21_05: "개인정보처리방침",
            t21_06: "이용약관 별표 다운로드",
            t21_07: "VAT 포함",
            t21_08: "배송조회",
            t21_09: "신청하기",
            t21_10: "이용신청기간",
            t21_11: "회차",
            t21_12: "할인요금",
            t21_13: "일",
            t21_14: "일괄충전신청",
            t21_15: "원",
            t21_16: "결제금액",
            t21_17: "기본요금",
            t21_18: "일 사용",
            t21_19: "60일 이상 선택(즉시할인), 120일 이상 선택(추가 할인)",
            t21_20: "주한미군",
            t21_21: "영주증",
            t21_22: "재외동포거소증",
            t21_23: "주한미국 ID를 입력하세요.",
            t21_24: "영주증 등록번호를 입력하세요.",
            t21_25: "재외동포 거소증 등록번호를 입력하세요.",
            t21_26: "(필수) 고유식별정보 수집·이용 동의",
            t21_27: "(필수) 개인정보 제3자 제공 동의",
            t21_28: "(필수) 개인정보 제3자 위탁 동의",
            t21_29: "(필수) 개인신용정보 조회 동의",
            t99_99: "도시락모바일",

        }

    },
    en: {
        trans: {
            t01_01: "Extension/charging method",
            t01_02: "Customer Satisfaction Center",
            t01_03: "Login",
            t01_04: "My Page",
            t01_05: "Inquire details of application",
            t01_06: "<span class='banTit fW-R'>Prepaid Dosirak Mobile</span><span class='banTit fW-R'>USIM that everyone in the world</span><span class='banTit fW-R'>can join without any condition!</span>",
            t01_07: "<span class='banTit fW-R'>10,000 Won will be given even if you extend only once!</span><span class='banTit fW-R'>The most inexpensive prepaid plan</span><span class='banTit fW-R'>Try Dosirak Prepaid plan</span>",
            t01_08: "Subscription application",
            t01_09: "Rate plan",
            t01_10: "<span class='conTxt fW-R wh'>Guidance to extension/charging method</span><span class='conTxt fW-R wh'>Whenever you need, anytime and anywhere!</span><span class='conTxt fW-R wh nextLine'>Can be extended/charged!</span>",
            t01_11: "Website<br/>login",
            t01_12: "Check subscription</br>information",
            t01_13: "Select<br/>payment method",
            t01_14: "Extension completed",
            t01_15: "Go directly to Extension/Charging",
            t01_16: "Best",
            t01_17: "Large capacity",
            t01_18: "No-frills type",
            t01_19: "Long-term discount",
            t01_20: "Check our rates, the more you use the service the more it gets cheaper",
            t01_21: "Discount",
            t01_22: "Additional discount",
            t01_23: "Automatic payment discount",
            t01_24: "Overlapping discount",
            t01_25: "Data",
            t01_26: "Calls",
            t01_27: "Text messages",
            t01_28: "Subscribe",
            t01_29: "View in detail",
            t01_30: "Anyone can apply without an ID! Prepaid Dosirak Mobile rate plan",
            t01_31: "View all",
            t01_32: "Additional service",
            t01_33: "Customer Satisfaction Center",
            t01_34: "Business hours: 09:00 ~ 18:00 (Lunch hour: 12:00 ~ 13:00) ",
            t01_35: "Notice board",
            t01_36: "FAQ",
            t01_37: "Public notices",
            t01_38: "<span class='banTit fW-R'>Do you need a mobile prepaid service?</span><span class='banTit fW-R'>Apply at the Incheon airport right away!</span><span class='banTit fW-R'>Application ></span>",
            t02_01: "Dosirak Mobile rate plan",
            t02_02: "If you select a usage pattern, we will recommend the appropriate rate plan that match your needs.",
            t02_03: "Monthly fee",
            t02_04: "All",
            t02_05: "Data usage",
            t02_06: "Calls made",
            t02_07: "※ It is a product with prepaid rate plan (inclusive of VAT).",
            t02_08: "In the order of recommendation",
            t02_09: "In the order of lowest price",
            t02_10: "In the order of highest price",
            t02_11: "Check the monthly fee in advance at the time of the discount application.",
            t02_12: "Monthly basic fee",
            t03_01: "Detailed rate plan",
            t03_02: "Rate plan",
            t03_03: "Monthly basic fee",
            t03_04: "Data",
            t03_05: "Calls",
            t03_06: "Text messages",
            t03_07: "Data is provided by computing the data needed on daily basis with the date of subscription for flat rate product with provision of basic volume of data as the reference date.<br/>Fees will be added or deducted to or from the basic rate in accordance with the volume used.",
            t03_08: "Basic guidance",
            t03_09: "Information on discount",
            t03_10: "Rate for excessive use",
            t03_11: "Fee guidance",
            t03_12: "Other precautions",
            t03_13: "Subscription application",
            t04_01: "Subscription application",
            t04_02: "Please input information accurately as they are used for contract and activation of the phone.",
            t04_03: "Subscription type",
            t04_04: "<em class='mgl-50 fW-R'>Change of number</em><em class='mgl-50 fW-L'>Use the number you have been using with change of the telecommunication company only (apply activation after having received the product).</em>",
            t04_05: "<em class='mgl-50 fW-R'>New subscription</em><em class='mgl-50 fW-L'>Apply subscription with new number</em>",
            t04_06: "Rate plan",
            t04_07: "Select rate plan",
            t04_08: "Select rate plan",
            t04_09: "Additional service (elective)",
            t04_10: "Block SPAM<br/>Service that blocks SPAM text messages",
            t04_11: "Display caller number<br/>Service for displaying the phone number of the caller",
            t04_12: "Terms and conditions of use",
            t04_13: "I consent to all the terms and conditions.",
            t04_14: "(Requisite) Terms and conditions of service use",
            t04_15: "(Requisite) Guidance on collection and use of personal information",
            t04_16: "(Elective) Consent to reception of customized beneficial information and advertisement",
            t05_01: "Check details of application",
            t05_02: "Subscription product",
            t05_03: "Subscription type",
            t05_04: "USIM type",
            t05_05: "Rate plan",
            t05_06: "Anticipated monthly payment",
            t05_07: "Other costs (at the time of the first payment)",
            t05_08: "Total payment amount",
            t05_09: "<span class='nt_Tit'>※ Please make sure to check the following.</span><span class='nt_Txt'>The rate plan you selected is a prepaid type plan which uses LG U+ telecommunication network.</span><span class='nt_Txt'>If there is a cost for USIM, it will be included in the 1st invoice.</span>",
            t05_10: "<div style='font-size:16px; color:red; text-align:left; font-weight:bold;'>Do you have payments in arrears to be made to any telecommunication company?</div><div style='text-align:left;'>If you do, you won’t be able to subscribe to our service and activate the phone. Please use our service after settling your previous accounts.</div>",
            t05_11: "Selection completed",
            t06_01: "<h1>Subscription application form</h1><span class='subTit fW-L'>Dosirak Mobile</span>",
            t06_02: "Total amount to be paid (Inclusive of VAT)",
            t06_03: "STEP 1 Certification",
            t06_04: "Korean (over the age of 19 years)",
            t06_05: "Foreigner (over the age of 19 years)",
            t06_06: "Check one’s real name",
            t06_07: "Name",
            t06_08: "Resident registration certificate",
            t06_09: "Driver’s license",
            t06_10: "Input full resident registration No.",
            t06_11: "Nationality",
            t06_12: "Passport",
            t06_13: "Input Passport",
            t06_14: "Regidence Card(Formerly ARC)",
            t06_15: "Input Regidence Card(Formerly ARC) No.",
            t06_16: "<span class='liDesc'>Please attach the ID image you selected to be put into<br/><b>the rectangular box by clicking this.</br></span>",
            t06_17: "<span class='liDesc'>Please attach your face image to be put into<br/><b>the rectangular box by clicking this.</br></span>",
            t06_18: "Check whether your ID has been attached.",
            t06_19: "Check whether your passport has been attached.",
            t06_20: "Go directly to subscription by Korean (less than 19 years old)",
            t06_21: "Make sure to attach your ID in this manner!",
            t06_22: "Attach ID",
            t06_23: "Take photograph of your face",
            t06_24: "<span class='nt_Txt'>Please take frontal view photo.</span><span class='nt_Txt'>Only original photo of ID can be used.</span><span class='nt_Txt'>Take photo to enable accurate confirmation of ID contents.</span>",
            t06_25: "Confirm",
            t06_26: "Previous",
            t06_27: "Next",
            t06_28: "Subscription application form",
            t07_01: "STEP 2 Subscription information",
            t07_02: "New subscription information",
            t07_03: "Desired No. (last 4 digits)",
            t07_04: "1st preference",
            t07_05: "2nd preference",
            t07_06: "※ If there is no preferred No., nearest available No. will be arbitrarily designated for activation of the phone.",
            t07_07: "No. transfer information",
            t07_08: "Current telecommunication company",
            t07_09: "SKT",
            t07_10: "KT",
            t07_11: "LGU+",
            t07_12: "MVNO",
            t07_13: "Mobile phone No.",
            t07_14: "Certification method",
            t07_15: "Credit card",
            t07_16: "Account No.",
            t07_17: "Payment by Giro (electronic billing service)",
            t07_18: "Enter the last 4 digits",
            t07_19: "※ Enter the payment information on the mobile phone plan that you are currently using",
            t07_20: "Mobile phone serial No. ",
            t07_21: "<span class='inputNotice'>※ How to check the serial No. of the mobile phone</span><br/><span class='inputNotice'>○ Android (Samsung, LG, etc.)</span><br/><span class='inputNotice'>1. Setting > Device information > Status > Check serial No.</span><br/><span class='inputNotice'>2. Setting > Mobile phone information > Regulation information > Check serial No.</span><br/><span class='inputNotice'>○ I-Phone: Setting > General > Serial No.</span><span class='inputNotice'>If the serial No. of the mobile phone registered with the telecommunication company you are currently using differs from the serial No. of the mobile phone currently being used, certification may not be possible.</span>",
            t07_22: "Business operator information",
            t07_23: "Existing mobile phone information",
            t07_24: "Select the type",
            t07_25: "LGU+",
            t07_26: "KT/SKT",
            t07_27: "Non-contractual mobile phone",
            t07_28: "Foreign made mobile phone",
            t07_29: "Unable to confirm",
            t07_30: "Enter the model name of mobile phone",
            t07_31: "<span class='inputNotice'>※ Mobile phone models launched after 2015 (i.e, Galaxy Note 5, I-Phone 6s) are compatible with the USIM but those launched before 2015 are compatible only with LGU+ LTE devices.</span><span class='inputNotice'>※ Phone may not be normally activated if the information on the mobile phone model is not accurate.</span><span class='inputNotice'>※ Mobile phone you purchased overseas is not compatible even if it is manufactured by a Korean company (NEXUS X5 is excluded).</span>",
            t07_32: "Subscriber information",
            t07_33: "Enter mobile phone No.",
            t07_34: "E-mail",
            t07_35: "<span class='inputNotice'>※ Make sure to input your information accurately as they are used in the provision of essential guidance such as inquiring details of application and delivery notice, etc.</span>",
            t07_36: "Delivery information",
            t07_37: "Same as the subscriber information",
            t07_38: "Door-to-door service",
            t07_39: "Start delivery",
            t07_40: "Arrival at delivery location",
            t07_41: "Check ID",
            t07_42: "Received by customer",
            t07_43: "<span class='noticeTxt'>※ Door-to-door service is free.</span><span class='noticeTxt'>※ For the door-to-door service, the delivery staff will personally visit the customer and hand him/her over the USIM after checking the ID of the customer.</span><span class='noticeTxt'>※ Make sure to present your ID when receiving the USIM.</span><span class='noticeTxt'>※ We seek your understanding that there could be delay in delivery depending on circumstances.</span><span class='noticeTxt'>※ Customers seeking to transfer the phone number must request activation of the phone through the website.</span><span class='noticeTxt'>※ The SIM will be sent after activation, and the door-to-door service will take 3-5 business days for delivery. (Weekend/holiday delivery unavailable)</span><span class='noticeTxt'>※ If you fail to receive the item due to reasons attributable to you, the actual number of days of use can be shortened. (※in the case of new subscription)</span><span class='noticeTxt'>※ If  you are absent at the time of delivery, the item may be returned. In case of delay in receipt, please be sure to contact the customer service center. If you do not have a Korean contact number at the time of application, you will be contacted separately through the email address provided.</span>",
            t07_44: "Enter the name of receiver.",
            t07_45: "Enter mobile phone No.",
            t07_46: "E-mail",
            t07_47: "Postcode",
            t07_48: "Search postcode",
            t07_49: "Basic address",
            t07_50: "Enter detailed address",
            t07_51: "※ The service cannot be used in the regions where the product can not be delivered.",
            t07_52: "Seoul Customer Center",
            t07_53: "<span class='noticeTxt'>※ You can collect your product by personally visiting the head office in Seoul rather than through delivery.</span><span class='noticeTxt'>※ Location: 6th Fl., Chungjeong Bldg., #135 Tongil-ro, Seodaemun-gu, Seoul</span><span class='noticeTxt'>※ Business hours: 09:00 ~ 18:00 (Lunch hour: 12:00 ~ 13:00)</span><span class='noticeTxt'>※ Closed on weekends / holidays</span>",
            t08_01: "STEP 3 Payment",
            t08_02: "Payment information",
            t08_03: "Make payment upon completion of subscription",
            t08_04: "<span class='inputNotice bk'>※ You can choose the payment method according to the selected payment option, prior or after the completion of the subscription.</span><span class='inputNotice bk'>※ ‘Payment following the completion of subscription’ is the payment method you select to make payment following completion of the subscription application. As such, the subscription application will be approved after the payment is processed.</span>",
            t08_05: "Virtual account",
            t08_06: "Choose bank",
            t08_07: "You can check virtual account No. in the page for completion of filling in subscription application form.",
            t08_08: "Payment at convenience store",
            t08_09: "<span class='noticeTxt'>※ Payment reception number will be forwarded through Notice Talk (text message) upon completion of the subscription application.</span><span class='noticeTxt'>※ Proceed with the payment in cash at the nearest convenience store. (Present your payment reception number to the convenience store staff.)</span><span class='noticeTxt'>※ Payment can be made at any GS25, Seven-Eleven, CU and C-SPACE outlets nationwide.</span><span class='noticeTxt'>※ Subscription application will be completed if you make payment within 7 days.</span>",
            t08_10: "Immediate payment",
            t08_11: "Credit/Deposit card",
            t08_12: "<span class='inputNote pay'>※ Only the card/account under the name of the subscriber can be used.</span>",
            t08_13: "Card information",
            t08_14: "Domestic credit card",
            t08_15: "Overseas credit card",
            t08_16: "Credit card No.",
            t08_17: "Date of birth",
            t08_18: "Validity period: Year",
            t08_19: "Validity period: Month",
            t08_20: "Check validity",
            t08_21: "Account transfer",
            t08_22: "Account information",
            t08_23: "Select bank",
            t08_24: "Account No.",
            t08_25: "Kakao Pay",
            t08_26: "Automatic payment <em class='nextLine'>service</em>",
            t08_27: "Application",
            t08_28: "<span class='noticeTxt'>※ Basic fee will be automatic invoiced 3 days prior to termination date of use.</span><span class='noticeTxt'>※ Discount will be applied for the automatic payment portion from the month immediately following the month of application (at the time of the 2nd payment).</span><span class='noticeTxt'>※ You can receive long-term discount and double discount benefits (Applied automatically).</span>",
            t08_29: "I consent to automatic withdrawal (payment) at the designated payment day from the aforementioned account (credit card) without notification of the charges to be paid in accordance with the contents of the contract attached to the application form.",
            t08_30: "Do not apply.",
            t09_01: "Subscription application has been received.",
            t09_02: "Application details",
            t09_03: "Subscription type",
            t09_04: "Rate plan",
            t09_05: "USIM type",
            t09_06: "Additional service",
            t09_07: "Total payment amount",
            t09_08: "Choose payment method",
            t09_09: "Automatic payment",
            t09_10: "Download subscription application form",
            t09_11: "※ Filling the subscription application form is completed with the contents you entered in the application form.<br/>※ You can check the contents of and download the completed application form below.",
            t09_12: "Standard guidance for wireless service contract",
            t09_13: "Online subscription application form",
            t10_01: "Terms and conditions",
            t10_02: "Policy on personal information processing",
            t10_03: "Wide Mobile Co., Ltd. ｜ Representative director: Man Jung Kim ｜ 6th Fl., Chungjeong Bldg., #135 Tongil-ro, Seodaemun-gu, Seoul<br/>Customer Center: 1566-9071 ｜ Business Registration No.: 121-81-66458<br/>Telecommunication Sales Business Report No.: 2018-SEOULSEODAEMUN-0301",
            t11_01: "Anticipated monthly payment",
            t11_02: "Basic monthly fee",
            t11_03: "Additional service",
            t11_04: "Discount on fees",
            t11_05: "Other costs (at the time of the 1st payment)",
            t11_06: "USIM cost",
            t11_07: "Delivery cost",
            t11_08: "Conditionally free",
            t11_09: "Discount amount",
            t11_10: "Automatic payment discount",
            t11_11: "Long-term use discount",
            t12_01: "Login",
            t12_02: "You can use much more convenient services including inquiries on charges and the volume of usage.",
            t12_03: "Certification number Login",
            t12_04: "Enter name",
            t12_05: "Activation No.",
            t12_06: "Enter Certification No.",
            t12_07: "Check Certification No.",
            t12_08: "Policy on handling personal information",
            t12_09: "I consent to the details related to the collection of personal information.",
            t12_10: "Member login",
            t12_11: "Maintain login session ",
            t12_12: "To Main page",
            t12_13: "SNS login",
            t12_14: "Find ID",
            t12_15: "Find Password",
            t12_16: "Membership subscription",
            t12_17: "Login",
            t13_01: "Find ID/Password",
            t13_02: "Mobile phone certification is necessary to find ID and Password.",
            t13_03: "Find ID",
            t13_04: "Execute certification of your ID by clicking<b> ‘Mobile phone certification’ button.</b>",
            t13_05: "Certify mobile phone",
            t13_06: "Cancel",
            t13_07: "Find Password",
            t13_08: "ID confirmation and certification",
            t13_09: "ID (mail)",
            t13_10: "Certify mobile phone",
            t14_01: "Inquire details of application",
            t14_02: "You can check your subscription application details and status of delivery.",
            t14_03: "Inquire non-member application",
            t14_04: "Order No.",
            t14_05: "Subscriber’s name",
            t14_06: "Inquire membership application",
            t15_01: "You can inquire application details.",
            t15_02: "1 month",
            t15_03: "3 months",
            t15_04: "6 months",
            t15_05: "Inquiries",
            t15_06: "Application date",
            t15_07: "Order No.",
            t15_08: "Subscription type",
            t15_09: "Applied rate plan",
            t15_10: "Status",
            t15_11: "Delivery",
            t15_12: "Cancel/Return",
            t15_13: "View more",
            t16_01: "My Page",
            t16_02: "You can correct your information and subscription information, and inquire and check additional services.",
            t16_03: "Membership information",
            t16_04: "ID",
            t16_05: "Mobile phone No.",
            t16_06: "Correct membership information in the website",
            t16_07: "Subscription information",
            t16_08: "Elective",
            t16_09: "Inquire",
            t17_01: "Subscription information",
            t17_02: "You can conveniently make inquiries on you rate plan.",
            t17_03: "Customer information",
            t17_04: "Name of the rate plan",
            t17_05: "Activation date",
            t17_06: "Period of use",
            t17_07: "Plan Termination Date ",
            t17_08: "Balance of additional charges",
            t17_09: "Status of automatic payment",
            t17_10: "Application",
            t17_11: "Go directly to use of extension/charges",
            t18_01: "Revise membership information",
            t18_02: "You can revise Dosirak Mobile membership subscription information.",
            t18_03: "ID",
            t18_04: "Name",
            t18_05: "Mobile phone No.",
            t18_06: "Change",
            t18_07: "Date of birth",
            t18_08: "Password",
            t18_09: "Gender",
            t18_10: "Male",
            t18_11: "Female",
            t18_12: "Selective information (consent to receive)",
            t18_13: "E-mail",
            t18_14: "SMS",
            t18_15: "Link with SNS login",
            t18_16: "Facebook",
            t18_17: "Kakao Talk",
            t18_18: "Naver",
            t18_19: "Withdraw membership",
            t18_20: "Information revision completed",
            t19_01: "Guidance on how to change mobile phone No.",
            t19_02: "<span class='noteTxt'>Enter the new mobile phone No. to be used in the logged in ID.</span><span class='noteTxt'>At the time of changing mobile phone No., the mobile phone No. of the WiFi Dosirak and Dosirak USIM website ID will also be changed equally.</span>",
            t19_03: "Enter new mobile phone No.",
            t19_04: "Enter certification No.",
            t19_05: "Transmit the certification No.",
            t19_06: "Cannot be confirmed",
            t19_07: "Change the mobile phone No.",
            t19_08: "Cancel",
            t20_01: "Guidance on changing Password",
            t20_02: "<span class='noteTxt'>Enter the new Password to be used with the ID.</span><span class='noteTxt'>If you change the Password, the Password of the WiFi Dosirak and Dosirak USIM website ID will also be automatically changed.</span>",
            t20_03: "Enter current Password",
            t20_04: "Enter new Password",
            t20_05: "Confirm new Password",
            t20_06: "Change Password",
            t21_01: "Terms and conditions of use/Policy in processing personal information",
            t21_02: "You can check the terms and conditions of the use of the website and services, and policy on processing of personal information.",
            t21_03: "Terms and conditions of the use of Dosirak Prepaid",
            t21_04: "Terms and conditions of Wide Mobile Integrated Membership",
            t21_05: "Policy on processing personal information",
            t21_06: "Download attached table of terms and conditions of the use",
            t21_07: "Inclusive of VAT",
            t21_08: "inquiries",
            t21_09: "Application",
            t21_10: "Application period",            
            t21_11: "Cycle",
            t21_12: "Discount charge",
            t21_13: "day",
            t21_14: "Select period of use",
            t21_15: "KRW",
            t21_16: "Payment amount",            
            t21_17: "Base rate",
            t21_18: "days use",
            t21_19: "Select at least 60 days (immediate discount), select at least 120 days (additional discount)",
            t21_20: "US army ID",
            t21_21: "PERMANENT RESIDENT CARD",
            t21_22: "OVERSEAS KOREAN RESIDENT CARD",      
            t21_23: "Please enter your DOD number",
            t21_24: "Please enter your card number",
            t21_25: "Please enter your card number",            
            t21_26: "(Required) Consent to collection and use of unique identification information",
            t21_27: "(Required) Consent to provide personal information to third parties",
            t21_28: "(Required) Consent to entrust personal information to a third party",
            t21_29: "(Required) Consent to personal credit information inquiry",            
            t99_99: "Dosirak Mobile",

        }
    },
    cn: {
        trans: {
            t01_01: "延期/充值方法",
            t01_02: "客服中心",
            t01_03: "登录",
            t01_04: "我的页面",
            t01_05: "申请详情查询",
            t01_06: "<span class='banTit fW-R'>“所有人都可以注册的</span><span class='banTit fW-R'>Dosirak Mobile 预付费 SIM 卡！</span>”",
            t01_07: "<span class='banTit fW-R'>“即使只延长一次，</span><span class='banTit fW-R'>也能获得10,000韩元！最优惠的预付费方式，</span><span class='banTit fW-R'>试一试 Prepaid 吧</span>",
            t01_08: "注册申请",
            t01_09: "话费套餐",
            t01_10: "<span class='conTxt fW-R wh'>延期/充值方法</span><span class='conTxt fW-R wh nextLine'>无论何时何地 ,只要您需要,就可以随时延期/充值</span>",
            t01_11: "登录主页",
            t01_12: "用户信息确认",
            t01_13: "选择支付方法",
            t01_14: "延期完成",
            t01_15: "跳转至延期/ 充值 ",
            t01_16: "最佳",
            t01_17: "大容量",
            t01_18: "实惠型",
            t01_19: "长期折扣",
            t01_20: "越用越优惠的费率，请提前确认。",
            t01_21: "折扣",
            t01_22: "额外折扣",
            t01_23: "自动支付折扣",
            t01_24: "重复折扣",
            t01_25: "流量",
            t01_26: "通话",
            t01_27: "短信",
            t01_28: "立即申请",
            t01_29: "查看详情",
            t01_30: "无需本人认证，任何人都可以申请！ Dosirak Mobile预付费制",
            t01_31: "查看全部",
            t01_32: "附加服务",
            t01_33: "客服中心",
            t01_34: "运营时间 09:00 ~ 18:00(午休时间 12:00 ~ 13:00) ",
            t01_35: "公告栏",
            t01_36: "FAQ",
            t01_37: "公告事项",
            t01_38: "<span class='banTit fW-R'>需要预付费电话卡吗？</span><span class='banTit fW-R'>从仁川机场入境吗？</span><span class='banTit fW-R'>可以在机场直接开通啦！</span><span class='banTit fW-R'>申请 ></span>",
            t02_01: "Dosirak Mobile资费制",
            t02_02: "选择您的使用模式，我们会给您推荐合适的资费方案",
            t02_03: "月付金额",
            t02_04: "整体",
            t02_05: "流量使用量",
            t02_06: "通话使用量",
            t02_07: "※预付费商品。(含税)",
            t02_08: "按推荐顺序",
            t02_09: "按价格从低到高",
            t02_10: "按价格从高到低",
            t02_11: "使用折扣时请先确认月付金额。",
            t02_12: "每月基本费用",
            t03_01: "资费详情",
            t03_02: "资费制度",
            t03_03: "每月基本费用",
            t03_04: "流量",
            t03_05: "通话",
            t03_06: "短信",
            t03_07: "作为一个有基本流量的包月套餐，从购买日期开始按日计算提供流量。<br/>根据使用资费，除基本费用之外，追加收取额外金额。",
            t03_08: "基本介绍",
            t03_09: "折扣介绍",
            t03_10: "超额费率",
            t03_11: "充值指南",
            t03_12: "其他注意事项",
            t03_13: "注册申请",
            t04_01: "注册申请",
            t04_02: "您输入的信息将用于签约及开通服务，请准确输入。",
            t04_03: "注册类型",
            t04_04: "<em class='mgl-50 fW-R'>号码迁移</em><em class='mgl-50 fW-L'>使用的号码不变，仅运营商变更(领取后申请开通)</em>",
            t04_05: "<em class='mgl-50 fW-R'>新注册</em><em class='mgl-50 fW-L'>申请新号码</em>",
            t04_06: "资费制度",
            t04_07: "请选择一个资费套餐。",
            t04_08: "选择资费标准",
            t04_09: "附加业务(选择)",
            t04_10: "拦截垃圾邮件<br/>拦截垃圾邮件的服务",
            t04_11: "来电显示<br/>显示来电者电话号码的服务",
            t04_12: "服务条款",
            t04_13: "我同意所有条款和条件。",
            t04_14: "（必填）服务条款",
            t04_15: "（必填）个人信息的收集和使用信息",
            t04_16: "（可选）同意接收定制的优惠信息和广告",
            t05_01: "确认申请明细",
            t05_02: "注册的产品",
            t05_03: "注册的类型",
            t05_04: "SIM类型",
            t05_05: "资费制度",
            t05_06: "预计每月交纳金额",
            t05_07: "其他费用(第一次缴费时1次)",
            t05_08: "总付款金额",
            t05_09: "<span class='nt_Tit'>※请务必确认。</span><span class='nt_Txt'>您选择的资费制是预付型资费制， 使用 LGU+ 通信网络。</span><span class='nt_Txt'>若有SIM费时, 将计入首次费用结算。</span>",
            t05_10: "<div style='font-size:16px; color:red; text-align:left; font-weight:bold;'>有运营商欠费吗?</div><div style='text-align:left;'>若存在拖欠运营商费用时则不能加入或开通，请付清后再使用。</div>",
            t05_11: "选择完成",
            t06_01: "<h1>申请表</h1><br/><span class='subTit fW-L'>Dosirak Mobile</span>",
            t06_02: "总结算金额（包括VAT）",
            t06_03: "STEP1 认证",
            t06_04: "韩国人（19周岁以上）",
            t06_05: "外国人（19周岁以上）",
            t06_06: "实名认证",
            t06_07: "姓名",
            t06_08: "居民身份证",
            t06_09: "驾驶证",
            t06_10: "输入全部身份证号",
            t06_11: "国籍",
            t06_12: "护照",
            t06_13: "输入护照号码",
            t06_14: "外国人登录证",
            t06_15: "输入外国人登录编号",
            t06_16: "<span class='liDesc'>请单击此处在方框中上<br/><b>传所选的身份证照片。</b></span>",
            t06_17: "<span class='liDesc'>请单击此处在方框中上<br/><b>传您的面部照片。</b></span>",
            t06_18: "上传身份证确认注意事项",
            t06_19: "上传护照确认注意事项",
            t06_20: "国内人士(未满19周岁)立即加入",
            t06_21: "请一定要把身份证这样上传!",
            t06_22: "添加身份证",
            t06_23: "拍摄本人面部",
            t06_24: "<span class='nt_Txt'>请正面拍摄。</span><span class='nt_Txt'>身份证必须是原版照片。</span><span class='nt_Txt'>请确保清楚拍摄身份证内容。</span>",
            t06_25: "确认",
            t06_26: "上一页",
            t06_27: "下一页",
            t06_28: "申请表",
            t07_01: "STEP2 注册信息",
            t07_02: "新注册信息",
            t07_03: "希望号码（后4位数）",
            t07_04: "第一希望",
            t07_05: "第二希望",
            t07_06: "※如果没有本人希望的号码，会随机指定尽可能接近的号码开通。",
            t07_07: "号码移动信息",
            t07_08: "当前运营商",
            t07_09: "SKT",
            t07_10: "KT",
            t07_11: "LGU+",
            t07_12: "MVNO",
            t07_13: "手机号码",
            t07_14: "认证方法",
            t07_15: "信用卡",
            t07_16: "银行账号",
            t07_17: "转账缴纳",
            t07_18: "后4位输入",
            t07_19: "※输入目前使用的手机付费信息",
            t07_20: "手机序列号",
            t07_21: "<span class='inputNotice'>※ 如何查看手机序列号</span><br/><span class='inputNotice'>○ 安卓（三星、LG 等）</span><br/><span class='inputNotice'>1.设置>设备信息>状态>序列号检查</span><br/><span class='inputNotice'>2.设置>关于手机>监管信息>检查序列号</span><br/><span class='inputNotice'>○ iPhone：设置 > 通用 > 序列号</span><span class='inputNotice'>如果登记在运营商处的手机序列号与当前使用的手机序列号不同，则可能无法进行身份验证。</span>",
            t07_22: "商家信息",
            t07_23: "现有手机信息",
            t07_24: "类型选择",
            t07_25: "lgu +",
            t07_26: "kt / skt",
            t07_27: "自给制手机",
            t07_28: "外国手机",
            t07_29: "无法确认",
            t07_30: "输入手机型号",
            t07_31: "<span class='inputNotice'>※ 手机上市日期在2015年之后的机型（例如Galaxy Note 5、iPhone 6s）兼容SIM卡，</span><span class='inputNotice'>该日期之前的机型仅兼容LGU+的LTE设备。</span><span class='inputNotice'>※ 如果手机型号信息不正确，可能无法正常操作。</span><span class='inputNotice'>※ 海外购买的手机即使是韩国厂商也不兼容（NEXUS X5除外）</span>",
            t07_32: "用户信息",
            t07_33: "输入手机号码",
            t07_34: "电子邮件",
            t07_35: "<span class='inputNotice'>※所输入的信息将用于申请详细查询、配送指南等必需环节，请准确输入。</span>",
            t07_36: "配送信息",
            t07_37: "与用户信息相同",
            t07_38: "上门服务",
            t07_39: "开始配送",
            t07_40: "到达目的地",
            t07_41: "确认身份证",
            t07_42: "顾客领取",
            t07_43: "<span class='noticeTxt'>※ 上门服务免费。</span><span class='noticeTxt'>※ 上门服务，送货司机会亲自上门拜访并确认您的身份，然后进行交付。</span><span class='noticeTxt'>※ 领取SIM卡时，请务必出示身份证件。</span><span class='noticeTxt'>※ 请注意，配送如果出现突发情况，配送可能会延迟。</span><span class='noticeTxt'>※ 申请号码迁移的客户请在收到号码后通过网站提出开通请求。</span><span class='noticeTxt'>※ SIM卡将激活后发送，上门服务需要3-5个工作日送达。 （周末/节假日不发货）</span><span class='noticeTxt'>※ 因您自身原因未能收到物品时，实际使用天数会缩短。 (※在新开通的情况下)</span><span class='noticeTxt'>※ 如果您在交货时不在，该物品可能会被退回。如遇延迟收货，请务必联系客服中心。如果您在申请时没有留下韩国联系电话，我们将通过提供的电子邮件单独联系您。</span>",
            t07_44: "请输入收件人姓名",
            t07_45: "输入手机号码",
            t07_46: "电子邮件",
            t07_47: "邮政编码",
            t07_48: "搜索邮编",
            t07_49: "默认地址",
            t07_50: "请输入您的详细地址",
            t07_51: "※无法配送的地区无法使用。",
            t07_52: "首尔客服中心",
            t07_53: "<span class='noticeTxt'>※可直接到首尔总公司办公室领取，无需配送。</span><span class='noticeTxt'>※位置:首尔市西大门区统一路135忠正大厦6楼</span><span class='noticeTxt'>※营业时间:09:00 ~ 18:00(午餐时间12:00 ~ 13:00)</span><span class='noticeTxt'>※周末/公休日不营业</span>",
            t08_01: "STEP3 付款",
            t08_02: "支付信息",
            t08_03: "注册完成后付款",
            t08_04: "<span class='inputNotice bk'>※ 您可以根据注册前/后的支付方式选择支付方式。</span><span class='inputNotice bk'>※ 在“注册完成后付款”中，在完成注册申请表后选择的付款方式完成付款后，注册申请将被批准。</span>",
            t08_05: "虚拟账户",
            t08_06: "银行选择",
            t08_07: "您可以在注册表完成页面上查看虚拟帐号。",
            t08_08: "便利店支付",
            t08_09: "<span class='noticeTxt'>※ 注册申请书填写完毕后，将收到短信发送的收款号码。</span><span class='noticeTxt'>※ 请在附近的便利店用现金结算。 （请给便利店职员看一下收款号码）</span><span class='noticeTxt'>※ 全国GS25、7-11、CU、C-SPACE卖场均可进行结算。</span><span class='noticeTxt'>※ 填写申请书后7日内完成支付，才能完成注册申请。</span>",
            t08_10: "即时付款",
            t08_11: "信用卡/借记卡",
            t08_12: "<span class='inputNote pay'>※ 仅限会员名下的卡/账户使用。</span>",
            t08_13: "卡片信息",
            t08_14: "国内卡",
            t08_15: "海外卡",
            t08_16: "卡号",
            t08_17: "出生日期",
            t08_18: "有效期  年",
            t08_19: "有效期  月",
            t08_20: "验证",
            t08_21: "银行转帐",
            t08_22: "帐户信息",
            t08_23: "银行选择",
            t08_24: "账号",
            t08_25: "Kakao支付",
            t08_26: "自动付款<em class='nextLine'>服务</em>",
            t08_27: "申请",
            t08_28: "<span class='noticeTxt'>※ 基本费用在使用结束前3天自动结算。</span><span class='noticeTxt'>※ 申请后的次月（第2次）自动结算部分可享受折扣。</span><span class='noticeTxt'>※ 可同时享受长期使用折扣和自动结算折扣。（自动应用）</span>",
            t08_29: "本人同意本申请书合同项下的缴费金额，于上述账户(卡)指定的支取日自动支取(结算), 无需另行通知。",
            t08_30: "未申请",
            t09_01: "您的注册申请已收到。",
            t09_02: "申请详情",
            t09_03: "注册类型",
            t09_04: "资费制度",
            t09_05: "sim 类型",
            t09_06: "附加服务",
            t09_07: "总付款金额",
            t09_08: "选择付款方式",
            t09_09: "自动付款",
            t09_10: "下载申请文件",
            t09_11: "※ 注册申请表已根据申请表中提供的信息填写完毕。<br/>※可参考如下进行查看和下载。",
            t09_12: "无线服务合同标准指南",
            t09_13: "网上申请表",
            t10_01: "服务条款",
            t10_02: "隐私政策",
            t10_03: "(株)WideMobile  代表:金万中 首尔市西大门区统一路135忠正大厦6楼<br/>客服中心:1566-9071 营业执照号:121-81-66458<br/>通信销售业编号:2018-首尔西大门-0301号",
            t11_01: "预计每月交纳额",
            t11_02: "月基本费用",
            t11_03: "附加服务",
            t11_04: "话费折扣",
            t11_05: "其他费用(首次)",
            t11_06: "SIM费",
            t11_07: "配送费",
            t11_08: "有条件的免费",
            t11_09: "折扣金额",
            t11_10: "自动支付折扣",
            t11_11: "长期使用折扣",
            t12_01: "登录",
            t12_02: "可享受费用及使用量查询等更加便利的服务。",
            t12_03: "号码验证登录",
            t12_04: "请输入姓名",
            t12_05: "开通号码",
            t12_06: "验证码发送",
            t12_07: "验证码确认",
            t12_08: "隐私声明",
            t12_09: "同意个人信息收集。",
            t12_10: "会员登录",
            t12_11: "保持登录状态",
            t12_12: "返回主页",
            t12_13: "SNS登录",
            t12_14: "查找用户名",
            t12_15: "找回密码",
            t12_16: "注册会员",
            t12_17: "Login",
            t13_01: "查找用户名/密码",
            t13_02: "需要手机验证以查找用户名和密码。",
            t13_03: "查找用户名",
            t13_04: "请点击手机验证按钮进行本人认证。",
            t13_05: "手机验证",
            t13_06: "取消",
            t13_07: "查找密码",
            t13_08: "确认用户名及本人认证",
            t13_09: "用户名( 邮件)",
            t13_10: "手机验证",
            t14_01: "查询申请详情",
            t14_02: "可以查看注册申请的详细情况和配送状态。",
            t14_03: "非会员申请查询",
            t14_04: "订单号码",
            t14_05: "注册会员名",
            t14_06: "会员申请查询",
            t15_01: "可以查询申请明细。",
            t15_02: "一个月",
            t15_03: "三个月",
            t15_04: "六个月",
            t15_05: "查询",
            t15_06: "申请日",
            t15_07: "订单号码",
            t15_08: "注册类型",
            t15_09: "申请资费类型",
            t15_10: "状态",
            t15_11: "配送",
            t15_12: "取消/退货",
            t15_13: "查看更多",
            t16_01: "我的页面",
            t16_02: "可以编辑我的信息、查看注册信息和附加服务。",
            t16_03: "会员信息",
            t16_04: "ID",
            t16_05: "手机号码",
            t16_06: "修改网站会员信息",
            t16_07: "注册信息",
            t16_08: "选择",
            t16_09: "查询",
            t17_01: "注册信息",
            t17_02: "轻松查询资费信息。",
            t17_03: "用户信息",
            t17_04: "资费名称",
            t17_05: "开通日期",
            t17_06: "使用期间",
            t17_07: "资费期满日",
            t17_08: "追加充值余额",
            t17_09: "自动结算状态",
            t17_10: "申请",
            t17_11: "延长使用/立即充值",
            t18_01: "修改会员信息",
            t18_02: "可以修改Dosirak Mobile 会员注册信息。",
            t18_03: "ID",
            t18_04: "姓名",
            t18_05: "手机号码",
            t18_06: "变更",
            t18_07: "出生日期",
            t18_08: "密码",
            t18_09: "性别",
            t18_10: "男",
            t18_11: "女",
            t18_12: "可选信息（同意接收）",
            t18_13: "电子邮件",
            t18_14: "SMS",
            t18_15: "SNS登录链接",
            t18_16: "Facebook",
            t18_17: "Kakao Talk",
            t18_18: "NAVER",
            t18_19: "注销会员",
            t18_20: "信息修改完毕",
            t19_01: "手机号码变更指南",
            t19_02: "<span class='noteTxt'>输入要在登录ID 中使用的新手机号码。</span><span class='noteTxt'>更改手机号码时，Wi-Fi Dosirak 和 Dosirak USIM 网站 ID 的手机号码也会更改。</span>",
            t19_03: "输入新的手机号码",
            t19_04: "认证号码输入",
            t19_05: "发送验证码",
            t19_06: "无法确认",
            t19_07: "更改手机号码",
            t19_08: "取消",
            t20_01: "密码修改指南",
            t20_02: "<span class='noteTxt'>在登录ID 中输入要使用的新密码。</span><span class='noteTxt'>更改密码时，Wi-Fi Dosirak 和 Dosirak USIM 网站 ID 的密码也会更改。</span>",
            t20_03: "输入当前密码",
            t20_04: "输入新密码",
            t20_05: "确认新密码",
            t20_06: "更改密码",
            t21_01: "使用条款/隐私政策",
            t21_02: "可以查看网站和服务使用条款和隐私政策。",
            t21_03: "Dosirak Mobile 预付条款和条件",
            t21_04: "Wide Mobile 综合会员条款",
            t21_05: "隐私政策",
            t21_06: "使用条款 文档下载",
            t21_07: "包括VAT",
            t21_08: "配送查询",
            t21_09: "申请",
            t21_10: "使用申请期间 ",      
            t21_11: "周期",
            t21_12: "贴现",
            t21_13: "天",
            t21_14: "选择使用期限",
            t21_15: "KRW",
            t21_16: "支付金额",
            t21_17: "基本利率",
            t21_18: "使用天数",
            t21_19: "选择60天以上（立即折扣），选择120天以上（追加折扣)",
            t21_20: "US army ID",
            t21_21: "永久居民卡",
            t21_22: "海外韩国居留卡",            
            t21_23: "Please enter your DOD number",
            t21_24: "请输入您的卡号",
            t21_25: "海外韩国居留卡",      
            t21_26: "（必填） 同意收集和使用唯一身份信息",
            t21_27: "（必填） 同意向第三方提供个人信息",
            t21_28: "（必填） 同意将个人信息委托给第三方",
            t21_29: "（必填） 同意个人征信信息查询",                         
            t99_99: "Dosirak Mobile",

        }
    },
    vi: {
        trans: {
            t01_01: "Phương thức Gia hạn/ Nạp tiền",
            t01_02: "Trung tâm chăm sóc khách hàng",
            t01_03: "Đăng nhập",
            t01_04: "Trang chủ",
            t01_05: "Xem lại thông<br/>tin đăng kí",
            t01_06: "<span class='banTit fW-R'>Chỉ cần là công dân trên thế giới này,</span><span class='banTit fW-R'>Đều có thể đăng kí sử dụng SIM trả trước của</span><span class='banTit fW-R'>Dosirak Mobile!</span>",
            t01_07: "<span class='banTit fW-R'>Dù chỉ gia hạn 1 lần,</span><span class='banTit fW-R'>vẫn nhận ngay 10,000 won!</span><span class='banTit fW-R'>Cước phí SIM trả trước rẻ nhất<br/>Chần chừ chi mà không sử dụng Dosirak Prepaid</span>",
            t01_08: "Đăng kí gia nhập",
            t01_09: "Cước phí",
            t01_10: "<span class='conTxt fW-R wh'>Hướng dẫn Gia hạn/ Nạp tiền</span><span class='conTxt fW-R wh nextLine'>Bất cứ khi nào, bất cứ đâu</span><span class='conTxt fW-R wh nextLine'>Đều dễ dàng Gia hạn/ Nạp tiền</span>",
            t01_11: "Đăng nhập vào<br/>trang chủ",
            t01_12: "Xác nhận thông</br>tin gia nhập",
            t01_13: "Lựa chọn phương</br>thức thanh toán",
            t01_14: "Nạp tiền thành công",
            t01_15: "Gia hạn/ Nạp tiền ngay",
            t01_16: "Cước phí tốt nhất",
            t01_17: "Cước phí dung lượng",
            t01_18: "Cước phí cấu hình",
            t01_19: "Ưu đãi lâu dài",
            t01_20: "Dùng càng lâu, giá thành càng rẻ, đừng quên check nhé!",
            t01_21: "Ưu đãi",
            t01_22: "Khuyến mãi thêm",
            t01_23: "Khuyến mãi tự động thanh toán",
            t01_24: "Nhiều ưu đãi",
            t01_25: "Dung lượng ",
            t01_26: "Cuộc gọi",
            t01_27: "Tin nhắn",
            t01_28: "Chi tiết",
            t01_29: "Đăng kí",
            t01_30: "Không cần xác nhận, ai cũng có thể đăng kí! Gói trả trước của Dosirak Mobile",
            t01_31: "Xem toàn bộ",
            t01_32: "Dịch vụ gia tăng",
            t01_33: "Trung tâm chăm sóc khách hàng",
            t01_34: "Thời gian làm việc 09:00 ~ 18:00 (Nghỉ trưa 12:00 ~ 13:00) ",
            t01_35: "Bảng tin",
            t01_36: "FAQ",
            t01_37: "Thông báo ",
            t01_38: "<span class='banTit fW-R'>Bạn muốn sử dụng cước điện thoại trả trước?</span><span class='banTit fW-R'>Bạn sẽ nhập cảnh tới sân bay Incheon?</span><span class='banTit fW-R'>Đăng kí dễ dàng, ngay tại sân bay!</span><span class='banTit fW-R'>Đăng kí ></span>",
            t02_01: "Chi phí cước của Dosirak Mobile",
            t02_02: "Đề xuất các gói cước phù hợp, tùy theo yêu cầu sử dụng ",
            t02_03: "Cước phí chi trả mỗi tháng",
            t02_04: "Toàn bộ",
            t02_05: "Dung lượng sử dụng",
            t02_06: "Cước cuộc gọi sử dụng",
            t02_07: "※ Sản phẩm SIM trả trước. (Đã bao gồm VAT)",
            t02_08: "Thứ tự đề xuất",
            t02_09: "Giá thấp nhất",
            t02_10: "Giá cao nhất",
            t02_11: "Đừng quên kiểm tra số tiền thanh toán khi áp dụng giảm giá nhé.",
            t02_12: "Chi phí căn bản mỗi tháng",
            t03_01: "Chi tiết cước phí",
            t03_02: "Cước phí",
            t03_03: "Chi phí căn bản mỗi tháng",
            t03_04: "Dung lượng",
            t03_05: "Cuộc gọi",
            t03_06: "Tin nhắn",
            t03_07: "Chúng tôi tính toán và cung cấp dung lượng dựa trên tiêu chuẩn của sản phẩm với mức cước được đăng kí ban đầu.<br/>Tùy theo nhu cầu sử dụng mà sẽ có sự phát sinh về chi phí ngoài cước.",
            t03_08: "Hướng dẫn cơ bản",
            t03_09: "Hướng dẫn giảm giá",
            t03_10: "Chi phí phát sinh ",
            t03_11: "Hướng dẫn nạp tiền",
            t03_12: "Các trường hợp lưu ý",
            t03_13: "Đăng kí gia nhập",
            t04_01: "Đăng kí gia nhập",
            t04_02: "Thông tin bạn nhập sẽ dùng cho việc kí kết và mở mạng.Vui lòng nhập thông tin một cách chính xác",
            t04_03: "Loại hình gia nhập",
            t04_04: "<em class='mgl-50 fW-R'>Thay đổi số liên lạc</em><em class='mgl-50 fW-L'>Chỉ thay đổi nhà mạng, giữ nguyên số điện thoại đang dùng (Đăng kí mở số sau khi nhận)</em>",
            t04_05: "<em class='mgl-50 fW-R'>Đăng kí mới</em><em class='mgl-50 fW-L'>Đăng kí gia nhập bằng số điện thoại mới</span>",
            t04_06: "Cước phí",
            t04_07: "Vui lòng chọn loại cước phí",
            t04_08: "Chọn cước phí",
            t04_09: "Dịch vụ gia tăng (Lựa chọn)",
            t04_10: "Chặn tin nhắn rác<br/>Dịch vụ chặn tin nhắn rác",
            t04_11: "Hiện số điện thoại gọi<br/>Dịch vụ hiện số điện thoại người gọi",
            t04_12: "Điều khoản",
            t04_13: "Đồng ý với tất cả điều khoản",
            t04_14: "(Bắt buộc) Điều khoản sử dụng dịch vụ",
            t04_15: "(Bắt buộc) Thu thập thông tin & Hướng dẫn sử dụng",
            t04_16: "(Lựa chọn) Đồng ý nhận tin ưu đãi & quảng cáo",
            t05_01: "Xác nhận đăng kí",
            t05_02: "Sản phẩm đăng kí",
            t05_03: "Loại hình đăng kí",
            t05_04: "Loại SIM",
            t05_05: "Cước phí",
            t05_06: "Chi phí mỗi tháng tạm tính",
            t05_07: "Chi phí khác (Lần đầu tiên)",
            t05_08: "Tổng chi phí",
            t05_09: "<span class='nt_Tit'>※ Vui lòng kiểm tra kĩ.</span><span class='nt_Txt'>Cước bạn chọn là cước phí trả trước của nhà mạng LG U +</span><span class='nt_Txt'>Trường hợp có SIM tính phí, phí sẽ được tính vào lần thanh toán đầu tiên.</span>",
            t05_10: "<div style='font-size:16px; color:red; text-align:left; font-weight:bold;'>Bạn có đang nợ cước của nhà mạng không?</div><div style='text-align:left;'>Trường hợp bạn đang nợ cước, bạn không thể đăng kí cũng như sử dụng dịch vụ.<br/>Vui lòng sử dụng dịch vụ sau khi đã thanh toán.</div>",
            t05_11: "Hoàn tất lựa chọn",
            t06_01: "<h1>Đơn đăng kí gia nhập</h1><br/><span class='subTit fW-L'>Dosirak Mobile</span>",
            t06_02: "Tổng thanh toán (Bao gồm VAT)",
            t06_03: "Bước 1 Xác nhận",
            t06_04: "Người Hàn Quốc ( Trên 19 tuổi)",
            t06_05: "Người Nước ngoài (Trên 19 tuổi)",
            t06_06: "Xác nhận danh tính",
            t06_07: "Tên",
            t06_08: "Thẻ căn cước công dân",
            t06_09: "Bằng lái xe",
            t06_10: "Nhập toàn bộ số căn cước công dân",
            t06_11: "Quốc tịch",
            t06_12: "Số hộ chiếu",
            t06_13: "Nhập số hộ chiếu",
            t06_14: "Thẻ đăng kí người nước ngoài",
            t06_15: "Nhập số thẻ đăng kí người nước ngoài",
            t06_16: "<span class='liDesc'>Bấm vào hình chữ<br/>nhật để <b>đính kèm ảnh chứng minh</b></span>",
            t06_17: "<span class='liDesc'>Bấm vào hình chữ nhật để <b>đính kèm ảnh có<br/>nhận diện gương mặt.</b></span>",
            t06_18: "Xác nhận các lưu ý về thẻ chứng minh",
            t06_19: "Xác nhận các lưu ý về hộ chiếu",
            t06_20: "Đăng kí ngay, dành cho người Hàn Quốc (Trên 19 tuổi)",
            t06_21: "Vui lòng đính kèm thẻ chứng minh như sau!",
            t06_22: "Đính kèm thẻ chứng minh",
            t06_23: "Chụp ảnh gương mặt",
            t06_24: "<span class='nt_Txt'>Vui lòng chụp góc chính diện</span><span class='nt_Txt'>Chỉ chấp nhận bản gốc của thẻ chứng minh</span><span class='nt_Txt'>Vui lòng chụp rõ thẻ chứng minh để xác minh chính xác nội dung trên thẻ</span>",
            t06_25: "Xác nhận",
            t06_26: "Trước",
            t06_27: "Sau",
            t06_28: "Đơn đăng kí gia nhập",
            t07_01: "Bước 2 Thông tin đăng kí",
            t07_02: "Đăng kí mới",
            t07_03: "Số điện thoại mong muốn (4 số cuối)",
            t07_04: "Số mong muốn 1",
            t07_05: "Số mong muốn 2",
            t07_06: "※ Trường hợp không có yêu cầu về số điện thoại, chúng tôi sẽ lựa chọn ngẫu nhiên",
            t07_07: "Thông tin thay đổi số điện thoại",
            t07_08: "Nhà mạng đang sử dụng",
            t07_09: "SKT",
            t07_10: "KT",
            t07_11: "LGU+",
            t07_12: "MVNO",
            t07_13: "Số điện thoại ",
            t07_14: "Phương thức xác nhận",
            t07_15: "Thẻ tín dụng",
            t07_16: "Tài khoản ngân hàng",
            t07_17: "Chuyển khoản",
            t07_18: "Nhập 4 số cuối",
            t07_19: "※ Nhập thông tin thanh toán cước của điện thoại đang dùng",
            t07_20: "Đổi số điện thoại",
            t07_21: "<span class='inputNotice'>※ Cách xác nhận thay đổi số điện thoại</span><br/><span class='inputNotice'>* Androi (Samsung, LG, vv)</span><br/><span class='inputNotice'>1. Thiết lập > Thông tin thiết bị kĩ thuật > Trạng thái -> Xác nhận thay đổi số điện thoại</span><br/><span class='inputNotice'>2. Thiết lập > Thông tin điện thoại -> Thông tin quy định > Xác nhận thay đổi số điện thoại</span><br/><span class='inputNotice'>* Iphone: Thiết lập > Tổng quát > Thay đổi số</span><span class='inputNotice'>Không thể cấp chứng nhận trong trường hợp thay đổi số điện thoại đang sử dụng không khớp với số điện thoại với nhà mạng được đăng kí</span>",
            t07_22: "Thông tin doanh nghiệp",
            t07_23: "Số điện thoại đang sử dụng ",
            t07_24: "Lựa chọn nhà mạng",
            t07_25: "LGU+",
            t07_26: "KT/SKT",
            t07_27: "Điện thoại không trả góp",
            t07_28: "Điện thoại quốc tế",
            t07_29: "Không thể xác minh",
            t07_30: "Loại điện thoại",
            t07_31: "<span class='inputNotice'>※ Các mẫu điện thoại ra mắt sau năm 2015 (ví dụ: Galaxy Note 5, iPhone 6s) sẽ tương thích với cấu hình SIM và các mẫu ra mắt trước đó sẽ tương thích với thay đổi bằng LTE của LGU +.</span><span class='inputNotice'>※ Trường hợp thông tin điện thoại không chính xác, có thể gặp vấn đề trong quá trình mở mạng</span><span class='inputNotice'>※ Với các điện thoại mua từ nước ngoài cũng sẽ có trường hợp không tương thích dù cho linh kiện được sản xuất tại Hàn Quốc. (Ngoại trừ NEXUS X5)</span>",
            t07_32: "Thông tin gia nhập",
            t07_33: "Nhập số điện thoại",
            t07_34: "Email",
            t07_35: "<span class='inputNotice'>Vui lòng nhập chính xác thông tin, vì thông tin này sẽ được sử dụng cho</span><span class='inputNotice'>mục đích cần thiết như truy vấn lịch sử đăng kí và thông báo giao hàng.</span>",
            t07_36: "Địa chỉ giao hàng",
            t07_37: "Đồng nhất thông tin với người đăng kí",
            t07_38: "Dịch vụ giao hàng",
            t07_39: "Bắt đầu giao hàng",
            t07_40: "Đến nơi giao hàng",
            t07_41: "Xác nhận danh tính",
            t07_42: "Giao hàng",
            t07_43: "<span class='noticeTxt'>※ Không mất phí vận chuyển.</span><span class='noticeTxt'>※ SIM sẽ được vận chuyển đến khách hàng sau khi người vận chuyển xác nhận thành công danh tính.</span><span class='noticeTxt'>※ Vui lòng đem theo thẻ căn cước công dân, hộ chiếu khi nhận SIM</span><span class='noticeTxt'>※ Trường hợp thay đổi số điện thoại, khách hàng vui lòng yêu cầu đăng kí SIM trên trang chủ.</span><span class='noticeTxt'>※ Trường hợp không nhận sản phẩm vì lý do từ phía khách hàng, thời gian sử dụng thực tế có thể bị rút ngắn.</span><span class='noticeTxt'>※ SIM sẽ được gửi sau khi kích hoạt và dịch vụ tận nơi sẽ mất 3-5 ngày làm việc để nhận. (Không có giao hàng cuối tuần / ngày lễ) </span><span class='noticeTxt'>※ Nếu bạn không nhận được hàng vì những lý do liên quan đến bạn, số ngày sử dụng thực tế có thể được rút ngắn. (※ trong trường hợp đăng ký mới) </span><span class='noticeTxt'>※ Nếu bạn vắng mặt tại thời điểm giao hàng, hàng có thể được trả lại. Trong trường hợp nhận hàng chậm trễ, vui lòng liên hệ với trung tâm dịch vụ khách hàng. Nếu bạn không có số điện thoại liên lạc của Hàn Quốc tại thời điểm nộp đơn, bạn sẽ được liên hệ riêng thông qua địa chỉ email được cung cấp.</span>",
            t07_44: "Tên người nhận",
            t07_45: "Số điện thoại",
            t07_46: "Email",
            t07_47: "Zip Code",
            t07_48: "Tìm Zip Code",
            t07_49: "Địa chỉ",
            t07_50: "Địa chỉ chi tiết",
            t07_51: "※ Không thể sử dụng dịch vụ với những khu vực không cho phép giao nhận.",
            t07_52: "Trung tâm chăm sóc khách hàng ở Seoul",
            t07_53: "<span class='noticeTxt'>※ Trường hợp không dùng dịch vụ giao hàng, có thể nhận SIM trực tiếp tại văn phòng chính ở Seoul</span><span class='noticeTxt'>※ Địa chỉ : Tầng 6, Tòa nhà Chungjeong, 135, Tongil-ro, Seodaemun-gu, Seoul, Hàn Quốc</span><span class='noticeTxt'>※ Thời gian làm việc : 9:00 ~ 18:00 (Nghỉ trưa 12:00 ~ 13:00)</span><span class='noticeTxt'>※ Không làm việc vào cuối tuần/ ngày lễ</span>",
            t08_01: "Bước 3 Thanh toán",
            t08_02: "Thông tin thanh toán",
            t08_03: "Thanh toán sau khi hoàn tất đăng ký",
            t08_04: "<span class='inputNotice bk'>※ Bạn có thể chọn phương thức thanh toán tùy thuộc vào lựa chọn thanh toán trước / sau khi hoàn tất đăng ký.</span><span class='inputNotice bk'>※ Với Thanh toán sau khi gia nhập, sau khi hoàn thành điền đầy đủ thông tin gia nhập, lựa chọn phương thức thanh toán, thông tin sẽ được tự động đăng kí sau khi thanh toán hoàn tất.</span>",
            t08_05: "Tài khoản thanh toán",
            t08_06: "Ngân hàng",
            t08_07: "Có thể xác nhận tài khoản thanh toán trên trang chủ sau khi hoàn tất điền đơn đăng kí",
            t08_08: "Thanh toán tại cửa hàng tiện lợi",
            t08_09: "<span class='noticeTxt'>※ Sau khi hoàn tất điền đơn đăng kí, số biên nhận sẽ gửi thông qua tin nhắn.</span><span class='noticeTxt'>※ Vui lòng thanh toán tiền mặt tại cửa hàng tiện lợi gần nhất (Đưa số biên nhận cho nhân viên cửa hàng)</span><span class='noticeTxt'>※ Có thể thanh toán tại tất cả các cửa hàng tiện lợi GS25, Seven Eleven, CU, S-SPACE trên toàn quốc.</span><span class='noticeTxt'>※ Vui lòng thanh toán trong vòng 7 ngày kể từ khi đăng kí, sau khi thanh toán thì đăng kí mới được công nhận.</span>",
            t08_10: "Thanh toán ngay",
            t08_11: "Thẻ tín dụng",
            t08_12: "<span class='inputNote pay'>※ Tên trên thẻ/ tài khoản phải đồng nhất với tên người đăng kí</span>",
            t08_13: "Thông tin thẻ",
            t08_14: "Thẻ trong nước",
            t08_15: "Thẻ quốc tế",
            t08_16: "Số thẻ",
            t08_17: "Ngày tháng năm sinh",
            t08_18: "Thời hạn thẻ (năm)",
            t08_19: "Thời hạn thẻ (tháng)",
            t08_20: "Kiểm tra tính hiệu lực",
            t08_21: "Chuyển khoản",
            t08_22: "Thông tin tài khoản",
            t08_23: "Ngân hàng",
            t08_24: "Số tài khoản",
            t08_25: "Kakao Pay",
            t08_26: "Dịch vụ thanh toán <em class='nextLine'>tự động</em>",
            t08_27: "Đăng kí",
            t08_28: "<span class='noticeTxt'>※ Phí cơ bản sẽ tự động thanh toán khi kết thúc sử dụng trước 3 ngày.</span><span class='noticeTxt'>※ Áp dụng chiết khấu khi tự động thanh toán tháng tiếp theo (lần 2) sau khi đăng kí.</span><span class='noticeTxt'>※ Có thể nhận ưu đãi dài hạn và giảm giá cùng lúc. (Áp dụng tự động.)</span>",
            t08_29: "Tôi đồng ý với chi phí thanh toán có trong hợp đồng chi tiết của dịch vụ sẽ được trừ tiền (thanh toán) tự động từ tài khoản (thẻ) trên vào ngày thanh toán quy định mà không cần thông báo.",
            t08_30: "Không đăng kí ",
            t09_01: "Đăng kí đã được tiếp nhận",
            t09_02: "Lịch sử đăng kí",
            t09_03: "Loại hình đăng kí",
            t09_04: "Cước phí",
            t09_05: "Loại SIM",
            t09_06: "Dịch vụ giá trị gia tăng",
            t09_07: "Tổng số tiền thanh toán",
            t09_08: "Phương thức thanh toán",
            t09_09: "Thanh toán tự động",
            t09_10: "Tải về thông tin đăng kí",
            t09_11: "※ Bạn đã hoàn thành đơn đăng ký với thông tin bạn đã cung cấp.<br/>※ Bạn có thể kiểm tra và tải đơn xuống ở phía bên dưới.",
            t09_12: "Thông báo tiêu chuẩn hợp đồng dịch vụ không dây",
            t09_13: "Đăng kí trực tuyến",
            t10_01: "Điều khoản sử dụng",
            t10_02: "Chính sách bảo mật",
            t10_03: "Công ty Trách nhiệm Hữu hạn Wide Mobile ｜ Giám đốc : Kim Man-jung ｜Tầng 6, Tòa nhà Chungjeong, 135, Tongil-ro,<br/>Seodaemun-gu, Seoul<br/>CS : 1566-9071 ｜ Mã số doanh nghiệp : 121-81-66458<br/>Mã số báo cáo doanh nghiệp : Số 2018-Seoul Seodaemun-0301",
            t11_01: "Số tiền cần thanh toán mỗi tháng",
            t11_02: "Cước cơ bản hàng tháng",
            t11_03: "Dịch vụ giá trị gia tăng",
            t11_04: "Giảm giá",
            t11_05: "Chi phí khác (Lần đầu tiên)",
            t11_06: "Phí SIM",
            t11_07: "Phí vận chuyển",
            t11_08: "Điều kiện được miễn phí",
            t11_09: "Số tiền đã giảm",
            t11_10: "Giảm giá thanh toán tự động",
            t11_11: "Giảm giá sử dụng lâu dài ",
            t12_01: "Đăng nhập ",
            t12_02: "Có thể xác nhận chi phí và dung lượng đã sử dụng một cách dễ dàng",
            t12_03: "Đăng nhập bằng OTP",
            t12_04: "Vui lòng nhập tên",
            t12_05: "Số điện thoại đăng kí",
            t12_06: "Gửi mã số xác nhận",
            t12_07: "Xác nhận mã số",
            t12_08: "Cam kết bảo mật",
            t12_09: "Đồng ý với việc thu thập thông tin cá nhân",
            t12_10: "Đăng nhập thành viên",
            t12_11: "Duy trì đăng nhập",
            t12_12: "Về trang chủ",
            t12_13: "Đăng nhập với SNS",
            t12_14: "Tìm ID",
            t12_15: "Tìm mật khẩu",
            t12_16: "Gia nhập thành viên",
            t12_17: "Login",
            t13_01: "Quên ID/ Mật khẩu",
            t13_02: "Để tìm ID/ Mật khẩu cần mã xác nhận từ số điện thoại",
            t13_03: "Tìm ID",
            t13_04: "Vui lòng chọn xác nhận bằng số điện thoại để tiến hành xác minh danh tính.",
            t13_05: "Xác minh bằng số điện thoại",
            t13_06: "Hủy",
            t13_07: "Tìm mật khẩu",
            t13_08: "Xác nhận ID & Xác nhận danh tính",
            t13_09: "ID (email)",
            t13_10: "Xác minh bằng số điện thoại",
            t14_01: "Xem lại nội dung đăng kí",
            t14_02: "Bạn có thể kiểm tra nội dung đăng kí và trạng thái thông tin giao hàng.",
            t14_03: "Xem lại nội dung đăng kí (Không phải thành viên)",
            t14_04: "Mã số đơn hàng",
            t14_05: "Tên người gia nhập",
            t14_06: "Xem lại nội dung đăng kí (Thành viên)",
            t15_01: "Bạn có thể xem lại chi tiết thông tin đăng kí",
            t15_02: "1 tháng",
            t15_03: "3 tháng",
            t15_04: "6 tháng",
            t15_05: "Xem thông tin",
            t15_06: "Ngày đăng kí",
            t15_07: "Mã số đơn hàng",
            t15_08: "Loại hình đăng kí",
            t15_09: "Cước phí đăng kí",
            t15_10: "Trạng thái",
            t15_11: "Giao hàng",
            t15_12: "Hủy/ Trả hàng",
            t15_13: "Xem thêm",
            t16_01: "Trang của tôi",
            t16_02: "Bạn có thể chỉnh sửa thông tin cá nhân, xác nhận thông tin đăng nhập & các dịch vụ ",
            t16_03: "Thông tin thành viên",
            t16_04: "ID",
            t16_05: "Số điện thoại",
            t16_06: "Chỉnh sửa thông tin hội viên tại trang chủ",
            t16_07: "Thông tin gia nhập",
            t16_08: "Chọn",
            t16_09: "Xem lại",
            t17_01: "Thông tin gia nhập",
            t17_02: "Dễ dàng xác nhận lại cước phí sử dụng",
            t17_03: "Thông tin khách hàng",
            t17_04: "Tên gói cước",
            t17_05: "Ngày mở mạng",
            t17_06: "Thời gian sử dụng",
            t17_07: "Ngày hết hạn sử dụng cước",
            t17_08: "Số dư khoản nạp thêm",
            t17_09: "Trạng thái thanh toán tự động",
            t17_10: "Đăng kí",
            t17_11: "Gia hạn/ Nạp tiền ngay",
            t18_01: "Thay đổi thông tin thành viên",
            t18_02: "Có thể thay đổi thông tin thành viên trên Dosirak Mobile ",
            t18_03: "ID",
            t18_04: "Tên",
            t18_05: "Số điện thoại",
            t18_06: "Thay đổi",
            t18_07: "Ngày tháng năm sinh",
            t18_08: "Mật khẩu",
            t18_09: "Giới tính",
            t18_10: "Nam",
            t18_11: "Nữ",
            t18_12: "Lựa chọn thông tin (Đồng ý nhận tin)",
            t18_13: "Email",
            t18_14: "SMS",
            t18_15: "Tự động đăng nhập bằng SNS",
            t18_16: "Facebook",
            t18_17: "Kakao Talk",
            t18_18: "Naver",
            t18_19: "Xóa tài khoản",
            t18_20: "Hoàn tất thay đổi thông tin",
            t19_01: "Hướng dẫn thay đổi thông tin số điện thoại",
            t19_02: "<span class='noteTxt'>Nhập số điện thoại di động mới sẽ sử dụng trong mục đăng nhập ID.</span><span class='noteTxt'>Nếu bạn thay đổi số điện thoại di động, thì số điện thoại của ID trên webside của Wide Dosirak, <br/>Dosirak USIM cũng sẽ tự động thay đổi.</span>",
            t19_03: "Nhập số điện thoại mới",
            t19_04: "Nhập mã số xác nhận",
            t19_05: "Gửi mã số xác nhận",
            t19_06: "Xác minh thất bại",
            t19_07: "Thay đổi số điện thoại",
            t19_08: "Hủy",
            t20_01: "Hướng dẫn thay đổi mật khẩu",
            t20_02: "<span class='noteTxt'>Nhập mật khẩu mới bạn muốn sử dụng trong mục đăng nhập ID</span><span class='noteTxt'>Khi bạn thay đổi mật khẩu, mật khẩu của ID trên webside của Wide Dosirak, Dosirak USIM cũng sẽ tự động thay đổi.</span>",
            t20_03: "Mật khẩu hiện tại",
            t20_04: "Mật khẩu mới",
            t20_05: "Xác nhận mật khẩu mới",
            t20_06: "Thay đổi mật khẩu",
            t21_01: "Điều khoản sử dụng / Chính sách quyền riêng tư",
            t21_02: "Bạn có thể kiểm tra trang web, các điều khoản sử dụng dịch vụ và chính sách bảo mật.",
            t21_03: "Điều khoản sử dụng Dosirak Prepaid",
            t21_04: "Điều khoản thành viên của Wide Mobile",
            t21_05: "Chính sách bảo mật",
            t21_06: "Tải về các chính sách sử dụng",
            t21_07: "Bao gồm VAT",
            t21_08: "Theo dõi giao hàng",
            t21_09: "Đăng kí",
            t21_10: "Thời gian ứng dụng",
            t21_11: "đi xe đạp",
            t21_12: "Phí chiết khấu",
            t21_13: "ngày",
            t21_14: "định ngày sử dụng",
            t21_15: "KRW",
            t21_16: "Số tiền thanh toán",            
            t21_17: "lãi suất cơ bản",
            t21_18: "ngày sử dụng",
            t21_19: " Chọn 60 ngày trở lên (giả giá ngay lập tức), chọn 120 ngày trở lên (giảm giá bổ sung)",
            t21_20: "US army ID",
            t21_21: "THẺ THƯỜNG TRÚ",
            t21_22: "Thẻ cư trú Hàn Quốc ở nước ngoài",  
            t21_23: "Please enter your DOD number",
            t21_24: "Vui lòng nhập số thẻ của bạn",
            t21_25: "Vui lòng nhập số thẻ của bạn",
            t21_26: "(Bắt buộc) Đồng ý thu thập và sử dụng thông tin nhận dạng duy nhất",
            t21_27: "(Bắt buộc) Đồng ý cung cấp thông tin cá nhân cho bên thứ ba",
            t21_28: "(Bắt buộc) Đồng ý ủy thác thông tin cá nhân cho bên thứ ba",
            t21_29: "(Bắt buộc) Đồng ý với yêu cầu thông tin tín dụng cá nhân",                                                        
            t99_99: "Dosirak Mobile",

        }
    }
}


export default translate