<template>
  <!-- 예상금액 플로팅 -->
  <div class="subPageTop">
    <div id="priceFloat_top">
      <div class="pricefDetail"
           id="pricefDetail_top"
           v-show="isExtention">
        <ul class="priceDetailAr">
          <li>
            <div class="priceMain">
              <span
                  class="priceName fW-R etc notranslate"><b>{{ $t('trans.t11_01') }}</b></span>
              <span class="priceAmount fW-R etc"
              ><b>{{
                  monthEstiCharge | currency
                }}원</b></span
              >
            </div>
            <div class="priceMain">
              <span
                  class="priceName fW-L notranslate">{{ $t('trans.t11_02') }}</span>
              <span class="priceAmount fW-L"
              >{{
                  OrderDataSet.monthAmount | currency
                }}원</span
              >
            </div>
            <div class="priceMain">
              <span
                  class="priceName fW-L">부가서비스</span>
              <span class="priceAmount fW-L"
              >{{
                  OrderDataSet.extraAmount | currency
                }}원</span
              >
            </div>
          </li>
          <li>
            <div class="priceMain">
              <span
                  class="priceName fW-R etc notranslate"><b>{{ $t('trans.t05_07') }}</b></span>
              <span class="priceAmount fW-R etc"
              ><b>{{
                  oneTimeCharge | currency
                }}원</b></span
              >
            </div>
            <div class="priceMain">
              <span class="priceName fW-L"><span
                  class='notranslate'>{{ $t('trans.t11_06') }}</span>
								<div class="question fW-L">
									<img
                      src="../images/priceFloat_question.png">
									<span class="tooltiptext">
										선택 요금제에 따라 유심비가 선 결제 또는 후 청구됩니다. (1회)
									</span>
								</div>
							</span>
              <span class="priceAmount"
              >{{
                  OrderDataSet.usimAmount | currency
                }}원</span
              >
            </div>
            <div class="priceMain">
              <span class="priceName fW-L"><span
                  class='notranslate'>{{ $t('trans.t11_07') }}</span>
								<div class="question">
									<img
                      src="../images/priceFloat_question.png">
									<span class="tooltiptext">
										배송 방법 선택에 따라 배송비가 다르게 책정됩니다.<br>
										<em>방문수령 : 무료</em>
										<em>택배 : 0,000원</em>
										<em>당일배송 : 0,000원~0,000원(수도권에 한함, 지역에 따라 차등적용)</em>
									</span>
								</div>
							</span>
              <span
                  class="priceAmount fW-L notranslate">{{ $t('trans.t11_08') }}</span>
            </div>
          </li>
          <li>
              <div class="priceAds">
                  <div class="priceMain">
                  <span class="priceName fW-L notranslate">{{$t('trans.t11_09')}}</span>
                  <span class="priceAmount fW-L"
                      >{{totalDiscountAmount | currency}}원</span
                  >
              </div>
              <div class="priceMain">
                  <span class="priceName fW-L notranslate">{{$t('trans.t11_10')}}</span>
                  <span class="priceAmount fW-L"
                      >{{autoDiscountAmount | currency}}원</span
                  >
              </div>
              <div class="priceMain">
                  <span class="priceName fW-L notranslate">{{$t('trans.t11_11')}}</span>
                  <span class="priceAmount fW-L"
                      >{{longTermDiscountAmount | currency}}원</span
                  >
              </div>
              </div>
          </li>
        </ul>
      </div>
      <div class="priceFloatAr">
        <div class="lt fW-L">
          <span
              class="floatTit notranslate">{{ $t('trans.t05_08') }}</span>
          <span class="floatPrice"
          ><strong class="fW-B"
                   :key="totalCharge">{{
              totalCharge | currency
            }}{{$t('trans.t21_15')}}</strong><span class="notranslate">({{ $t('trans.t21_07') }})</span></span
          >
        </div>
        <div class="rt">
          <span
              :class="['arrowPrice', { active: isExtention }]"
              @click="isExtention = !isExtention"
          ></span>
          <a
              href="#"
              class="floatPriceBtn"
              v-if="memberInfo.isLogin"
              @click="inputTempSave"
          >임시저장</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as topFloatBaner from "../js/util";
import {orderMixin} from "../mixin/storeMixin";
import * as Enum from "../js/enum";
import {
  putTempData,
  putTempImage
} from "../api/order";

export default {
  name: "TopRateBanner",
  mixins: [orderMixin],
  mounted() {
    window.addEventListener("scroll", topFloatBaner.topFloatBaner);
  },

  data() {
    return {
      isExtention: false,
    };
  },

  methods: {
    async inputTempSave() {
      let keys = "";

      try {
        if (
            this.memberInfo.member_login_type === Enum.loginType.EMAIL ||
            this.memberInfo.member_login_type === Enum.loginType.SNS
        ) {
          keys = this.memberInfo.member_seq;
        } else {
          keys = this.memberInfo.member_mvno.celnumber;
        }

        localStorage.setItem(keys, '')

        const resImage = await putTempImage({
          key: keys,
          card: this.aesEncrypt(sessionStorage.getItem("imageCard"), keys),
          face: this.aesEncrypt(sessionStorage.getItem("imageFace"), keys),
        });

        const resData = await putTempData({
          key: keys,
          data: this.aesEncrypt(JSON.stringify(this.OrderDataSet), keys),
        });

        this.kakaoTrans("저장 되었습니다").then((res) => alert(res));

        return;
      } catch (error) {
        this.kakaoTrans("오류가 발생했습니다.").then((res) => alert(res));
        return;
      }
    },
  },

  computed: {
    // 월예상 납부금액
    monthEstiCharge() {
      return this.OrderDataSet.monthAmount + this.OrderDataSet.extraAmount;
    },

    // 최초1회 결제
    oneTimeCharge() {
      return this.OrderDataSet.quickAmount + this.OrderDataSet.usimAmount;
    },

    //결제금액
    totalCharge() {
      return (
          this.OrderDataSet.planAmount +
          this.OrderDataSet.extraAmount +
          this.OrderDataSet.quickAmount +
          this.OrderDataSet.usimAmount
      );
    },
  },
};
</script>

<style></style>
